import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faMinus } from "@fortawesome/free-solid-svg-icons";
import backgroundEllipses from "../../images/hero/backgroundEllipses.png";
import "../../styles/PageCareers.css";

interface JobPosition {
  id: number;
  title: string;
  category: string;
  Responsibilities: string;
  Qualification?: string;
  Requirements: string;
  Interview?: string | null;
}

const initialJobPositions: JobPosition[] = [
  //  Business Development category
  {
    id: 1,
    title: "Sr. Business Development Executive",
    category: "Business Development",
    Responsibilities:
      "Responsibilities :\n- Client Acquisition : Personally identify and target potential clients, showcasing the value proposition of our customized merchandise and corporate gifting solutions.\n- Sales Strategy : Develop and implement effective sales strategies, providing guidance to the team to ensure cohesive execution.\n- Relationship Building : Cultivate strong relationships with key clients and provide mentorship to the team on effective client engagement.\n- Market Research : Stay abreast of industry trends, market dynamics, and competitor activities, guiding the team on strategic approaches.\n- Collaboration : Work closely with internal teams, including marketing and operations, to ensure seamless execution and delivery of client projects.\n- Leadership Skills : Demonstrate leadership qualities within the sales team, providing mentorship and support to junior team members.",
    Requirements:
      "Requirements :\n- Proven track record in business development and sales in a fast-paced environment.\n- Strong negotiation and communication skills, with the ability to articulate the value proposition effectively.\n- Results-oriented mindset with a focus on achieving and exceeding sales targets.\n- Ability to build and maintain strong client relationships.\n- Familiarity with CRM software and sales analytics tools.\n- Bachelor's degree in Business, Marketing, or a related field.\n- Experience: 1-3 years.",
  },
  {
    id: 2,
    title: "Business Development Executive",
    category: "Business Development",
    Responsibilities:
      "Responsibilities:\nClient Acquisition - Personally identify and target potential clients, showcasing the value proposition of our customized merchandise and corporate gifting solutions.\nSales Strategy - Develop and implement effective sales strategies, providing guidance to the team to ensure cohesive execution. \nRelationship Building- Cultivate strong relationships with key clients and provide mentorship to the team on effective client engagement.\nMarket Research- Stay abreast of industry trends, market dynamics, and competitor activities, guiding the team on strategic approaches.\nPipeline Management - Oversee and contribute to the maintenance of a robust sales pipeline, managing leads from initial contact through to contract negotiation and closure. \nCollaboration- Work closely with internal teams, including marketing and operations, to ensure seamless execution and delivery of client projects.\nLeadership Skills-Demonstrate leadership qualities within the sales team, providing mentorship and support to junior team members",

    Requirements:
      "Requirements : \nProven track record in business development and sales in a fast-paced environment.\nStrong negotiation and communication skills, with the ability to articulate the value proposition effectively.\nResults-oriented mindset with a focus on achieving and exceeding sales targets.\nAbility to build and maintain strong client relationships.\nFamiliarity with CRM software and sales analytics tools.\nBachelor's degree in Business, Marketing, or a related field.\nExperience - 1-3 years. \nSkills for Team Leadership - Ability to inspire and guide junior team members.\nEffective communication skills to convey ideas and strategies clearly.\nWillingness to share knowledge and provide support to team members.\nCapacity to collaborate with cross-functional teams. \nQualities We Value: \nInitiative - Proactive and self-motivated individuals who take ownership of their targets. \nInnovation - Creative thinkers who can devise unique solutions to meet client needs. \nAdaptability - Individuals who thrive in a dynamic and evolving industry.\nCollaboration- Team players who can work seamlessly with cross-functional teams.",
  },
  {
    id: 3,
    title: "Assistant Manager-Sales",
    category: "Business Development",
    Responsibilities:
      "\nResponsibilities: \nLead Generation and Cold Calling - Identify and target potential clients through proactive cold calling and networking efforts. Generate and qualify leads to build a robust sales pipeline. \nField Sales - Conduct face-to-face meetings with prospective clients to present our customization and merchandising solutions. Develop and maintain relationships with key decision-makers and stakeholders.\nCommunication and Negotiation - Exhibit excellent verbal and written communication skills. Confidently negotiate terms and close deals while maintaining a high level of professionalism.\nSales Closing - Engage with businesses to understand their corporate customization and merchandising needs.Develop tailored proposals and solutions to meet their requirements.\nReporting and Analysis - Track and report on sales performance, lead generation activities, and market trends.Analyze data to make informed decisions and improve sales strategies",
    Qualification:
      "Qualification: \nExperience - Proven experience in cold calling, field sales, lead generation, and B2B sales. Familiarity with SAAS solutions is a plus. \nSkills - Strong communication skills, both verbal and written. Ability to build relationships and negotiate effectively. Confident and professional demeanour.\nEducation - Bachelor’s degree in Business Administration, Marketing, or a related field is preferred.\nAttributes- Self-motivated, goal-oriented, and able to thrive in a fast-paced environment. Strong problem-solving skills and a proactive approach.",
    Requirements:
      "Requirements: \n- Proven track record in business development and sales \n- Strong negotiation and communication skills \n- Results-oriented mindset \n- Ability to build and maintain strong client relationships \n- Familiarity with CRM software and sales analytics tools \n- Bachelor's degree in Business, Marketing, or related field \n- Experience: 3-4 years",
  },
  {
    id: 4,
    title: "Intern Sales",
    category: "Business Development",
    Responsibilities:
      "Responsibilities:\n- Learn sales processes\n- Support sales team in daily tasks\n- Assist in client communications",
    Requirements: "",
  },

  //  Operations category
  {
    id: 5,
    title: "Operations Manager",
    category: "Operations",
    Responsibilities:
      "Responsibilities:\n- Oversee day-to-day operations\n- Develop operational policies\n- Ensure operational efficiency",
    Requirements: "",
  },
  {
    id: 6,
    title: "Sr. Operation Executive",
    category: "Operations",
    Responsibilities:
      "Responsibilities:\n- Supervise operational activities\n- Coordinate with other departments\n- Implement process improvements",
    Requirements: "",
  },
  {
    id: 7,
    title: "Operation Executive",
    category: "Operations",
    Responsibilities:
      "Responsibilities:\n- Assist in daily operations\n- Handle customer inquiries\n- Maintain inventory records",
    Requirements: "",
  },

  //  Marketing category
  {
    id: 8,
    title: "Digital Marketing Specialist",
    category: "Marketing",
    Responsibilities:
      "Responsibilities: \nDevelop and implement digital marketing campaigns across various channels, including search engines, social media platforms, email, and display advertising, to drive brand awareness, engagement, and conversions.\nConduct keyword research, optimize website content, and manage PPC campaigns to improve search engine rankings and increase organic and paid traffic.\nManage social media accounts, create compelling content, and engage with followers to build brand loyalty and drive website traffic.\nPlan and execute email marketing campaigns, including segmentation, content creation, A/B testing, and performance analysis, to nurture leads and drive conversions.\nCollaborate with the content team to develop high-quality, SEO-friendly content for blogs, articles, whitepapers, and other digital assets to attract and engage target audiences.\nMonitor and analyze key performance indicators (KPIs), such as website traffic, conversion rates, and ROI, and use data-driven insights to optimize campaign performance and achieve marketing goals.\nStay informed about industry trends, emerging technologies, and best practices in digital marketing, and propose innovative ideas to enhance our digital marketing strategy.",
    Qualification:
      "Qualification: \nBachelor's degree in Marketing, Communications, or a related field.\nMinimum of 2 years of experience in digital marketing, preferably in a B2B or technology-related industry.\nProven track record of developing and executing successful digital marketing campaigns that drive measurable results.\nStrong understanding of digital marketing channels, tools, and techniques, including SEO, SEM, social media, email marketing, and content marketing.",
    Requirements: "",
  },
  {
    id: 9,
    title: "Marketing Executive",
    category: "Marketing",
    Responsibilities:
      "Responsibilities:\n- Assist in marketing activities\n- Create marketing materials\n- Coordinate marketing events",
    Requirements: "",
  },
  {
    id: 10,
    title: "Intern Digital Marketing",
    category: "Marketing",
    Responsibilities:
      "Responsibilities:\n- Learn digital marketing techniques\n- Support digital marketing team\n- Assist in content creation",
    Requirements: "",
  },
  {
    id: 11,
    title: "Intern Marketing",
    category: "Marketing",
    Responsibilities:
      "Responsibilities:\n1) Propose strategies to build a lasting digital connection with consumers. \n2) Plan and monitor the ongoing company presence on social media (Twitter, Facebook etc.)\n3) Be actively involved in SEO efforts (keyword, image optimization etc.)\n4) Provide ideas for content marketing.\n5) Collaborate with designers to improve and optimize the user experience.",
    Requirements: "",
  },
  {
    id: 12,
    title: "Telecaller/Lead Generator",
    category: "Marketing",
    Responsibilities:
      "Responsibilities: \nInbound Call Management : \nAnswer incoming calls promptly and professionally. Address customer inquiries, provide information about our corporate gifting solutions, and resolve issues effectively. Maintain accurate records of customer interactions and transactions.\nOutbound Call Activities : \nConduct outbound calls to potential clients to introduce our corporate gifting services. Follow up on leads and inquiries from various sources. Schedule meetings and product demonstrations for the sales team.\nLead Generation and Qualification:\nIdentify and generate new business leads through cold calling, networking, and other strategies. Qualify leads based on their needs and potential for conversion. Update and manage lead information in the CRM system. Customer Relationship Management-Build and maintain strong relationships with existing and potential clients.-Understand client needs and provide tailored solutions. Collect feedback and suggest improvements based on client interactions. \nReporting and Documentation:\nPrepare regular reports on call activities, lead generation, and conversion rates. Document client interactions and follow-ups accurately. \nExperience: \nMinimum of 6 Months of experience in Telecalling, preferably in a B2B or corporate environment. \nSkills:\nExcellent communication and interpersonal skills.\n Strong ability to handle multiple tasks and prioritize effectively.\nProficiency in CRM software and Microsoft Office Suite.\nProven track record in lead generation and sales.\nAttributes:\nProfessional demeanor and a customer-oriented mindset.\nHigh level of motivation and drive to achieve targets.\nAbility to work independently and as part of a team.",
    Qualification:
      "Qualifications:\nHigh school diploma or equivalent. \nBachelor’s degree in Business, Marketing, or a related field is a plus.",
    Requirements: "",
  },
  {
    id: 13,
    title: "Digital Marketing Trainee (Internship)",
    category: "Marketing",
    Responsibilities:
      "Responsibilities:\n- Propose strategies to build a lasting digital connection with consumers. \n- Plan and monitor the ongoing company presence on social media (Twitter, Facebook etc.). \n- Be actively involved in SEO efforts (keyword, image optimization etc.). \n- Provide ideas for content marketing. \n- Collaborate with designers to improve and optimize the user experience.",
    Requirements:
      "Required skills: \n- Excellent communication and interpersonal skills. \n- Solid understanding of digital marketing techniques and practices. \n- Expertise with B2B, B2C social media, Google Adwords and email campaigns and SEO/SEM. \n- A zeal to drive a change and taking ownership of your own work. \n- Experience 0-1 year.",
    Interview:
      "Interview:\n- Round 1: Telephonic Screening.\n- Round 2: Aptitude Test (onsite).\n- Round 3: Technical & HR Round",
  },

  //  R&D (Research and Development) category
  {
    id: 14,
    title: "R&D Specialist",
    category: "R&D (Research and Development)",
    Responsibilities:
      "Responsibilities:\n- Conduct research and experiments\n- Develop new products\n- Collaborate with product development team",
    Requirements: "",
  },

  //  Creative category
  {
    id: 15,
    title: "Video/Photo Editor",
    category: "Creative",
    Responsibilities:
      "Responsibilities:\n- Edit videos and photos\n- Enhance visual content\n- Maintain media library",
    Requirements: "",
  },
  {
    id: 16,
    title: "Digital Creator",
    category: "Creative",
    Responsibilities:
      "Responsibilities: \n1. Content Creation: - Develop and produce high-quality, engaging, and shareable digital content.\n - Conceptualize, script, and act in videos across various genres, showcasing creativity and originality.\n- Stay updated on current trends and incorporate them into content creation to maintain relevance. \n2. On-Camera Presence:\n - Display confidence, charisma, and a natural on-camera presence.\n- Act comfortably in front of the camera, conveying emotions and messages effectively. \n- Adapt to different styles, whether it's acting in a scripted scenario, hosting, or anchoring. 3. Communication Skills.\n- Demonstrate excellent verbal communication skills.\n- Interact with the audience in a compelling and relatable manner.\n- Collaborate effectively with team members, providing input on content ideas and improvements.\n4. Language Proficiency: - Showcase a strong command over the language, ensuring clear and articulate communication.\n- Write and edit scripts with attention to grammar, style, and tone.\n- Ensure that content aligns with brand guidelines and resonates with the target audience.\n 5. Presentability:\n- Maintain a polished and presentable image suitable for hosting or anchoring roles.\n- Pay attention to grooming, attire, and overall appearance in line with the brand's image.\n6. Adaptability:\n- Embrace versatility and adaptability to different content styles and formats.\n- Stay flexible to accommodate changes in scripts, direction, or production requirements.",
    Requirements: "",
  },
];

// Group job positions by category
const groupedPositions: { [key: string]: JobPosition[] } =
  initialJobPositions.reduce((acc, position) => {
    if (!acc[position.category]) {
      acc[position.category] = [];
    }
    acc[position.category].push(position);
    return acc;
  }, {} as { [key: string]: JobPosition[] });

const PageCareers: React.FC = () => {
  const [expandedPosition, setExpandedPosition] = useState<number | null>(null);
  const email = "hr.360customizer@gmail.com";

  const togglePosition = (id: number) => {
    setExpandedPosition((prev) => (prev === id ? null : id));
  };

  const handleEmailClick = () => {
    window.location.href = `mailto:${email}`;
  };

  return (
    <div className="container mx-auto py-8">
      {/* Heading Card */}
      <div
        className="flex justify-center w-full py-14 rounded-tl-3xl rounded-br-3xl mb-10"
        style={{
          backgroundImage: `url(${backgroundEllipses})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundColor: "#0169FF",
          position: "relative",
        }}
      >
        <div className="w-full lg:w-[70%] text-center space-y-5 m-4 lg:m-2">
          <h2 className="text-white text-lg lg:text-2xl font-medium mb-1 font-inter">
            JOIN OUR TEAM
          </h2>
          <h1 className="text-white text-3xl lg:text-5xl font-righteous mb-4 tracking-wide">
            Explore Careers with Us!
          </h1>
          <p className="text-white text-sm lg:text-xl font-inter">
            "Join Our Dynamic Team! Are you enthusiastic about exploring new
            horizons, embracing innovation, and nurturing a culture of
            creativity and problem-solving?"
          </p>
          <p className="absolute bottom-4 right-4 text-white text-base md:text-lg font-normal md:font-medium">
            Make Your Mark!
          </p>
        </div>
      </div>
      <div className="mb-8">
        <h2 className="text-slate-500 text-center text-2xl lg:text-4xl font-righteous font-medium tracking-wider mb-1">
          Open Positions
        </h2>
      </div>
      {/* Job positions section */}
      {Object.entries(groupedPositions).map(([category, positions]) => (
        <div key={category} className="my-10">
          <div className="flex flex-col md:flex-row justify-between items-center my-4 pr-2 md:w-[80%]">
            <h2 className="text-slate-500 text-xl lg:text-3xl font-semibold">
              <span className="hidden md:inline-block font-extrabold">
                &#8226;
              </span>
              {category}
            </h2>
            <button
              onClick={() => handleEmailClick()}
              className="inline-block px-2 py-1 md:px-4 md:py-2 text-xs md:text-sm font-inter font-medium transition-colors duration-200 transform bg-transparent border border-transparent rounded-md shadow-sm text-white bg-blue-700 hover:bg-blue-800 focus:outline-none"
            >
              Apply on hr@360customizer.com
            </button>
          </div>

          <div className="grid gap-8 rounded-xl">
            {positions.map((position) => (
              <div
                key={position.id}
                className={`p-4 ${
                  expandedPosition === position.id
                    ? "conditionalOpen"
                    : "conditionalClose"
                }`}
              >
                <div className="flex items-center justify-between md:w-[60%]">
                  <h3 className="whitespace-nowrap text-lg md:text-xl font-semibold font-inter">
                    {position.title}
                  </h3>
                  <button
                    onClick={() => togglePosition(position.id)}
                    className="rounded-lg text-blue-500 focus:outline-none md:ml-8"
                  >
                    {expandedPosition === position.id ? (
                      <FontAwesomeIcon icon={faMinus} />
                    ) : (
                      <FontAwesomeIcon icon={faPlus} />
                    )}
                  </button>
                </div>
                <div>
                  <div className="mt-4">
                    <div className="bg-[#0169FF] text-white rounded-tl-xl rounded-br-xl p-4 pb-8">
                      <h4 className="text-lg font-semibold mb-2 font-inter">
                        Job Responsibilities:
                      </h4>
                      <div className="space-y-2 mt-6">
                        {position.Responsibilities.split("\n").map(
                          (line, index) => (
                            <p key={index} className="text-sm font-inter">
                              {line.startsWith("Responsibilities") ? (
                                <strong>{line}</strong>
                              ) : (
                                line
                              )}
                            </p>
                          )
                        )}
                      </div>
                      <div className="space-y-2 mt-6">
                        {position.Qualification?.split("\n").map(
                          (line, index) => (
                            <p key={index} className="text-sm font-inter">
                              {line.startsWith("Qualification") ? (
                                <strong>{line}</strong>
                              ) : (
                                line
                              )}
                            </p>
                          )
                        )}
                      </div>
                      <div className="space-y-2 mt-6">
                        {position.Requirements.split("\n").map(
                          (line, index) => (
                            <p key={index} className="text-sm font-inter">
                              {line.startsWith("Requirements") ||
                              line.startsWith("Qualities We Value:") ? (
                                <strong>{line}</strong>
                              ) : (
                                line
                              )}
                            </p>
                          )
                        )}
                      </div>
                      {position.Interview && (
                        <div className="space-y-2 mt-6">
                          {position.Interview.split("\n").map((line, index) => (
                            <p key={index} className="text-sm font-inter">
                              {line}
                            </p>
                          ))}
                        </div>
                      )}
                      <div className="space-y-2 mt-6">
                        <p className="text-sm font-inter">
                          Location: Erandwane, Pune
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default PageCareers;
