import { CustomLink } from "data/types";
import React, { FC } from "react";
import { Link } from "react-router-dom";
import Button from "shared/Button/Button";
import twFocusClass from "utils/twFocusClass";

const DEMO_PAGINATION: CustomLink[] = [
  {
    label: "1",
    href: "#",
  },
  {
    label: "2",
    href: "#",
  },
  {
    label: "3",
    href: "#",
  },
  {
    label: "4",
    href: "#",
  },
];

export interface PaginationProps {
  className?: string;
  total?: number;
  itemPerPage?:number;
  pageSetter?:any;
  currentPage?:number;
}

const activeButtonStyle=`inline-flex w-11 h-11 items-center justify-center rounded-full bg-primary-6000 text-white`
const inaactiveButtonStyle=`inline-flex w-11 h-11 items-center justify-center rounded-full bg-white hover:bg-neutral-100 border border-neutral-200 text-neutral-6000 dark:text-neutral-400 dark:bg-neutral-900 dark:hover:bg-neutral-800 dark:border-neutral-700`
const Pagination: FC<PaginationProps> = ({ className = "",total, pageSetter,currentPage,itemPerPage=10 }) => {

  if (!total) return null;

  // create an arrray of pages using total

  const pages =total? Array.from(Array(Math.ceil(total/itemPerPage)).keys()).map((i)=>i+1):[0]


  const renderItem = (page:number, index: number) => {
    const styleCls= page===currentPage?activeButtonStyle:inaactiveButtonStyle

    return (
      <button
        key={index}
        className={`${styleCls} ${twFocusClass()}`}
        onClick={()=>{pageSetter(page)}}
        >
        {page}
      </button>
    );
  };

  return (
    <nav
      className={`nc-Pagination inline-flex space-x-1 text-base font-medium ${className}`}
    >
      {pages.map((page,index)=>(renderItem(page,index)))}
    </nav>
  );
};

export default Pagination;
