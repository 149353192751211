import { gql } from "@apollo/client";


const ADD_EMAIL_MUTATION = gql`

mutation addEmail($email: String!) {
    createEmailSubscriber(input:{email:$email}){
        id
        createdAt
        updatedAt
        email
    }
}

`

export default ADD_EMAIL_MUTATION;