// queries.ts
import { gql } from '@apollo/client';
import { PRODUCTS_FULL_VIEW_FRAGMENT } from "./fragments/product-full-view.fragment";
import { FEATURED_ASSET } from './fragments/featured-asset.fragment';


export const GET_PAGINATED_PRODUCTS = gql`
  query GetPaginatedProducts($take: Int!, $skip: Int, $ids: [String!], $isPack: Boolean) {
    products(options: { take: $take, skip: $skip, filter:{id:{in:$ids},isPack:{eq:$isPack}} }) {
      items {
        id
        name
        slug
        description
        featuredAsset {
          preview
        }
        variantList {
          items {
            price
            priceWithTax
            id
            name
            stockLevel
            featuredAsset {
              preview
            }
          }
          totalItems
        }
        customFields{
          minOrderQuantity
        }
      }
      totalItems
    }
  }
`;




export const GET_FACETS = gql`
  query {
    facets {
      totalItems
      items {
         customFields{
      isFeatured
    }
        id
        name
        code
        values {
          id
          name
          customFields{
            icon{
              id
              preview
            }
          }
        }
      }
    }
  }
`;




export const searchProductQuery = gql`
  query searchProduct ($ids: [ID!], $term:String, $price:SortOrder) {
    search(input:{facetValueFilters:[{or:$ids}],term:$term, sort:{price:$price}, take:10000}){
      totalItems
      items {
        id: productId
      }
      totalItems
    }
  }
`;




export const filterProductsQuery = gql`
  ${PRODUCTS_FULL_VIEW_FRAGMENT}
  query ($ids: [String!]) {
    products(options: { filter: { id: { in: $ids } } }) {
      totalItems
      items {
        ...product

      }
    }
  }
`;



  
    
export const GET_PRODUCT_BY_SLUG = gql`
query ($slug: String!) {
  product(slug: $slug) {
    id
    name
    createdAt
    updatedAt
    name
    description
    assets{
      preview
    }
    featuredAsset{
      source
    }

    facetValues {
      name
      code
      id
      facet {
        name
      }
    }
    variantList {
      totalItems
    }
    variants {
      sku
      name
      productId
      price
      priceWithTax
      id
      stockLevel
      assets{
        preview
      }
      featuredAsset{
        preview
      }
    }
    customFields{
    metaDescription
    metaTitle
    metaKeywords
    minOrderQuantity

  }
  }
}
`;
  
  

export const me = gql`
  query {
    me {
      id
      identifier
      channels {
        id
        token
      }
    }
  }
`

export const Orders = gql`
query{
  activeOrder{
    id
    code 
    lines {
      id
      linePrice
      quantity
      productVariant {
        id
        name
        price
        priceWithTax
        featuredAsset {
          preview
        }
        product {
          featuredAsset {
            preview
          }
        }
      }
    }
  }
}
`




export const myCartQuery = gql`
  ${FEATURED_ASSET}
  query {
    activeOrder {
      id
      code
      state
      lines {
        id
        unitPrice
        unitPriceWithTax
        linePrice
        linePriceWithTax
        quantity
        productVariant {
          id
          sku
          name
          price
          priceWithTax
          assets{
            preview
          }
          product {
            name
            slug
            description
            featuredAsset {
              ...featuredAsset
            }
            assets{
              preview
            }
            customFields{
              minOrderQuantity
            }
          }
        }
      }
      billingAddress {
        fullName
        streetLine1
        streetLine2
        city
        province
        postalCode
        country
        countryCode
        phoneNumber
        customFields{
          email
        }
      }
      shippingAddress {
        fullName
        streetLine1
        streetLine2
        city
        province
        postalCode
        country
        countryCode
        phoneNumber
        customFields{
          email
        }
      }
      discounts {
        type
        description
        amount
        amountWithTax
      }
      total
      totalWithTax
    }
  }
`;


export const activeCustomerQuery = gql`query{
  activeCustomer{
    id
    firstName
    lastName
    emailAddress
    orders{
      totalItems
    }
    
  }
  }`


  export const logoutMutation = gql`
  mutation{
    logout{
      success
    }
  }
  `

  export const orderStateQuery = gql`
  query{
    nextOrderStates
   }
  `


  
export const eligibleShippingMethodsQuery = gql`
query {
  eligibleShippingMethods {
    id
    code
    name
    price
    priceWithTax
  }
}
`;



export const paymentMethodsQuery = gql`
query{
  eligiblePaymentMethods{
    id
    name
    isEligible
    eligibilityMessage
    
  }
  }
`


export const myOrdersQuery = gql`
  query {
    activeCustomer {
      orders(
        options: {
          sort: { orderPlacedAt: DESC }
          filter:{state:{notEq:"AddingItems"}}
        }
      ) {
        items {
          total
          totalWithTax
          createdAt
          updatedAt
          orderPlacedAt
          active
          state
          id
          code
          state
          subTotal
          total
          subTotalWithTax
          totalWithTax
          customFields{
            kitName
          }

          lines {
            id
            unitPrice
            linePrice
            linePriceWithTax
            unitPriceWithTax
            quantity
            productVariant {
              id
              assets {
                id
                preview
              }
              product {
                slug
                description
                name
                featuredAsset {
                  preview
                }
                assets {
                  id
                  preview
                }
              }
              name
              sku
            }
          }
          shippingAddress {
            fullName
            postalCode
            streetLine1
            streetLine2
            city
            province
            country
            phoneNumber
          }
        }
      }
    }
  }
`;



export const myAddressesQuery = gql`

query MyAddresses{
  activeCustomer{
    addresses{
      id
      fullName
      streetLine1
      streetLine2
      city
      province
      postalCode
      country{
        name
        code
      }
      phoneNumber
      
    }
  }
}

`

export const myProfile = gql`


query{
  activeCustomer{
    firstName
    lastName
    phoneNumber
    emailAddress

  }
}

`


export const orderDetailQuery = gql`
query($id:ID!){
    order(id:$id){
      id
      code
      state
      subTotal
      total
      subTotalWithTax
      totalWithTax
      orderPlacedAt
      lines{
        id
        unitPrice
        linePrice
        linePriceWithTax
        unitPriceWithTax
        quantity
        productVariant{
          id
          assets{
            id
            preview
          }
          product{
            slug
            description
            name
            featuredAsset{
              preview
            }
            assets{
              id
              preview
            }
          }
          name
          sku
          
        }
      }
      shippingAddress{
        fullName
        postalCode
        streetLine1
        streetLine2
        city
        province
        country
        phoneNumber
      }
      
    }
  }


`

export const SellerDetails = gql`
query{
  activeChannel{
    seller{
      name
      customFields{
        mobile
        website
      }
    }
  }
  }

`

export const SELLER_SOCIAL_LINKS = gql`

query{
  activeChannel{
    seller{
      name
      customFields{
        facebook
        twitter
        instagram
        telegram
        youtube
        linkedin
      }
    }
  }
  }

`




export const GET_PAGE_BY_SLUG = gql`
query GetPageBySlug($slug: String!) {
  cmsPage(slug:$slug){
   id
   slug
   title
   content
   metaTitle
   metaDescription
   metaDescription
   metaKeywords
 }
 }

`


export const GET_HEADER_MENU = gql`
query{
  navigations(options:{filter:{type:"header"},sort:{order:ASC}}){
    items{
      id
      order
      title
      url
      type
    }
    totalItems
  }
}
`

export const GET_ALL_MENU = gql`
query{
  navigations(options:{sort:{order:ASC}}){
    items{
      id
      order
      title
      url
      type
      isHeading
    }
    totalItems
  }
}
`