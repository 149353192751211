import React, { FC } from "react";
import annualDayCelebration from "../../images/about/annualDayCelebration.jpeg";
import teamDinners from "../../images/about/teamDinners.jpeg";
import christmasCelebration from "../../images/about/christmasCelebration.jpeg";
import ourDivas from "../../images/about/ourDivas.jpeg";
import teamLunch from "../../images/about/teamLunch.jpeg";
import diwaliCelebration from "../../images/about/diwaliCelebration.jpeg";
import teamOutings from "../../images/about/teamOutings.jpeg";
import ourMachos from "../../images/about/ourMachos.jpeg";
import bollywoodDay from "../../images/about/bollywoodDay.jpeg";
import filmyDay from "../../images/about/filmyDay.jpeg";
import independanceDay from "../../images/about/independenceDay.jpeg";
import styles from "../../styles/SectionProductCatalogue.module.css";

interface Product {
  id: number;
  image: string;
  title: string;
}

const SectionOurTeam: FC = () => {
  const leftProducts: Product[] = [
    {
      id: 1,
      image: annualDayCelebration,
      title: "Annual Day Celebration",
    },
    {
      id: 2,
      image: teamLunch,
      title: "Fun with Team",
    },
    {
      id: 7,
      image: teamOutings,
      title: "Team Outings",
    },
    {
      id: 8,
      image: ourMachos,
      title: "Our Machos",
    },
  ];

  const rightProducts: Product[] = [
    {
      id: 3,
      image: christmasCelebration,
      title: "Christmas Celebration",
    },
    {
      id: 4,
      image: ourDivas,
      title: "Our Divas",
    },
    {
      id: 5,
      image: teamDinners,
      title: "Team Dinner",
    },
    {
      id: 6,
      image: diwaliCelebration,
      title: "Diwali Celebration",
    },
    {
      id: 9,
      image: filmyDay,
      title: "Filmy Day",
    },
    {
      id: 10,
      image: independanceDay,
      title: "Republic Day",
    },
    {
      id: 11,
      image: bollywoodDay,
      title: "Bollywood Day",
    },
  ];

  return (
    <div className="w-full py-2 md:py-10">
      <div className="mb-6 lg:w-1/2 lg:pr-4">
        <h2 className="text-2xl md:text-4xl tracking-wider font-righteous font-medium text-left">
          Our Team
        </h2>
        <p className="text-sm text-black dark:text-neutral-100 font-inter">
          Meet Our Customizers
        </p>
      </div>
      <div className="flex flex-col">
        {/* Left Section */}
        <div className="m-2 gap-4 flex flex-col md:flex-row">
          {/* First image */}
          <div
            key={leftProducts[0].id}
            className={`${styles["brand-card"]} w-[100%] md:w-[53%] mx-auto rounded-lg overflow-hidden relative`}
          >
            <img
              src={leftProducts[0].image}
              alt={leftProducts[0].title}
              className="w-full h-auto object-cover"
              id={`product_${leftProducts[0].id}`}
            />
            <div className={styles.overlay}>
              <p className={styles["overlay-text"]}>{leftProducts[0].title}</p>
            </div>
          </div>
          {/* Second image */}
          <div
            key={leftProducts[1].id}
            className={`${styles["brand-card"]} w-[100%] md:w-[47%] mx-auto rounded-lg overflow-hidden relative`}
          >
            <img
              src={leftProducts[1].image}
              alt={leftProducts[1].title}
              className="w-full h-auto object-cover"
              id={`product_${leftProducts[1].id}`}
            />
            <div className={styles.overlay}>
              <p className={styles["overlay-text"]}>{leftProducts[1].title}</p>
            </div>
          </div>
        </div>
        <div className="m-2 gap-4 flex flex-col md:flex-row">
          {/* Third image */}
          <div
            key={leftProducts[2].id}
            className={`${styles["brand-card"]} w-[100%] md:w-[35%] mx-auto rounded-lg overflow-hidden relative`}
          >
            <img
              src={leftProducts[2].image}
              alt={leftProducts[2].title}
              className="w-full h-auto object-cover"
              id={`product_${leftProducts[2].id}`}
            />
            <div className={styles.overlay}>
              <p className={styles["overlay-text"]}>{leftProducts[2].title}</p>
            </div>
          </div>
          {/* Fourth image */}
          <div
            key={leftProducts[3].id}
            className={`${styles["brand-card"]} w-[100%] md:w-[70%] mx-auto rounded-lg overflow-hidden relative`}
          >
            <img
              src={leftProducts[3].image}
              alt={leftProducts[3].title}
              className="w-full h-auto object-cover"
              id={`product_${leftProducts[3].id}`}
            />
            <div className={styles.overlay}>
              <p className={styles["overlay-text"]}>{leftProducts[3].title}</p>
            </div>
          </div>
        </div>
        {/* Right Section */}
        <div className="flex flex-col m-2 gap-4">
          <div className="flex gap-4 flex-col md:flex-row">
            {/* Fifth image */}
            <div
              key={rightProducts[0].id}
              className={`${styles["brand-card"]} w-[100%] md:w-[64%] mx-auto rounded-lg overflow-hidden relative`}
            >
              <img
                src={rightProducts[0].image}
                alt={rightProducts[0].title}
                className="w-full h-auto object-cover"
                id={`product_${rightProducts[0].id}`}
              />
              <div className={styles.overlay}>
                <p className={styles["overlay-text"]}>
                  {rightProducts[0].title}
                </p>
              </div>
            </div>
            {/* Sixth image */}
            <div
              key={rightProducts[1].id}
              className={`${styles["brand-card"]} w-[100%] md:w-[36%] mx-auto rounded-lg overflow-hidden relative`}
            >
              <img
                src={rightProducts[1].image}
                alt={rightProducts[1].title}
                className="w-full h-auto object-cover"
                id={`product_${rightProducts[1].id}`}
              />
              <div className={styles.overlay}>
                <p className={styles["overlay-text"]}>
                  {rightProducts[1].title}
                </p>
              </div>
            </div>
          </div>
          <div className="flex gap-4 flex-col md:flex-row">
            {/* Seventh image */}
            <div
              key={rightProducts[2].id}
              className={`${styles["brand-card"]} w-[100%] md:w-[50%] mx-auto rounded-lg overflow-hidden relative`}
            >
              <img
                src={rightProducts[2].image}
                alt={rightProducts[2].title}
                className="w-full h-auto object-cover"
                id={`product_${rightProducts[2].id}`}
              />
              <div className={styles.overlay}>
                <p className={styles["overlay-text"]}>
                  {rightProducts[2].title}
                </p>
              </div>
            </div>
            {/* Eighth image */}
            <div
              key={rightProducts[3].id}
              className={`${styles["brand-card"]} w-[100%] md:w-[50%] mx-auto rounded-lg overflow-hidden relative`}
            >
              <img
                src={rightProducts[3].image}
                alt={rightProducts[3].title}
                className="w-full h-auto object-cover"
                id={`product_${rightProducts[3].id}`}
              />
              <div className={styles.overlay}>
                <p className={styles["overlay-text"]}>
                  {rightProducts[3].title}
                </p>
              </div>
            </div>
          </div>
          {/* Ninth image */}
          <div className="flex gap-4 flex-col md:flex-row">
            <div
              key={rightProducts[4].id}
              className={`${styles["brand-card"]} w-full md:w-[39%] mx-auto rounded-lg overflow-hidden relative`}
            >
              <img
                src={rightProducts[4].image}
                alt={rightProducts[4].title}
                className="w-full h-auto object-cover"
                id={`product_${rightProducts[4].id}`}
              />
              <div className={styles.overlay}>
                <p className={styles["overlay-text"]}>
                  {rightProducts[4].title}
                </p>
              </div>
            </div>
            {/* Tenth image */}
            <div
              key={rightProducts[5].id}
              className={`${styles["brand-card"]} w-full md:w-[22%] mx-auto rounded-lg overflow-hidden relative`}
            >
              <img
                src={rightProducts[5].image}
                alt={rightProducts[5].title}
                className="w-full h-auto object-cover"
                id={`product_${rightProducts[5].id}`}
              />
              <div className={styles.overlay}>
                <p className={styles["overlay-text"]}>
                  {rightProducts[5].title}
                </p>
              </div>
            </div>
            {/* Eleventh image */}
            <div
              key={rightProducts[6].id}
              className={`${styles["brand-card"]} w-full md:w-[39%] mx-auto rounded-lg overflow-hidden relative`}
            >
              <img
                src={rightProducts[6].image}
                alt={rightProducts[6].title}
                className="w-full h-auto object-cover"
                id={`product_${rightProducts[6].id}`}
              />
              <div className={styles.overlay}>
                <p className={styles["overlay-text"]}>
                  {rightProducts[6].title}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionOurTeam;
