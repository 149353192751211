import React, { FC, useEffect, useState } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import LikeButton from "components/LikeButton";
import AccordionInfo from "./AccordionInfo";
import { MinusIcon, PlusIcon, StarIcon } from "@heroicons/react/24/solid";
import BagIcon from "components/BagIcon";
import NcInputNumber from "components/NcInputNumber";
import {
  NoSymbolIcon,
  ClockIcon,
  SparklesIcon,
} from "@heroicons/react/24/outline";
import IconDiscount from "components/IconDiscount";
import Prices from "components/Prices";
import toast from "react-hot-toast";
import SectionSliderProductCard from "components/SectionSliderProductCard";
import detail1JPG from "images/products/detail1.jpg";
import detail2JPG from "images/products/detail2.jpg";
import detail3JPG from "images/products/detail3.jpg";
import Policy from "./Policy";
import ReviewItem from "components/ReviewItem";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import SectionPromo2 from "components/SectionPromo2";
import ModalViewAllReviews from "./ModalViewAllReviews";
import NotifyAddTocart from "components/NotifyAddTocart";
import { useParams } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import { GET_PRODUCT_BY_SLUG } from "graphql/queries/queries";
import { Disclosure } from "@headlessui/react";
import { GetProductBySlugResponse } from "graphql/queries/types";
import Select from "shared/Select/Select";
import { addItemToOrderMutation } from "graphql/mutations/add-item-to-order";
import { Helmet } from "react-helmet-async";
import useOrderStore from "hooks/useCart";
import useCurrentUser from "hooks/useCurrentUser";
import NcImage from "shared/NcImage/NcImage";

export interface ProductDetailPageProps {
  className?: string;
}

const ProductDetailPage: FC<ProductDetailPageProps> = ({ className = "" }) => {
  let { slug } = useParams();
  const [addItemToOrder, addItemToOrderProp] = useMutation(
    addItemToOrderMutation
  );

  const orderStore = useOrderStore((state) => {
    return {
      setOrder: state.setOrder,
      order: state.order,
    };
  });

  const getUser = useCurrentUser((state) => {
    return {
      user: state.user,
      getUser: state.getUser,
      isLoggedIn: state.isUserLoggedIn,
    };
  });

  useEffect(() => {
    getUser.getUser();
    // console.log("useEffect called")
  }, []);

  //  console.log(getUser.user,"user---")
  //  console.log(getUser.isLoggedIn,"isLoggedin---")

  const { loading, error, data } = useQuery<GetProductBySlugResponse>(
    GET_PRODUCT_BY_SLUG,
    { variables: { slug } }
  );

  const variantId = data?.product?.variants?.[0]?.id;
  const productName = data?.product?.variants?.[0]?.name || "";
  const productDescription = data?.product?.description || "";
  const productPrice = data?.product?.variants?.[0]?.priceWithTax || 0;
  const productVariants = data?.product?.variants || [];
  const productImage = data?.product?.variants?.[0]?.featuredAsset?.preview
    ? data?.product?.variants?.[0]?.featuredAsset?.preview
    : data?.product?.featuredAsset?.source || "";
  const assets = data?.product?.variants?.[0]?.assets || data?.product?.assets;
  const productStockLevel = data?.product?.variants?.[0]?.stockLevel || "";
  let productMinOrderQuantity =
    data?.product?.customFields?.minOrderQuantity || 1;
  const productMetaDescription =
    data?.product?.customFields?.metaDescription || "";
  const productMetaTitle =
    data?.product?.customFields?.metaTitle || data?.product?.name || "";
  const productMetaKeywords = data?.product?.customFields?.metaKeywords || "";

  // console.log(data,"product on product Details page")

  const [product, setProduct] = useState({
    name: productName,
    description: productDescription,
    price: productPrice,
    variant: {},
    id: variantId,
    productImage: productImage,
    assets: assets,
  });
  const [qualitySelected, setQualitySelected] = React.useState(1);

  useEffect(() => {
    if (!loading && data) {
      // console.log(data,"product detail")
      let mainImage = data?.product?.variants[0]?.featuredAsset?.preview;

      // console.log(mainImage,"mainImage")
      if (!mainImage) {
        mainImage = data?.product?.featuredAsset?.source;
      }
      productMinOrderQuantity =
        data?.product?.customFields?.minOrderQuantity || 1;
      let filteeredAssets = data?.product?.variants?.[0]?.assets?.length
        ? data?.product?.variants?.[0]?.assets
        : data?.product?.assets;

      filteeredAssets = filteeredAssets?.filter((asset) => {
        // console.log(asset,"asset")
        // console.log(mainImage,"mainImage")
        return asset?.preview !== mainImage;
      });
      // console.log(mainImage,"mainImage1")
      // console.log(filteeredAssets,"filteeredAssets")
      if (qualitySelected <= data?.product?.customFields?.minOrderQuantity) {
        setQualitySelected(data?.product?.customFields?.minOrderQuantity || 1);
      }
      setProduct({
        ...product,
        name: productName,
        description: productDescription,
        price: productPrice,
        variant: {},
        id: variantId,
        productImage: mainImage,
        assets: filteeredAssets,
      });
    }

    if (!addItemToOrderProp.loading && addItemToOrderProp.data) {
      // console.log(addItemToOrderProp.data,"data on product Details page")
      if (
        addItemToOrderProp.data?.addItemToOrder.__typename ===
        "InsufficientStockError"
      ) {
        toast.error(addItemToOrderProp.data?.addItemToOrder?.message);
      }
    }
  }, [data, loading, addItemToOrderProp.data, addItemToOrderProp.loading]);

  // console.log(product,"product")

  const [isOpenModalViewAllReviews, setIsOpenModalViewAllReviews] =
    useState(false);

  // console.log(productImage,"productImage")

  if (loading)
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-primary-500"></div>
      </div>
    );

  if (error || addItemToOrderProp.error) {
    return (
      <div className="flex flex-col justify-center items-center h-screen">
        <h1 className="text-2xl font-semibold text-center my-3">
          Something went wrong!
        </h1>
        <ButtonPrimary
          onClick={() => {
            window.location.reload();
          }}
        >
          Reload
        </ButtonPrimary>
      </div>
    );
  }

  if (!loading && !data?.product) {
    return (
      <div className="flex flex-col justify-center items-center h-screen">
        <h1 className="text-2xl font-semibold text-center my-3">
          Product not found!
        </h1>
        <ButtonPrimary
          onClick={() => {
            window.location.reload();
          }}
        >
          Reload
        </ButtonPrimary>
      </div>
    );
  }

  const notifyAddTocart = () => {
    toast.custom(
      (t) => (
        <NotifyAddTocart
          productImage={productImage}
          qualitySelected={qualitySelected}
          show={t.visible}
          sizeSelected={""}
          variantActive={0}
          price={product.price}
          name={product.name}
        />
      ),
      { position: "top-right", id: "nc-product-notify", duration: 3000 }
    );
  };

  const addToCart = () => {
    addItemToOrder({
      variables: { productId: product.id, qty: qualitySelected },
    }).then((res) => {
      if (res.data?.addItemToOrder?.__typename === "Order") {
        const code = res.data?.addItemToOrder?.code;
        const id = res.data?.addItemToOrder?.id;
        const lines = res.data?.addItemToOrder?.lines;
        orderStore.setOrder({ code: code, id: id, lines: lines });
        notifyAddTocart();
      } else {
        // check if code id and lined exist then add it to orderStore
        if (
          res.data?.addItemToOrder?.order?.code &&
          res.data?.addItemToOrder?.order?.id &&
          res.data?.addItemToOrder?.order?.lines
        ) {
          const code = res.data?.addItemToOrder?.order?.code;
          const id = res.data?.addItemToOrder?.order?.id;
          const lines = res.data?.addItemToOrder?.order?.lines;
          orderStore.setOrder({ code: code, id: id, lines: lines });
        }
      }
    });
  };

  const renderVariants = () => {
    if (!productVariants || !productVariants.length) {
      return null;
    }

    return (
      <div>
        <div className="flex">
          <Select
            onChange={(e) => {
              const getVarient = productVariants.find(
                (variant) => variant.id === e.target.value
              );
              // remove product image from assets if it is not in assets
              const filteeredAssets = getVarient?.assets?.filter(
                (asset) => asset?.preview !== getVarient?.featuredAsset?.preview
              );
              const pImage = getVarient?.featuredAsset?.preview
                ? getVarient?.featuredAsset?.preview || ""
                : data?.product?.featuredAsset?.source || "";
              setProduct({
                ...product,
                variant: { ...getVarient },
                price: getVarient?.price || 0,
                name: getVarient?.name || "",
                id: getVarient?.id || "",
                productImage: pImage,
                assets: filteeredAssets,
              });
            }}
          >
            {productVariants.map((variant, index) => {
              return (
                <option key={index} value={variant.id}>
                  {variant.name}
                </option>
              );
            })}
          </Select>
        </div>
      </div>
    );
  };

  const renderSectionContent = () => {
    return (
      <div className="space-y-7 2xl:space-y-8">
        {/* ---------- 1 HEADING ----------  */}
        <div>
          <h2 className="text-2xl sm:text-3xl font-semibold">{product.name}</h2>

          <div className="sm:flex items-center mt-5 space-x-4 sm:space-x-5">
            {/* <div className="flex text-xl font-semibold">$112.00</div> */}
            <Prices
              contentClass="py-1 px-2 md:py-1.5 md:px-3 text-lg font-semibold"
              price={product.price}
            />

            <div className=" hidden sm:block h-7 border-l border-slate-300 dark:border-slate-700"></div>

            <div className="flex mt-3 sm:mt-0 items-center">
              {productVariants.length > 1 && renderVariants()}
            </div>
          </div>
        </div>

        {/* ---------- 3 VARIANTS AND SIZE LIST ----------  */}

        {/* <div className="">{renderVariants()}</div> */}
        {/* <div className="">{renderSizeList()}</div> */}

        {/*  ---------- 4  QTY AND ADD TO CART BUTTON */}
        <div className="flex space-x-3.5">
          <div className="flex items-center justify-center bg-slate-100/70 dark:bg-slate-800/70 px-2 py-3 sm:p-3.5 rounded-full">
            <NcInputNumber
              min={productMinOrderQuantity}
              defaultValue={qualitySelected}
              onChange={setQualitySelected}
            />
          </div>
          {productStockLevel === "IN_STOCK" && (
            <ButtonPrimary
              className="flex-1 flex-shrink-0"
              onClick={addToCart}
              loading={addItemToOrderProp.loading}
            >
              <BagIcon className="hidden sm:inline-block w-5 h-5 mb-0.5" />
              <span className="ml-3">
                {addItemToOrderProp.loading ? "Adding..." : "Add to cart"}{" "}
              </span>
            </ButtonPrimary>
          )}

          {productStockLevel === "OUT_OF_STOCK" && (
            <ButtonPrimary className="flex-1 flex-shrink-0" disabled={true}>
              <BagIcon className="hidden sm:inline-block w-5 h-5 mb-0.5" />
              <span className="ml-3">Out of stock</span>
            </ButtonPrimary>
          )}
        </div>

        {/*  */}
        <hr className=" 2xl:!my-10 border-slate-200 dark:border-slate-700"></hr>
        {/*  */}

        {/* ---------- 5 ----------  */}
        {/* <AccordionInfo /> */}
        <div className="w-full rounded-2xl space-y-2.5">
          <Disclosure defaultOpen={true}>
            {({ open }) => (
              <>
                <Disclosure.Button className="flex items-center justify-between w-full px-4 py-2 font-medium text-left bg-slate-100/80 hover:bg-slate-200/60 dark:bg-slate-800 dark:hover:bg-slate-700 rounded-lg focus:outline-none focus-visible:ring focus-visible:ring-slate-500 focus-visible:ring-opacity-75 ">
                  <span>Description</span>
                  {!open ? (
                    <PlusIcon className="w-4 h-4 text-slate-600 dark:text-slate-400" />
                  ) : (
                    <MinusIcon className="w-4 h-4 text-slate-600 dark:text-slate-400" />
                  )}
                </Disclosure.Button>
                <Disclosure.Panel
                  className="p-4 pt-3 last:pb-0 text-slate-600 text-sm dark:text-slate-300 leading-6"
                  as="div"
                  dangerouslySetInnerHTML={{ __html: product.description }}
                ></Disclosure.Panel>
              </>
            )}
          </Disclosure>
        </div>

        {/* ---------- 6 ----------  */}
        <div className="hidden xl:block">{/* <Policy /> */}</div>
      </div>
    );
  };

  const renderDetailSection = () => {
    return (
      <div className="">
        <h2 className="text-2xl font-semibold">Product Details</h2>
        <div className="prose prose-sm sm:prose dark:prose-invert sm:max-w-4xl mt-7">
          <p>
            The patented eighteen-inch hardwood Arrowhead deck --- finely
            mortised in, makes this the strongest and most rigid canoe ever
            built. You cannot buy a canoe that will afford greater satisfaction.
          </p>
          <p>
            The St. Louis Meramec Canoe Company was founded by Alfred Wickett in
            1922. Wickett had previously worked for the Old Town Canoe Co from
            1900 to 1914. Manufacturing of the classic wooden canoes in Valley
            Park, Missouri ceased in 1978.
          </p>
          <ul>
            <li>Regular fit, mid-weight t-shirt</li>
            <li>Natural color, 100% premium combed organic cotton</li>
            <li>
              Quality cotton grown without the use of herbicides or pesticides -
              GOTS certified
            </li>
            <li>Soft touch water based printed in the USA</li>
          </ul>
        </div>
      </div>
    );
  };

  const renderReviews = () => {
    return (
      <div className="">
        {/* HEADING */}
        <h2 className="text-2xl font-semibold flex items-center">
          <StarIcon className="w-7 h-7 mb-0.5" />
          <span className="ml-1.5"> 4,87 · 142 Reviews</span>
        </h2>

        {/* comment */}
        <div className="mt-10">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-y-11 gap-x-28">
            <ReviewItem />
            <ReviewItem
              data={{
                comment: `I love the charcoal heavyweight hoodie. Still looks new after plenty of washes. 
                  If you’re unsure which hoodie to pick.`,
                date: "December 22, 2021",
                name: "Stiven Hokinhs",
                starPoint: 5,
              }}
            />
            <ReviewItem
              data={{
                comment: `The quality and sizing mentioned were accurate and really happy with the purchase. Such a cozy and comfortable hoodie. 
                Now that it’s colder, my husband wears his all the time. I wear hoodies all the time. `,
                date: "August 15, 2022",
                name: "Gropishta keo",
                starPoint: 5,
              }}
            />
            <ReviewItem
              data={{
                comment: `Before buying this, I didn't really know how I would tell a "high quality" sweatshirt, but after opening, I was very impressed. 
                The material is super soft and comfortable and the sweatshirt also has a good weight to it.`,
                date: "December 12, 2022",
                name: "Dahon Stiven",
                starPoint: 5,
              }}
            />
          </div>

          <ButtonSecondary
            onClick={() => setIsOpenModalViewAllReviews(true)}
            className="mt-10 border border-slate-300 dark:border-slate-700 "
          >
            Show me all 142 reviews
          </ButtonSecondary>
        </div>
      </div>
    );
  };

  return (
    <div className={`nc-ProductDetailPage ${className}`}>
      <Helmet>
        <title>{productMetaTitle} || 360CUSTOMIZER</title>
        <meta name="description" content={productMetaDescription} />
        <meta name="keywords" content={productMetaKeywords} />
      </Helmet>

      {/* MAIn */}
      <main className="container mt-5 lg:mt-11">
        <div className="lg:flex">
          {/* CONTENT */}
          <div className="w-full lg:w-[55%] ">
            {/* HEADING */}
            <div className="relative">
              <NcImage
                src={product.productImage}
                containerClassName="aspect-w-16 aspect-h-16"
                className="w-full rounded-2xl object-cover bg-white"
                alt="product detail 1"
              />
              {/* {renderStatus()} */}
              {/* META FAVORITES */}
              {/* <LikeButton className="absolute right-3 top-3 " /> */}
            </div>
            <div className="grid grid-cols-2 gap-3 mt-3 sm:gap-6 sm:mt-6 xl:gap-8 xl:mt-8">
              {product?.assets?.map((asset, index) => (
                <NcImage
                  key={`${index}-${asset?.preview}}`}
                  src={asset?.preview}
                  containerClassName="aspect-w-16 aspect-h-16"
                  className="w-full rounded-2xl object-cover bg-white"
                  alt="product detail 1"
                />
              ))}
            </div>
          </div>

          {/* SIDEBAR */}
          <div className="w-full lg:w-[45%] pt-10 lg:pt-0 lg:pl-7 xl:pl-9 2xl:pl-10">
            {renderSectionContent()}
          </div>
        </div>

        {/* DETAIL AND REVIEW */}
        <div className="mt-12 sm:mt-16 space-y-10 sm:space-y-16">
          {/* <div className="block xl:hidden">
            <Policy />
          </div> */}

          {/* {renderDetailSection()} */}

          {/* <hr className="border-slate-200 dark:border-slate-700" /> */}

          {/* {renderReviews()} */}

          {/* <hr className="border-slate-200 dark:border-slate-700" /> */}

          {/* OTHER SECTION */}
          {/* <SectionSliderProductCard
            heading="Customers also purchased"
            subHeading=""
            headingFontClassName="text-2xl font-semibold"
            headingClassName="mb-10 text-neutral-900 dark:text-neutral-50"
          /> */}

          {/* SECTION */}
          <div className="pb-20 xl:pb-28 lg:pt-14">
            {/* <SectionPromo2 /> */}
          </div>
        </div>
      </main>

      {/* MODAL VIEW ALL REVIEW */}
      <ModalViewAllReviews
        show={isOpenModalViewAllReviews}
        onCloseModalViewAllReviews={() => setIsOpenModalViewAllReviews(false)}
      />
    </div>
  );
};

export default ProductDetailPage;
