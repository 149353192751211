import Prices from "components/Prices";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import { useQuery } from "@apollo/client";
import { SellerDetails, myOrdersQuery, orderDetailQuery } from "graphql/queries/queries";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { Link, useParams } from "react-router-dom";
import formatCurrencyINR from "utils/convertNumbToCurrency";
import Badge from "shared/Badge/Badge";
import { PDFDownloadLink, PDFViewer } from '@react-pdf/renderer';
import NcImage from "shared/NcImage/NcImage";
import { useEffect, useState } from "react";

import MyDoc from "components/CatelogPdf/Pdf";


const DemoImageURL = '/assets/placeholder-small.png'; // Replace with your demo image URL

interface OrderDetail {
  order: {
    id: string;
    code: string;
    state: string;
    subTotal: number;
    total: number;
    subTotalWithTax: number;
    totalWithTax: number;
    orderPlacedAt: string;
    lines: OrderLine[];
    shippingAddress: ShippingAddress;
  };
}

interface OrderLine {
  id: string;
  unitPrice: number;
  linePrice: number;
  linePriceWithTax: number;
  unitPriceWithTax: number;
  quantity: number;
  imageUrl?: string;
  assets?: Asset[] | [];
  productVariant: ProductVariant;
}

interface Asset {
  id: string;
  preview: string;
}

interface ProductVariant {
  id: string;
  product: Product;
  name: string;
  sku: string;
  assets: Asset[] | [];
}

interface Product {
  slug: string;
  description: string;
  featuredAsset: {
    preview: string;
  };
  assets: Asset[] | [];
}

interface ShippingAddress {
  fullName: string;
  postalCode: string;
  streetLine1: string;
  streetLine2: string;
  city: string;
  province: string;
  country: string;
  phoneNumber: string;
}

// import Poppins from "./PoppinsBlack.ttf";
 



const monthNames: string[] = [
    'January', 'February', 'March', 'April', 'May', 'June', 'July',
    'August', 'September', 'October', 'November', 'December'
  ];
  
  function formatDateToDDMMYYYY(dateString: string): string {
    const date = new Date(dateString);
    
    const day = date.getDate().toString().padStart(2, '0');
    const month = monthNames[date.getMonth()];
    const year = date.getFullYear().toString();
    
    return `${day} ${month} ${year}`;
  }
 

  const isImageURLValid = async (url:any) => {
    try {
      const response = await fetch(url);
      return response.ok;
    } catch (error) {
      return false;
    }
  };




const renderProduct = (item:any, index: number) => {
    const id = item?.id
    const productId = item?.productVariant?.id
    const image = item?.productVariant?.product?.featuredAsset?.preview
    const price =item?.linePriceWithTax
    const name=item?.productVariant?.name
    const productName=item?.productVariant?.product?.name
    const quantity=item?.quantity
    const slug = item?.productVariant?.product?.slug

    return (

      <div
        key={index}
        className="relative flex py-8 sm:py-10 xl:py-12 first:pt-0 last:pb-0"
      >
        <div className="relative h-36 w-24 sm:w-32 flex-shrink-0 overflow-hidden rounded-xl bg-slate-100">
          <NcImage
            src={image}
            alt={name}
            containerClassName="h-full w-full object-cover object-center"
            className="h-full w-full object-cover object-center"
          />
          <Link to={"/product/"+slug} className="absolute inset-0"></Link>
        </div>

        <div className="ml-3 sm:ml-6 flex flex-1 flex-col">
          <div>
            <div className="flex justify-between ">
              <div className="flex-[1.5] ">
                <h3 className="text-base font-semibold">
                  <Link to={"/product/"+slug}>{productName}</Link>
                </h3>
                <p className="mt-1 text-sm text-neutral-500 dark:text-neutral-400">
                  {name}
                </p>

                <div className="mt-3 flex justify-between w-full sm:hidden relative">
                  <p>qty: {quantity}</p>
                  <Prices
                    contentClass="py-1 px-2 md:py-1.5 md:px-2.5 text-sm font-medium h-full"
                    price={price}
                  />
                </div>
              </div>

              <div className="hidden sm:block text-center relative">
                {/* <NcInputNumber defaultValue={quantity} id={id} productId={productId} className="relative z-10" /> */}
                QTY:{quantity}
              </div>

              <div className="hidden flex-1 sm:flex justify-end">
                <Prices price={price} className="mt-0.5" />
              </div>
            </div>
          </div>

          <div className="flex mt-auto pt-4 items-end justify-between text-sm">
            {/* {Math.random() > 0.6
              ? renderStatusSoldout()
              : renderStatusInstock()} */}
             {/* { renderStatusInstock()} */}

            {/* <button
              onClick={()=>{
                handleRemoveOrder({lineId:id,productId:productId})
                
              }}
              className="relative z-10 flex items-center mt-3 font-medium text-primary-6000 hover:text-primary-500 text-sm "
            >
              <span>Remove</span>
            </button> */}
          </div>
        </div>
      </div>
    );
  };




const AccountOrderDetail = () => {
    let { id } = useParams();


    const {data, loading, error}= useQuery<OrderDetail>(orderDetailQuery, {variables:{id:id},fetchPolicy:"no-cache"})

    const FetchSeller = useQuery(SellerDetails,{fetchPolicy:"no-cache"})

    const [processedData, setProcessedData] = useState<OrderDetail| null>(null);

    const [seller, setSeller] = useState<{mobile:string,website:string} | null>(null);

 

    useEffect(() => {
      if (!loading && data && data.order) {
        const processedLines = data?.order?.lines?.map(async (line) => {
          const imageUrl = line.productVariant.product.featuredAsset?.preview;

          // check if product variant have more than 1 assets then use else check in product assets if it has more than 1 then use else set empty array
          const variantAssetLength = line.productVariant.assets.length;
          const productAssetLength = line.productVariant.product.assets.length;
          let assets =  variantAssetLength >1 ? line.productVariant.assets : productAssetLength > 1 ? line.productVariant.product.assets : [];

          // now check if any of the image matches with imageUrl then remove it from assets array
          assets = assets.filter((asset) => asset.preview !== imageUrl);
          

          let procesingData = line

          if (!assets.length){
            procesingData = { ...procesingData,  assets: [] };
          }else{
            assets.forEach((asset, i) => {
              const isValid = isImageURLValid(asset.preview);
              if (!isValid) {
                assets[i].preview = DemoImageURL;
                procesingData = { ...procesingData, assets: assets };
              }
            })
            procesingData = { ...procesingData, assets: assets };
          }

          

          if (!imageUrl) {
            procesingData = { ...procesingData, imageUrl: DemoImageURL };
          } else {
            const isValid = await isImageURLValid(imageUrl);
  
            if (!isValid) {
              procesingData = { ...procesingData, imageUrl: DemoImageURL };
            }
            procesingData = { ...procesingData, imageUrl };
          }
          return procesingData;
        });
  
        // Use Promise.all to await the completion of all asynchronous operations
        Promise.all(processedLines).then((processedLinesData) => {
          const processedOrder = { ...data.order, lines: processedLinesData };
          setProcessedData({ order: processedOrder });
        });
      }
    }, [loading, data]);

    useEffect(() => {
      if(!FetchSeller.loading && FetchSeller.data && FetchSeller.data.activeChannel && FetchSeller.data.activeChannel.seller){
        const mobile = FetchSeller.data?.activeChannel?.seller?.customFields?.mobile
        const website = FetchSeller.data?.activeChannel?.seller?.customFields?.website
        setSeller({mobile:mobile,website:website})
      }

    }, [FetchSeller.data,FetchSeller.loading])

   


   
  
    if (loading || FetchSeller.loading) return (
      <div className="flex justify-center items-center h-screen">
            <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-primary-900"></div>
      </div>
    )

    if(error || FetchSeller.error) return (
      <div className="flex flex-col justify-center items-center h-screen">
            <p className="text-red-500">Something went wrong</p>
            <ButtonSecondary className="mt-8" href="/account-my-order">Go Back</ButtonSecondary>
      </div>
    ) 

    if(!data?.order || !FetchSeller?.data?.activeChannel) return (
      <div className="flex flex-col justify-center items-center h-screen">
            <p className="text-red-500">No Data Found</p>
            <ButtonSecondary className="mt-8" href="/account-my-order">Go Back</ButtonSecondary>
      </div>
    )

   

   
    
  
  const orderPdfName = processedData?.order?.code + "-" + processedData?.order?.orderPlacedAt

   
  return (
    <div className="nc-CartPage">
      {/* {processedData && 
          (
        <PDFViewer style={{width:"100%"}} showToolbar >
            <MyDoc data={processedData}  seller={seller} />
        </PDFViewer>
          )
        } */}
      <main className="container py-16 lg:pb-28 lg:pt-20 ">
        <div className=" flex flex-wrap  justify-between  mb-12 sm:mb-16">

            <div>
            <h2 className="block text-2xl sm:text-3xl lg:text-4xl font-semibold ">
                Order Detail
            </h2>
            <span className="block mt-4 text-neutral-500 dark:text-neutral-400 text-sm sm:text-base">
                #{data?.order?.code}
            </span>
            </div>
            <div className="flex flex-col justify-end">
            <Badge className="mt-1" name={processedData?.order?.state==="PaymentAuthorized"?"Order Placed":processedData?.order?.state} />
            {processedData?.order?.orderPlacedAt &&
            <p className="mt-1 text-neutral-400 " >{formatDateToDDMMYYYY(processedData?.order?.orderPlacedAt)}</p>
            }
            </div>
        </div>

      

        <hr className="border-slate-200 dark:border-slate-700 my-10 xl:my-12" />

        <div className="flex flex-col relative lg:flex-row">
          <div className="w-full lg:w-[60%] xl:w-[55%] divide-y divide-slate-200 dark:divide-slate-700">
            {processedData?.order?.lines.map(renderProduct) }
          </div>

          <div className="border-t lg:border-t-0 lg:border-l border-slate-200 dark:border-slate-700 my-10 lg:my-0 lg:mx-10 xl:mx-16 2xl:mx-20 flex-shrink-0"></div>
          <div className="flex-1">
            <div className="my-4">
              <h3 className="text-lg font-semibold ">Shipping Address</h3>
              <div className="mt-7 text-sm text-slate-500 dark:text-slate-400 divide-y divide-slate-200/70 dark:divide-slate-700/80">
                <div className=" pb-4">
                 
                <p className=" text-slate-500 dark:text-slate-200">
                    {processedData?.order?.shippingAddress?.fullName && (<><span className="font-bold">{processedData?.order?.shippingAddress?.fullName}</span><br /></>)}
                    {processedData?.order?.shippingAddress?.streetLine1 && (<span>{processedData?.order?.shippingAddress?.streetLine1},</span>)}
                    {processedData?.order?.shippingAddress?.streetLine2 && (<><span> {processedData?.order?.shippingAddress?.streetLine2}</span><br /></>)}
                    {processedData?.order?.shippingAddress?.city && (<span> {processedData?.order?.shippingAddress?.city},</span>)}
                    {processedData?.order?.shippingAddress?.province && (<><span> {processedData?.order?.shippingAddress?.province},</span><br /></>)}
                    {processedData?.order?.shippingAddress?.postalCode && (<span>{processedData?.order?.shippingAddress?.postalCode} </span>)}
                    {processedData?.order?.shippingAddress?.country && (<><span>{processedData?.order?.shippingAddress?.country},  </span><br /></>)}
                    {processedData?.order?.shippingAddress?.phoneNumber && (<span>Phone: {processedData?.order?.shippingAddress?.phoneNumber} </span>)}
                  
                </p>

                 
                </div>
             
              </div>
            </div>

            <div className="my-4">
              <h3 className="text-lg font-semibold ">Order Detail</h3>
              <div className="mt-7 text-sm text-slate-500 dark:text-slate-400 divide-y divide-slate-200/70 dark:divide-slate-700/80">
                <div className="flex justify-between pb-4">
                  <span>Subtotal</span>
                  <span className="font-semibold text-slate-900 dark:text-slate-200">
                  
                    {formatCurrencyINR(processedData?.order?.subTotal || 0)}
                  </span>
                </div>
                
                <div className="flex justify-between py-4">
                  <span>Tax estimate</span>
                  <span className="font-semibold text-slate-900 dark:text-slate-200">
                    {processedData?.order?.totalWithTax && processedData?.order?.total &&
                     formatCurrencyINR(processedData?.order?.totalWithTax - processedData?.order?.total)
                    }
                  </span>
                </div>
                <div className="flex justify-between font-semibold text-slate-900 dark:text-slate-200 text-base pt-4">
                  <span>Order total</span>
                  <span>{formatCurrencyINR(processedData?.order?.totalWithTax || 0)} </span>
                </div>
              </div>
           
               {processedData && (
                   <PDFDownloadLink  document={<MyDoc data={processedData} seller={seller}   />} fileName={`${orderPdfName}.pdf`}>
                   {({ blob, url, loading, error }) =>
                       loading ? 'Loading document...' : (<ButtonPrimary className="mt-8 w-full">
                       Download
                     </ButtonPrimary>)
                   }
                   </PDFDownloadLink>
               )}
               

            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default AccountOrderDetail;



