import React, { FC } from "react";
import { useInView } from "react-intersection-observer";
import welcomeKit from "../../images/SectionOurPreCreatedPacks/welcomeKits.svg";
import joiningKit from "../../images/SectionOurPreCreatedPacks/joiningKits.svg";
import welnessKit from "../../images/SectionOurPreCreatedPacks/welnessKits.svg";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import "../../styles/SectionHowItWork.css";

const SectionOurBrandPartners: FC = () => {
  const kits = [
    { image: joiningKit, title: "Festival Kits" },
    { image: welnessKit, title: "Wellness Kits" },
    { image: welcomeKit, title: "Welcome Kits" },
  ];

  const threshold = {
    mobile: 0.1,
    desktop: 0.2,
  };

  const { ref, inView } = useInView({
    threshold: threshold.mobile,
  });

  const isDesktop = window.innerWidth > 768;
  if (isDesktop) {
    threshold.mobile = threshold.desktop;
  }

  return (
    <div ref={ref} className="w-full  py-4 md:py-6 bg-[#F8F8F8]">
      <div className="flex justify-between items-center mb-8 px-2 lg:px-16">
        <div className="text-left">
          <h2 className="text-xl md:text-3xl font-righteous font-medium tracking-wide">
            Our Pre Curated Packs
          </h2>
          <p className="text-sm text-black dark:text-neutral-100 font-inter">
            Discover Our readymade packs
          </p>
        </div>
        <div>
          <ButtonPrimary
            href={"/product-packs"}
            className="px-4 py-2 md:px-4 md:py-2 text-blue-500 bg-transparent rounded-md border border-blue-400 text-xs"
          >
            View All
          </ButtonPrimary>
        </div>
      </div>
      <div className="grid grid-cols-1 gap-4 mx-auto md:grid-cols-2 lg:grid-cols-3 lg:max-w-[95%]">
        {kits.map((kit, index) => (
          <div
            key={index}
            className={`animated-card w-full mx-auto h-auto ${
              inView ? "fade-in" : "fade-out"
            }`}
          >
            <img
              src={kit.image}
              alt={kit.title}
              className="w-[90%] mx-auto h-auto"
            />
            <div className="flex justify-between px-6 lg:px-8 mx-auto mt-4">
              <p className="text-center text-lg font-semibold">{kit.title}</p>
              <span
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  background: "black",
                  borderRadius: "50%",
                  width: "2rem",
                  height: "2rem",
                  color: "white",
                  cursor: "pointer",
                  fontSize: "24px",
                }}
              >
                &gt;
              </span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SectionOurBrandPartners;
