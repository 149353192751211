import { useMutation, useQuery } from "@apollo/client";
import { NoSymbolIcon, CheckIcon } from "@heroicons/react/24/outline";
import { addItemToOrderMutation } from "graphql/mutations/add-item-to-order";
import { removeItemFromOrderMutation } from "graphql/mutations/remove-from-cart.mutation";
import { Orders, myCartQuery } from "graphql/queries/queries";
import { MyCartQueryResponse } from "graphql/queries/types";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { toast } from "react-hot-toast";
import RemoveAllOrderMutation from "graphql/mutations/remove-all-order";
import modifyOrderMutation from "graphql/mutations/modify-order";
import formatCurrencyINR from "utils/convertNumbToCurrency";
import NcInputNumber from "components/NcInputNumber";
import useOrderStore from "hooks/useCart";
import NcImage from "shared/NcImage/NcImage";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";

const CartPage = () => {
  const [flag, setFlag] = useState(true);
  const { data, loading, error, refetch } = useQuery<MyCartQueryResponse>(
    myCartQuery,
    { fetchPolicy: "no-cache" }
  );

  const orders = useQuery(Orders, { fetchPolicy: "no-cache" });

  const orderStore = useOrderStore((state) => {
    return {
      setOrder: state.setOrder,
      order: state.order,
      resetOrder: state.resetOrder,
    };
  });

  const [cartData, setCartData] = useState<MyCartQueryResponse>({
    activeOrder: null,
  });

  const [addItemToOrder, addOrderProp] = useMutation(addItemToOrderMutation);
  const [modifyOrder, modifyOrderProp] = useMutation(modifyOrderMutation);
  const [removeOrder, removeOrderProp] = useMutation(
    removeItemFromOrderMutation
  );
  const [removeAllOrder, removeAllOrderProp] = useMutation(
    RemoveAllOrderMutation
  );

  const handleCartChange = (
    id: string,
    productId: string,
    qty: number,
    isAdding: Boolean,
    minQuantity: number
  ) => {
    if (isAdding) {
      addItemToOrder({ variables: { productId: productId, qty: qty } }).then(
        (res) => {
          if (res.data?.addItemToOrder?.__typename === "Order") {
            const code = res.data.addItemToOrder.code;
            const id = res.data.addItemToOrder.id;
            const lines = res.data.addItemToOrder.lines;
            orderStore.setOrder({ code: code, id: id, lines: lines });
          }
        }
      );
    } else {
      if (qty < minQuantity) {
        toast.error("Minimum quantity reached");
        return;
      }

      modifyOrder({ variables: { orderLineId: id, quantity: qty } }).then(
        (res) => {
          if (res.data?.adjustOrderLine?.__typename === "Order") {
            const code = res.data.adjustOrderLine.code;
            const id = res.data.adjustOrderLine.id;
            const lines = res.data.adjustOrderLine.lines;
            orderStore.setOrder({ code: code, id: id, lines: lines });
          }
        }
      );
    }
  };

  const handleRemoveOrder = ({
    lineId,
    productId,
    index,
  }: {
    lineId: string;
    productId: string;
    index: number;
  }) => {
    removeOrder({ variables: { id: lineId } }).then((res) => {
      if (orderStore.order && orderStore.order.lines) {
        const updatedOrder = { ...orderStore.order };
        updatedOrder.lines.splice(index, 1);
        orderStore.setOrder(updatedOrder);
      }
    });
  };

  // Function For Remove All Products
  const handleClearOrder = () => {
    orderStore.resetOrder();

    removeAllOrder()
      .then((res) => {
        if (res && res.data && res.data.removeAllOrderLines) {
          setFlag(false);
        } else {
          console.error(
            "Error: No data returned after removing all products from the order."
          );
        }
      })
      .catch((error) => {
        console.error("Error clearing order:", error);
        orderStore.resetOrder();
      });
  };

  useEffect(() => {
    refetch();
  });

  useEffect(() => {
    if (!loading && data) {
      setCartData({ ...cartData, ...data });
    }
    // set flag here to only fetch the data if flag is true
    if (!orders.loading && orders.data && flag) {
      orderStore.setOrder({
        code: orders?.data?.activeOrder?.code,
        id: orders?.data?.activeOrder?.id,
        lines: orders?.data?.activeOrder?.lines,
      });
    }

    if (!addOrderProp.loading && addOrderProp.data) {
      if (addOrderProp.data?.addItemToOrder?.__typename !== "Order") {
        toast.error(addOrderProp.data?.addItemToOrder?.message);
      }
    }
  }, [
    data,
    removeOrderProp.data,
    addOrderProp.data,
    modifyOrderProp.data,
    orders.loading,
    orders.data,
  ]);

  const renderStatusSoldout = () => {
    return (
      <div className="rounded-full flex items-center justify-center px-2.5 py-1.5 text-xs text-slate-700 dark:text-slate-300 border border-slate-200 dark:border-slate-700">
        <NoSymbolIcon className="w-3.5 h-3.5" />
        <span className="ml-1 leading-none font-inter">Sold Out</span>
      </div>
    );
  };

  const renderStatusInstock = () => {
    return (
      <div className="rounded-full flex items-center justify-center px-2.5 py-1.5 text-xs text-slate-700 dark:text-slate-300 border border-[#939393] dark:border-slate-700">
        <CheckIcon className="w-3.5 h-3.5" />
        <span className="ml-1 leading-none font-inter">In Stock</span>
      </div>
    );
  };

  const renderProduct = (item: any, index: number) => {
    const id = item?.id;
    const productId = item?.productVariant?.id;
    const image = item?.productVariant?.product?.featuredAsset?.preview;
    const price = item?.linePriceWithTax;
    const name = item?.productVariant.name;
    const quantity = item?.quantity;
    const minQuantity =
      item?.productVariant?.product?.customFields?.minOrderQuantity || 1;
    const slug = item?.productVariant?.product?.slug;

    return (
      <div
        key={index}
        className="relative flex py-8 sm:py-10 xl:py-12 first:pt-0 last:pb-0"
      >
        <div className="relative h-36 w-24 sm:w-32 flex-shrink-0 overflow-hidden rounded-xl bg-slate-100">
          <NcImage
            containerClassName="h-full w-full object-cover object-center"
            src={image}
            alt={name}
            className="h-full w-full object-cover object-center"
          />
          <Link to={"/product/" + slug} className="absolute inset-0"></Link>
        </div>

        <div className="ml-3 sm:ml-6 flex flex-1 flex-col">
          <div className="flex flex-col sm:flex-row">
            <div className="flex justify-between ">
              <div className="flex-[1.5] ">
                <h3 className="text-lg font-semibold font-inter">
                  <Link to={"/product/" + slug}>{name}</Link>
                </h3>
              </div>
            </div>
          </div>
          <div className="flex flex-row items-center justify-between gap-4 md:gap-14 mt-5 pr-6 relative">
            <h2 className="text-black text-lg font-semibold font-inter">
              {formatCurrencyINR(price)}
            </h2>
            <NcInputNumber
              defaultValue={quantity}
              id={id}
              productId={productId}
              min={minQuantity}
              onCartChange={handleCartChange}
              className="relative z-10 w-[7rem] md:w-28 px-3"
            />
          </div>

          <div className="flex flex-row mt-4 py-2 pr-7 items-center justify-between text-sm font-inter">
            {renderStatusInstock()}
            <button
              onClick={() => {
                handleRemoveOrder({
                  lineId: id,
                  productId: productId,
                  index: index,
                });
              }}
              className="relative z-10 flex items-center font-medium text-primary-6000 hover:text-primary-500 text-sm"
            >
              <span>Remove</span>
            </button>
          </div>
        </div>
      </div>
    );
  };

  if (
    loading ||
    removeOrderProp.loading ||
    modifyOrderProp.loading ||
    addOrderProp.loading ||
    removeAllOrderProp.loading
  )
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-primary-500"></div>
      </div>
    );

  if (
    error ||
    removeOrderProp.error ||
    modifyOrderProp.error ||
    addOrderProp.error ||
    removeAllOrderProp.error
  )
    return (
      <div className="flex flex-col justify-center items-center h-screen">
        <h3 className="text-lg font-semibold font-inter my-3">
          Something went wrong
        </h3>
        <ButtonPrimary
          onClick={() => {
            refetch();
          }}
        >
          Retry
        </ButtonPrimary>
      </div>
    );

  return (
    <div className="nc-CartPage">
      <Helmet>
        <title>Shopping Cart || 360CUSTOMIZER</title>
      </Helmet>

      <main className="container py-10 lg:pb-28 lg:pt-10">
        <div className="mb-2">
          <h2 className="block text-2xl sm:text-3xl lg:text-4xl font-medium font-righteous">
            Shopping Cart
          </h2>
        </div>

        <hr className="border-slate-300 dark:border-slate-700 my-10 xl:my-12" />
        {!loading &&
        cartData?.activeOrder?.lines.length &&
        cartData?.activeOrder?.lines.length > 0 ? (
          <div className="flex flex-col relative lg:flex-row">
            <div className="w-full lg:w-[60%] xl:w-[55%] divide-y divide-slate-300 dark:divide-slate-700">
              {cartData.activeOrder.lines.map(renderProduct)}
              <div className="flex justify-end items-center pb-4">
                <ButtonSecondary
                  onClick={handleClearOrder}
                  className="relative z-10 flex items-center my-3 font-medium text-primary-6000 hover:text-[#0068FF] text-sm font-inter rounded-md"
                >
                  Clear All
                </ButtonSecondary>
              </div>
            </div>

            <div className="border-t lg:border-t-0 lg:border-l border-slate-300 dark:border-slate-700 my-10 lg:my-0 lg:mx-10 xl:mx-16 2xl:mx-20 flex-shrink-0"></div>
            <div className="flex-1">
              <div className="sticky top-28">
                <h3 className="text-lg font-semibold font-inter">
                  Order Summary
                </h3>
                <div className="mt-7 text-sm text-slate-500 dark:text-slate-400 divide-y divide-slate-300/70 dark:divide-slate-700/80">
                  <div className="flex justify-between py-4 font-inter">
                    <span>Tax estimate</span>
                    <span className="font-semibold font-inter text-slate-900 dark:text-slate-200">
                      {data?.activeOrder?.totalWithTax &&
                        data?.activeOrder?.total &&
                        formatCurrencyINR(
                          data?.activeOrder?.totalWithTax -
                            data?.activeOrder?.total
                        )}
                    </span>
                  </div>
                  <div className="flex justify-between font-semibold font-inter text-slate-900 dark:text-slate-200 text-base pt-4">
                    <span>Order total</span>
                    <span>
                      {formatCurrencyINR(data?.activeOrder?.totalWithTax || 0)}{" "}
                    </span>
                  </div>
                </div>
                {cartData?.activeOrder?.lines.length === 0 ? (
                  <ButtonPrimary
                    className="mt-8 w-full  hover:bg-[#0068FF] rounded-md font-inter"
                    disabled={false}
                  >
                    Continue
                  </ButtonPrimary>
                ) : (
                  <ButtonPrimary
                    href="/checkout"
                    className="mt-8 w-full hover:bg-[#0068FF] rounded-md font-inter"
                  >
                    Continue
                  </ButtonPrimary>
                )}
              </div>
            </div>
          </div>
        ) : (
          <div className="flex flex-col justify-center  items-center">
            <h3 className="text-lg font-semibold ">Your cart is empty</h3>

            <ButtonPrimary href="/page-collection" className="my-4">
              Shop Now
            </ButtonPrimary>
          </div>
        )}
      </main>
    </div>
  );
};

export default CartPage;
