import React from "react";
import { useQuery } from "@apollo/client";
import { SELLER_SOCIAL_LINKS } from "graphql/queries/queries";
import { SocialType } from "shared/SocialsShare/SocialsShare";
import instagram from "../../images/socials/instagram.svg";
import linkedin from "../../images/socials/linkedIn.svg";
import facebook from "../../images/socials/facebook.svg";

export interface SocialLinks {
  activeChannel: {
    seller: {
      customFields: {
        facebook: string | null;
        twitter: string | null;
        instagram: string | null;
        telegram: string | null;
        youtube: string | null;
        linkedin: string | null;
      };
    };
  };
}

export interface SocialsList1Props {
  className?: string;
  links?: SocialLinks;
}

const SocialsList1: React.FC<SocialsList1Props> = ({
  className = "space-y-3",
}) => {
  const { data, loading, error } = useQuery<SocialLinks>(SELLER_SOCIAL_LINKS, {
    fetchPolicy: "no-cache",
  });

  if (loading || error) return null;

  const links = data?.activeChannel?.seller?.customFields;

  const socials: SocialType[] = [
    {
      name: "Instagram",
      icon: instagram,
      href: links?.instagram || "#",
    },
    {
      name: "LinkedIn",
      icon: linkedin,
      href: links?.linkedin || "#",
    },
    {
      name: "Facebook",
      icon: facebook,
      href: links?.facebook || "#",
    },
  ].filter((social) => social.href !== "#");

  const renderItem = (item: SocialType, index: number) => (
    <a
      href={item.href}
      target="_blank"
      rel="noopener noreferrer"
      className="flex items-end text-4xl text-white hover:text-gray-500 dark:text-white dark:hover:text-gray-500 leading-none space-x-2 group"
      key={index}
    >
      <img src={item.icon} alt={item.name} className="w-10 m-2" />
    </a>
  );

  return (
    <div
      className={`flex flex-col justify-start items-end ${className}`}
      data-nc-id="SocialsList1"
    >
      {socials.map((item, index) => renderItem(item, index))}
    </div>
  );
};

export default SocialsList1;
