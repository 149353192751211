import React from "react";

export interface SectionHeroProps {
  className?: string;
  rightImg: string;
}

const SectionHero: React.FC<SectionHeroProps> = ({
  className = "",
  rightImg,
}) => {
  return (
    <div
      className={`nc-SectionHero relative  ${className}`}
      data-nc-id="SectionHero"
    >
      <div className="flex flex-col lg:flex-row space-y-14 lg:space-y-0 lg:space-x-10 items-center relative text-center lg:text-left">
        <div className="flex-grow">
          <img className="w-full" src={rightImg} alt="" />
        </div>
      </div>
    </div>
  );
};

export default SectionHero;
