import CardCategory1 from "components/CardCategories/CardCategory1";
import CardCategory4 from "components/CardCategories/CardCategory4";
import Heading from "components/Heading/Heading";
import NavItem2 from "components/NavItem2";
import React, { FC, useEffect } from "react";
import Nav from "shared/Nav/Nav";
import explore1Svg from "images/collections/explore1.svg";
import explore2Svg from "images/collections/explore2.svg";
import explore3Svg from "images/collections/explore3.svg";
import explore4Svg from "images/collections/explore4.svg";
import explore5Svg from "images/collections/explore5.svg";
import explore6Svg from "images/collections/explore6.svg";
import explore7Svg from "images/collections/explore7.svg";
import explore8Svg from "images/collections/explore8.svg";
import explore9Svg from "images/collections/explore9.svg";
import explore1Png from "images/collections/explore1.png";
import explore2Png from "images/collections/explore2.png";
import explore3Png from "images/collections/explore3.png";
import explore4Png from "images/collections/explore4.png";
import explore5Png from "images/collections/explore5.png";
import explore6Png from "images/collections/explore6.png";
import explore7Png from "images/collections/explore7.png";
import explore8Png from "images/collections/explore8.png";
import explore9Png from "images/collections/explore9.png";
import CardCategory6 from "components/CardCategories/CardCategory6";
import { useQuery } from "@apollo/client";
import { FacetItem, FacetValue, FacetsData } from "graphql/queries/types";
import { GET_FACETS } from "graphql/queries/queries";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import { Builder } from "@builder.io/react";

interface ExploreType {
  id: number;
  name: string;
  desc: string;
  image: string;
  svgBg: string;
  color?: string;
}

export interface SectionGridMoreExploreProps {
  className?: string;
  gridClassName?: string;
  boxCard?: "box1" | "box4" | "box6";
  title?: string;
}

const bgSVGs = [
  explore1Svg,
  explore2Svg,
  explore3Svg,
  explore4Svg,
  explore5Svg,
];

const getRandBgSVG = () => {
  return bgSVGs[Math.floor(Math.random() * bgSVGs.length)];
};

const SectionGridMoreExplore: FC<SectionGridMoreExploreProps> = ({
  className = "",
  boxCard = "box4",
  gridClassName = "grid-cols-1 md:grid-cols-2 xl:grid-cols-3",
  title = "Categories",
}) => {
  const facetData = useQuery<FacetsData>(GET_FACETS);

  const [facets, setFacets] = React.useState<FacetItem[]>([]);

  const [facetsValues, setFacetsValues] = React.useState<FacetValue[]>([]);

  const [tabActive, setTabActive] = React.useState("");

  useEffect(() => {
    if (!facetData.loading && facetData.data) {
      // get only 6 featured facets

      let topFacets = facetData.data.facets.items.filter(
        (facet: FacetItem, i) => {
          return facet.customFields.isFeatured && i < 6;
        }
      );

      if (topFacets.length === 0) {
        topFacets = facetData.data.facets.items.filter((_, i) => {
          return i < 6;
        });
      }

      setFacets([...topFacets]);

      // get only 6 facets values
      let topFacetsValues = facetData.data.facets.items[0].values.filter(
        (_, i) => i < 6
      );
      setFacetsValues([...topFacetsValues]);

      // set tab active
      setTabActive(topFacets[0].name);
    }
  }, [facetData.data, facetData.loading]);

  useEffect(() => {
    if (tabActive) {
      let facet = facetData.data?.facets.items.find(
        (item) => item.name === tabActive
      );
      // get top 6 facets values
      let topFacetsValues = facet?.values.filter((_, i) => i < 6);
      if (topFacetsValues) {
        setFacetsValues([...topFacetsValues]);
      }
    }
  }, [tabActive]);

  const renderHeading = () => {
    return (
      <div>
        <Heading
          className="mb-12 lg:mb-14 text-neutral-900 dark:text-neutral-50"
          fontClass="text-3xl md:text-4xl 2xl:text-5xl font-semibold"
          isCenter
          desc=""
        >
          {title}
        </Heading>
        <Nav
          className="p-1 bg-white dark:bg-neutral-800 rounded-full shadow-lg overflow-x-auto hiddenScrollbar"
          containerClassName="mb-12 lg:mb-14 relative flex justify-center w-full text-sm md:text-base"
        >
          {facets.map((item, index) => (
            <NavItem2
              key={index}
              isActive={tabActive === item.name}
              onClick={() => setTabActive(item.name)}
            >
              <div className="flex items-center justify-center space-x-1.5 sm:space-x-2.5 text-xs sm:text-sm ">
                {/* <span
                  className="inline-block"
                  dangerouslySetInnerHTML={{ __html: item.icon }}
                ></span> */}
                <span>{item.name}</span>
              </div>
            </NavItem2>
          ))}
        </Nav>
      </div>
    );
  };

  return (
    <div
      className={`nc-SectionGridMoreExplore relative ${className}`}
      data-nc-id="SectionGridMoreExplore"
    >
      {renderHeading()}
      <div className={`grid gap-4 md:gap-7 ${gridClassName}`}>
        {facetsValues.map((item) => {
          return (
            <CardCategory4
              name={item.name}
              desc={""}
              bgSVG={getRandBgSVG()}
              featuredImage={item?.customFields?.icon?.preview || ""}
              key={item.id}
              id={item.id}
              color={""}
            />
          );
        })}
      </div>
    </div>
  );
};
export const SectionGridMoreExploreWraper = ({ title = "Categories" }) => {
  return (
    <div className="relative py-24 lg:py-32">
      <BackgroundSection />
      <SectionGridMoreExplore title={title} />
    </div>
  );
};

Builder.registerComponent(SectionGridMoreExploreWraper, {
  name: "Categories",

  inputs: [{ name: "title", type: "string" }],
  defaultChildren: [],
});
export default SectionGridMoreExplore;
