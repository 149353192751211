import { gql } from "@apollo/client";

export const updateKitNameMutaion = gql`
  mutation ($orderId: ID!, $kitName: String!) {
        updateOrderKitName(orderId:$orderId,kitName:$kitName){
          __typename
        }
      
  }
`;
