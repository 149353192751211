import React, { FC, useEffect, useState } from "react";
import Logo from "shared/Logo/Logo";
import MenuBar from "shared/MenuBar/MenuBar";
import AvatarDropdown from "./AvatarDropdown";
import Navigation from "shared/Navigation/Navigation";
import CartDropdown from "./CartDropdown";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { GET_HEADER_MENU } from "graphql/queries/queries";
import useCurrentUser from "hooks/useCurrentUser";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import logoWhite from "../../images/360logo/360WhiteLogo.svg";

export interface MainNav2LoggedProps {}

const MainNav2Logged: FC<MainNav2LoggedProps> = () => {
  const getUser = useCurrentUser((state) => {
    return {
      user: state.user,
      getUser: state.getUser,
      isLoggedIn: state.isUserLoggedIn,
    };
  });

  const { data, loading, error } = useQuery(GET_HEADER_MENU, {
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    getUser.getUser();
  }, []);

  const inputRef = React.createRef<HTMLInputElement>();
  const [showSearchForm, setShowSearchForm] = useState(false);
  const navigate = useNavigate();

  // skeleton loader for loading state
  if (loading)
    return (
      <div className="nc-MainNav2Logged relative z-10 bg-white dark:bg-neutral-900 border-b border-slate-100 dark:border-slate-700">
        <div className="container">
          <div className="h-20 flex justify-between align-baseline">
            <div className="flex items-center lg:hidden flex-1">
              {/* Skeleton loader for the first section */}
              <div className="animate-pulse bg-gray-200 dark:bg-gray-700 h-5 w-20 rounded-md"></div>
            </div>
            <div className="lg:flex-1 flex items-center">
              {/* Skeleton loader for the second section */}
              <div className="animate-pulse bg-gray-200 dark:bg-gray-700 h-5 w-32 rounded-md"></div>
            </div>

            <div className="flex-2 hidden lg:flex justify-center items-center mx-4">
              {/* Skeleton loader for the third section */}
              <div className="animate-pulse bg-gray-200 dark:bg-gray-700 h-8 w-40 rounded-md"></div>
            </div>

            <div className="flex-1 flex items-center justify-end text-slate-700 dark:text-slate-100">
              {/* Skeleton loader for the fourth section */}
              <div className="animate-pulse bg-gray-200 dark:bg-gray-700 h-5 w-24 rounded-md"></div>
            </div>
          </div>
        </div>
      </div>
    );

  const renderMagnifyingGlassIcon = () => {
    return (
      <svg
        width={22}
        height={22}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11.5 21C16.7467 21 21 16.7467 21 11.5C21 6.25329 16.7467 2 11.5 2C6.25329 2 2 6.25329 2 11.5C2 16.7467 6.25329 21 11.5 21Z"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M22 22L20 20"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  };

  const renderSearchForm = () => {
    return (
      <form
        onSubmit={(e) => {
          e.preventDefault();
          navigate(`/search/?term=${inputRef.current?.value}`);
          setShowSearchForm(false);
        }}
        className="flex-1 py-2 text-slate-900 dark:text-slate-100"
      >
        <div className="bg-slate-50 dark:bg-slate-800 flex items-center space-x-1.5 px-5 h-full rounded">
          {renderMagnifyingGlassIcon()}
          <input
            ref={inputRef}
            type="text"
            placeholder="Type and press enter"
            className="border-none bg-transparent focus:outline-none focus:ring-0 w-full text-base"
            autoFocus
          />
          <button type="button" onClick={() => setShowSearchForm(false)}>
            <XMarkIcon className="w-5 h-5" />
          </button>
        </div>
        <input type="submit" hidden value="" />
      </form>
    );
  };

  const renderContent = () => {
    return (
      <div className="h-20 flex justify-between">
        <div className="flex items-center lg:hidden flex-1">
          <MenuBar data={data} />
        </div>

        <div className="lg:flex-1 flex items-center">
          <Logo imgLight={logoWhite} className="flex-shrink-0" />
        </div>

        <div className="flex-[2] hidden lg:flex justify-center mx-4">
          {showSearchForm ? renderSearchForm() : <Navigation data={data} />}
        </div>

        <div className="flex-1 flex items-center justify-end text-slate-700 dark:text-slate-100">
          {!showSearchForm && (
            <button
              className="hidden lg:flex w-10 h-10 sm:w-12 sm:h-12 rounded-full text-slate-700 dark:text-slate-300 hover:bg-slate-100 dark:hover:bg-slate-800 focus:outline-none items-center justify-center"
              onClick={() => setShowSearchForm(!showSearchForm)}
            >
              {renderMagnifyingGlassIcon()}
            </button>
          )}
          {/* <AvatarDropdown /> */}
          {getUser.isLoggedIn ? (
            <AvatarDropdown />
          ) : (
            // <ButtonSecondary href="/login"> Login</ButtonSecondary>
            <ButtonPrimary
              href="/login"
              sizeClass="py-2 px-4"
              className="bg-[#0068FF] rounded-md text-slate-50"
            >
              Login
            </ButtonPrimary>
          )}

          <CartDropdown />
        </div>
      </div>
    );
  };

  return (
    <div className="nc-MainNav2Logged relative z-10 bg-white dark:bg-neutral-900 border-b border-slate-100 dark:border-slate-700">
      <div className="container ">{renderContent()}</div>
    </div>
  );
};

export default MainNav2Logged;
