import { gql } from "@apollo/client";


export const requestPasswordReset = gql`
  mutation($email: String!) {
    requestPasswordReset(emailAddress: $email) {
      __typename
    }
  }
`;


export const resetPasswordMutation = gql`
  mutation($token: String!, $password: String!) {
    resetPassword(token: $token, password: $password) {
      __typename
    }
  }
`;