import formatCurrencyINR from "utils/convertNumbToCurrency";
import {
  Document,
  Page,
  View,
  Text,
  Image,
  StyleSheet,
  Link as PDFLink,
  Font,
} from "@react-pdf/renderer";
import cheerio from "cheerio";
import { MyCartQueryResponse } from "graphql/queries/types";
import websiteBrandingCover from "../../images/360logo/diwaliBrandingCover.png";
import websiteEndingCover from "../../images/360logo/diwaliEndingCover.png";
import diwaliHeader from "../../images/360logo/diwaliHeader.png";
import diwaliFooter from "../../images/360logo/diwaliFooter.png";

const DemoImageURL = "/assets/placeholder-small.png";

interface OrderDetail {
  order: {
    id: string;
    code: string;
    state: string;
    subTotal: number;
    total: number;
    subTotalWithTax: number;
    totalWithTax: number;
    orderPlacedAt: string;
    lines: OrderLine[];
    shippingAddress: ShippingAddress;
    customFields?: {
      kitName?: string;
    };
  };
}

interface OrderLine {
  id: string;
  unitPrice: number;
  linePrice: number;
  unitPriceWithTax: number;
  linePriceWithTax: number;
  quantity: number;
  imageUrl?: string;
  assets?: Asset[] | [];
  productVariant: ProductVariant;
}

interface CartLine {
  id: string;
  unitPrice: number;
  unitPriceWithTax: number;
  linePrice: number;
  linePriceWithTax: number;
  quantity: number;
  imageUrl?: string;
  assets?: {
    preview: string;
  }[];
  productVariant: {
    id: string;
    sku: string;
    name: string;
    price: number;
    product: {
      name: string;
      slug: string;
      description?: string;
      featuredAsset: {
        source: string;
      };

      customFields: {
        minOrderQuantity: number | null;
      };
    };
  };
}

interface Asset {
  id: string;
  preview: string;
}

interface ProductVariant {
  id: string;
  product: Product;
  name: string;
  sku: string;
  assets: Asset[] | [];
}

interface Product {
  slug: string;
  description: string;
  featuredAsset: {
    preview: string;
  };
  assets: Asset[] | [];
}

interface ShippingAddress {
  fullName: string;
  postalCode: string;
  streetLine1: string;
  streetLine2: string;
  city: string;
  province: string;
  country: string;
  phoneNumber: string;
}

// @ts-ignore
Font.register({
  family: "Righteous",
  fonts: [
    {
      src: "/assets/fonts/Righteous-Regular.ttf",
      fontWeight: "normal",
    },
    {
      src: "/assets/fonts/Righteous-Regular.ttf",
      fontWeight: "bold",
    },
  ],
});

Font.register({
  family: "Poppins",
  fonts: [
    {
      src: "/assets/fonts/Poppins/Poppins-Light.ttf",
      fontWeight: "light",
    },
    {
      src: "/assets/fonts/Poppins/Poppins-Bold.ttf",
      fontWeight: "bold",
    },
  ],
});

const monthNames: string[] = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

function formatDateToDDMMYYYY(dateString: string): string {
  const date = new Date(dateString);

  const day = date.getDate().toString().padStart(2, "0");
  const month = monthNames[date.getMonth()];
  const year = date.getFullYear().toString();

  return `${day} ${month} ${year}`;
}
const pdfStylesData = {
  primaryColor: "#30b0ba",
  secondaryColor: "#373435",
  thirdColor: "#413f40",
};

const isImageURLValid = async (url: any) => {
  try {
    const response = await fetch(url);
    return response.ok;
  } catch (error) {
    return false;
  }
};

const styles = StyleSheet.create({
  page: {
    padding: 0,
  },
  fullPage: {
    width: "100%",
    height: "100%",
  },
  header: {
    width: "100%",
    height: "auto",
  },
  fullImage: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
  overlayTextContainer: {
    width: "100%",
    position: "absolute",
    top: 30,
    left: 2,
    transform: "translate(-50%, -50%)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  overlayText: {
    position: "absolute",
    fontSize: 18,
    top: "25%",
    left: "30%",
    color: "white",
    fontWeight: "bold",
    textAlign: "center",
    transform: "translate(-50%, -50%)",
  },
  catalogLine: {
    height: 5,
    backgroundColor: "black",
    borderRadius: "20px",
    textAlign: "center",
    border: 0,
  },
  catalogContainer: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    minWidth: "30%",
    margin: "auto",
    backgroundColor: "black",
    borderRadius: "12px",
    marginTop: 2,
  },
  catalogText: {
    padding: 8,
    fontSize: 12,
    color: "white",
    fontWeight: "bold",
    textAlign: "center",
    fontFamily: "Righteous",
  },
  container: {
    height: "87%",
    fontFamily: "Righteous",
    fontWeight: "normal",
    justifyContent: "center",
    alignItems: "center",
    overflow: "hidden",
  },
  footer: {
    gap: 10,
    height: "5%",
    width: "100%",
    overflow: "hidden",
    alignItems: "center",
    flexDirection: "row",
    alignContent: "flex-end",
    justifyContent: "space-between",
    marginHorizontal: "auto",
    boxSizing: "border-box",
    backgroundColor: "#000000",
  },
  companyLogoWrapper: {
    width: "20%",
    height: "100%",
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "center",
  },
  companyLogo: {
    width: "30%",
    height: "auto",
  },
  contactInfo: {
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center",
    fontFamily: "Righteous",
    fontSize: 12,
    fontWeight: "medium",
  },
  phoneNumber: {
    fontSize: 12,
    minHeight: 20,
    minWidth: 20,
    color: pdfStylesData.secondaryColor,
  },
  websiteLink: {
    textDecoration: "none",
    fontWeight: "bold",
    fontFamily: "Righteous",
  },
  website: {
    color: "#fff",
    letterSpacing: 3,
    fontSize: 14,
    fontWeight: "bold",
    fontFamily: "Righteous",
  },
  totalBox: {
    gap: 1,
    height: 30,
    width: "20%",
    borderRadius: 5,
    paddingRight: 20,
    paddingHorizontal: 10,
    alignItems: "center",
    flexDirection: "column",
    boxSizing: "border-box",
    justifyContent: "space-evenly",
  },
  placeOrderBtn: {
    backgroundColor: "#fff",
    color: pdfStylesData.primaryColor,
    width: 80,
    borderRadius: 5,
    padding: 10,
    boxSizing: "border-box",
  },
  totalText: {
    fontSize: 8,
    boxSizing: "border-box",
    color: "#fff",
    fontWeight: "light",
    fontFamily: "Poppins",
  },
  totalAmount: {
    fontSize: 10,
    color: "#fff",
    fontWeight: "bold",
    fontFamily: "Poppins",
    boxSizing: "border-box",
  },
});

const singleProductStyles = StyleSheet.create({
  container: {
    padding: 15,
    width: "100%",
    height: "100%",
    display: "flex",
    marginHorizontal: "auto",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  card: {
    gap: 10,
    padding: 20,
    borderRadius: 5,
    width: "100%",
    height: "100%",
    maxWidth: "90%",
    maxHeight: "90%",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
  },
  mainImageContainer: {
    margin: "auto",
    width: "90%",
    height: "100%",
    maxHeight: "70%",
    justifyContent: "center",
    alignItems: "center",
    border: "2px solid black",
    borderTopRightRadius: "42px",
  },
  content: {
    height: "30%",
    width: "90%",
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },
  image: {
    width: "85%",
    height: "85%",
    objectFit: "contain",
  },
  heading: {
    fontSize: 22,
    color: pdfStylesData.secondaryColor,
    textOverflow: "ellipsis",
    fontWeight: "bold",
    fontFamily: "Righteous",
  },
  description: {
    fontSize: 18,
    textAlign: "justify",
    wordWrap: "break-word",
    textOverflow: "ellipsis",
    width: "100%",
    fontFamily: "Poppins",
    fontWeight: "medium",
  },
  priceLinkWrapper: {
    width: "70%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-end",
    gap: 10,
  },
  withoutPrice: {
    marginTop: "10px",
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "flex-end",
  },
  priceandquantity: {
    fontSize: 12,
    fontWeight: "medium",
    lineHeight: 1.2,
  },
  priceQuantity: {
    fontSize: 12,
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    fontWeight: "semibold",
    flexDirection: "column",
    fontFamily: "Righteous",
  },
  price: {
    lineHeight: 1.2,
    fontSize: 14,
    fontWeight: "medium",
  },
  pdfLink: {
    margin: "0",
    textDecoration: "none",
    fontFamily: "Righteous",
  },
  viewmoreWrapper: {
    display: "flex",
    padding: "5px 25px",
    borderRadius: "10px",
    justifyContent: "center",
    alignContent: "flex-start",
    backgroundColor: "#0068ff",
  },
  viewMore: {
    fontSize: 10,
    textDecoration: "none",
    color: "white",
  },
});

const doubleProductStyles = StyleSheet.create({
  container: {
    maxHeight: "100%",
    height: "100%",
    width: "100%",
    marginHorizontal: "auto",
    padding: 10,
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    gap: 20,
  },
  row: {
    gap: 10,
    padding: 5,
    width: "100%",
    height: "100%",
    maxHeight: "45%",
    borderRadius: 5,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  detailRow: {
    width: "100%",
    height: "100%",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    gap: 10,
  },
  mainImageContainer: {
    width: "50%",
    height: "100%",
    alignItems: "center",
    justifyContent: "center",
    border: "2px solid black",
    borderTopRightRadius: "40px",
  },
  content: {
    paddingTop: 12,
    width: "50%",
    height: "100%",
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },
  image: {
    width: "80%",
    height: "80%",
    objectFit: "contain",
    borderRadius: 5,
  },
  details: {
    color: pdfStylesData.secondaryColor,
    width: "100%",
    height: "100%",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },
  heading: {
    fontSize: 22,
    fontWeight: "bold",
    textOverflow: "ellipsis",
    color: pdfStylesData.secondaryColor,
  },
  productName: {
    fontSize: 18,
    marginBottom: 8,
    textOverflow: "ellipsis",
    overflow: "hidden",
  },
  description: {
    width: "90%",
    fontSize: 14,
    textAlign: "left",
    wordWrap: "break-word",
    textOverflow: "ellipsis",
    fontFamily: "Poppins",
    fontWeight: "light",
  },
  price: {
    lineHeight: 1.2,
    fontSize: 12,
    fontWeight: "medium",
  },
  priceandquantity: {
    fontSize: 10,
    fontWeight: "medium",
  },
  priceLinkWrapper: {
    gap: "1rem",
    marginTop: 4,
    width: "85%",
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-end",
    justifyContent: "space-between",
  },
  priceQuantity: {
    fontSize: 10,
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    fontWeight: "semibold",
    flexDirection: "column",
    fontFamily: "Righteous",
  },
  viewmoreWrapper: {
    display: "flex",
    padding: "3px 20px",
    borderRadius: "10px",
    justifyContent: "center",
    alignContent: "flex-start",
    backgroundColor: "#0068ff",
  },
  viewMore: {
    fontSize: 10,
    textDecoration: "none",
    color: "white",
  },
  pdfLink: {
    margin: "0",
    textDecoration: "none",
    fontFamily: "Righteous",
  },
});

const threeProductStyles = StyleSheet.create({
  container: {
    maxHeight: "100%",
    height: "100%",
    width: "100%",
    marginHorizontal: "auto",
    padding: 10,
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    gap: 20,
    margin: "auto",
  },
  row: {
    gap: 10,
    padding: 5,
    width: "100%",
    height: "100%",
    maxHeight: "30%",
    borderRadius: 5,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  detailRow: {
    width: "100%",
    height: "100%",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    gap: 10,
  },
  mainImageContainer: {
    width: "50%",
    height: "100%",
    alignItems: "center",
    justifyContent: "center",
    border: "2px solid black",
    borderTopRightRadius: "40px",
  },
  content: {
    paddingTop: 12,
    width: "50%",
    height: "100%",
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },
  image: {
    width: "80%",
    height: "80%",
    objectFit: "contain",
    borderRadius: 5,
  },
  details: {
    color: pdfStylesData.secondaryColor,
    width: "100%",
    height: "100%",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },
  heading: {
    fontSize: 18,
    fontWeight: "bold",
    textOverflow: "ellipsis",
    color: pdfStylesData.secondaryColor,
  },
  productName: {
    fontSize: 18,
    marginBottom: 8,
    textOverflow: "ellipsis",
    overflow: "hidden",
  },
  description: {
    width: "90%",
    fontSize: 14,
    textAlign: "left",
    wordWrap: "break-word",
    textOverflow: "ellipsis",
    fontFamily: "Poppins",
    fontWeight: "light",
  },
  price: {
    lineHeight: 1.2,
    fontSize: 12,
    fontWeight: "medium",
  },
  priceandquantity: {
    fontSize: 10,
    fontWeight: "medium",
  },
  priceLinkWrapper: {
    gap: "1rem",
    marginTop: 4,
    width: "85%",
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-end",
    justifyContent: "space-between",
  },
  priceQuantity: {
    fontSize: 10,
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    fontWeight: "semibold",
    flexDirection: "column",
    fontFamily: "Righteous",
  },
  viewmoreWrapper: {
    display: "flex",
    padding: "3px 20px",
    borderRadius: "10px",
    justifyContent: "center",
    alignContent: "flex-start",
    backgroundColor: "#0068ff",
  },
  viewMore: {
    fontSize: 10,
    textDecoration: "none",
    color: "white",
  },
  pdfLink: {
    margin: "0",
    textDecoration: "none",
    fontFamily: "Righteous",
  },
});

const quatProductStyles = StyleSheet.create({
  container: {
    maxHeight: "100%",
    height: "100%",
    width: "100%",
    padding: 5,
    paddingHorizontal: 10,
    marginHorizontal: "auto",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    gap: 5,
    flexWrap: "wrap",
    margin: "auto",
  },
  card: {
    gap: 10,
    padding: 10,
    borderRadius: 5,
    width: "49%",
    height: "45%",
    maxWidth: "49%",
    maxHeight: "45%",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
  },
  detailRow: {
    flexDirection: "row",
    justifyContent: "center",
    gap: 10,
    maxWidth: "90%",
  },
  mainImageContainer: {
    gap: 5,
    height: "80%",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    border: "2px solid black",
    borderTopRightRadius: "38px",
  },
  content: {
    justifyContent: "center",
    alignItems: "center",
    width: "90%",
  },
  imageContainer: {
    width: "100%",
    maxHeight: "55%",
  },
  image: {
    margin: "auto",
    height: "85%",
    width: "auto",
    objectFit: "contain",
  },
  secondaryImgaeContainer: {
    width: "33%",
    height: "80%",
  },
  details: {
    color: pdfStylesData.secondaryColor,
    width: "80%",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
  heading: {
    fontSize: 16,
    fontWeight: "bold",
    color: pdfStylesData.secondaryColor,
  },
  productName: {
    fontSize: 14,
    marginBottom: 4,
    fontWeight: "medium",
    textOverflow: "ellipsis",
    overflow: "hidden",
  },
  description: {
    fontSize: 12,
    lineHeight: 1,
    textAlign: "left",
    wordWrap: "break-word",
    textOverflow: "ellipsis",
    fontFamily: "Poppins",
    fontWeight: "medium",
  },
  priceLinkWrapper: {
    gap: 10,
    marginTop: 4,
    width: "90%",
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-end",
    justifyContent: "space-between",
  },
  withoutPrice: {
    marginTop: 4,
    width: "80%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  priceQuantity: {
    fontSize: 10,
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    fontWeight: "semibold",
    flexDirection: "column",
    fontFamily: "Righteous",
  },
  viewmoreWrapper: {
    display: "flex",
    padding: "2px 20px",
    borderRadius: "10px",
    justifyContent: "center",
    alignContent: "flex-start",
    backgroundColor: "#0068ff",
  },
  pdfLink: {
    margin: "0",
    textDecoration: "none",
    fontFamily: "Righteous",
  },
  viewMore: {
    margin: "0",
    fontSize: 10,
    color: "white",
    fontWeight: "semibold",
    textDecoration: "none",
    fontFamily: "Righteous",
  },
  price: {
    lineHeight: 1.2,
  },
  priceandquantity: {
    fontSize: 10,
    fontWeight: "medium",
  },
});

const sixProductStyles = StyleSheet.create({
  container: {
    gap: 5,
    padding: 5,
    width: "100%",
    height: "100%",
    flexWrap: "wrap",
    maxHeight: "100%",
    flexDirection: "row",
    paddingHorizontal: 10,
    alignItems: "center",
    margin: "auto",
    justifyContent: "center",
  },
  card: {
    gap: 10,
    padding: 10,
    width: "49%",
    height: "32%",
    maxWidth: "49%",
    maxHeight: "32%",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
  },
  productName: {
    margin: 0,
    fontSize: 10,
    lineHeight: 1,
    overflow: "hidden",
    fontWeight: "medium",
    fontFamily: "Righteous",
    textOverflow: "ellipsis",
  },
  mainImageContainer: {
    gap: 5,
    width: "60%",
    height: "100%",
    alignItems: "center",
    justifyContent: "center",
    border: "2px solid black",
    borderTopRightRadius: "38px",
  },
  image: {
    width: "auto",
    height: "87%",
    margin: "auto",
  },
  details: {
    width: "100%",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    color: pdfStylesData.secondaryColor,
  },
  heading: {
    margin: 0,
    fontSize: 14,
    fontWeight: "bold",
    fontFamily: "Righteous",
    color: pdfStylesData.secondaryColor,
  },
  description: {
    margin: 0,
    fontSize: 10,
    textAlign: "left",
    wordWrap: "break-word",
    fontFamily: "Poppins",
    textOverflow: "ellipsis",
  },
  priceLinkWrapper: {
    marginTop: 4,
    gap: 10,
    width: "62%",
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-end",
    justifyContent: "space-between",
  },
  withoutPrice: {
    marginTop: 4,
    width: "60%",
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "flex-end",
  },
  priceQuantity: {
    fontSize: 8,
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    fontWeight: "semibold",
    flexDirection: "column",
    fontFamily: "Righteous",
  },
  viewmoreWrapper: {
    display: "flex",
    padding: "0 10px",
    borderRadius: "10px",
    justifyContent: "center",
    alignItems: "flex-start",
    backgroundColor: "#0068ff",
  },
  pdfLink: {
    margin: "0",
    textDecoration: "none",
    fontFamily: "Righteous",
  },
  viewMore: {
    fontSize: 8,
    color: "white",
    fontWeight: "semibold",
    textDecoration: "none",
    fontFamily: "Righteous",
  },
});

const sevenProductStyles = StyleSheet.create({
  container: {
    maxHeight: "100%",
    height: "100%",
    width: "100%",
    padding: 5,
    paddingHorizontal: 10,
    marginHorizontal: "auto",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    gap: 5,
    flexWrap: "wrap",
    margin: "auto",
  },
  card: {
    flexDirection: "column",
    maxWidth: "32%",
    width: "32%",
    height: "33%",
    maxHeight: "33%",
    justifyContent: "center",
    alignItems: "center",
    gap: 10,
  },

  priceLinkWrapper: {
    marginTop: 4,
    gap: 10,
    width: "62%",
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-end",
    justifyContent: "space-between",
  },
  withoutPrice: {
    marginTop: 4,
    width: "60%",
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "flex-end",
  },

  heading: {
    fontSize: 10,
    fontWeight: "bold",
    color: pdfStylesData.secondaryColor,
  },
  productName: {
    fontSize: 10,
    marginBottom: 4,
    fontWeight: "medium",
    textOverflow: "ellipsis",
    overflow: "hidden",
  },
  description: {
    fontSize: 8,
    textAlign: "left",
    wordWrap: "break-word",
    textOverflow: "ellipsis",
    lineHeight: 1,
  },
  viewMore: {
    fontSize: 10,
    textDecoration: "none",
    color: "blue",
    marginTop: 5,
  },
  price: {
    lineHeight: 1.2,
  },
  priceandquantity: {
    fontSize: 6,
    fontWeight: "medium",
  },
});

const twelveProductStyles = StyleSheet.create({
  container: {
    flexDirection: "row",
    flexWrap: "wrap",
    padding: 10,
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%",
    overflow: "hidden",
    margin: "auto",
  },
  card: {
    width: "30%",
    height: "22%",
    marginVertical: 10,
    padding: 4,
    alignItems: "center",
    justifyContent: "space-between",
    overflow: "hidden",
  },
  mainImageContainer: {
    width: "100%",
    height: "80%",
    marginBottom: 2,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 10,
    border: "1px solid black",
  },
  image: {
    width: "80%",
    height: "auto",
    objectFit: "contain",
  },
  buttonNameWrapper: {
    width: "100%",
    height: "20%",
    gap: 4,
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
  },
  heading: {
    fontSize: 10,
    fontWeight: "bold",
    fontFamily: "Righteous",
    textAlign: "center",
    margin: 0,
    color: "#333",
  },
  viewmoreWrapper: {
    padding: 2,
    paddingHorizontal: 5,
    borderRadius: 5,
    backgroundColor: "#0068ff",
  },
  pdfLink: {
    textDecoration: "none",
  },
  viewMore: {
    fontSize: 8,
    color: "white",
    fontWeight: "bold",
    textAlign: "center",
  },
});

const BrandingPage = () => (
  <Page size="A4" style={styles.page}>
    <View style={styles.fullPage}>
      <Image src={websiteBrandingCover} style={styles.fullImage} />
    </View>
  </Page>
);

const EndingPage = () => (
  <Page size="A4" style={styles.page}>
    <View style={styles.fullPage}>
      <Image src={websiteEndingCover} style={styles.fullImage} />
    </View>
  </Page>
);

const truncateText = (text: string | undefined, maxLength: number) => {
  if (!text) return "";
  // Load the HTML content using cheerio
  const $ = cheerio.load(text);

  // Check if the text contains HTML tags
  if ($("body").children().length > 0) {
    // Get the text content from the HTML
    const textContent = $("body").text();

    // Truncate the text content
    if (textContent.length > maxLength) {
      return textContent.slice(0, maxLength) + "...";
    }

    return textContent;
  }

  // If there are no HTML tags, simply truncate the text
  if (text.length > maxLength) {
    return text.slice(0, maxLength) + "...";
  }

  return text;
};

function getOptimizedImageURL(url: string | undefined, width: number) {
  if (!url) return DemoImageURL;
  // return 'https://ckktfjvgua.cloudimg.io/' + url.replace("https://", "") + '?width='+ width;
  return (
    url.replace("https://", "https://ckktfjvgua.cloudimg.io/") +
    `?width=${width}`
  );
}

const MyDoc = (data: { data: OrderDetail; seller: any }) => {
  const pagesNumber =
    data?.data?.order?.lines?.length > 12
      ? Math.ceil(data?.data?.order?.lines?.length / 12)
      : 1;

  const doubleProductView = () => (
    <View style={threeProductStyles.container}>
      {data?.data?.order?.lines.map((item: OrderLine, index: number) => (
        <View key={index} style={threeProductStyles.row}>
          <PDFLink
            style={threeProductStyles.mainImageContainer}
            src={`${window.location.origin}/product/${item.productVariant.product.slug}`}
          >
            <Image
              source={getOptimizedImageURL(item?.imageUrl, 800)}
              style={threeProductStyles.image}
            />
          </PDFLink>

          <View style={threeProductStyles.content}>
            <Text style={threeProductStyles.heading}>
              {truncateText(item?.productVariant?.name, 30)}
            </Text>
            <Text style={threeProductStyles.description}>
              {truncateText(item?.productVariant?.product?.description, 35)}
            </Text>
            <View style={threeProductStyles.priceLinkWrapper}>
              <View style={singleProductStyles.priceandquantity}>
                <Text style={singleProductStyles.priceQuantity}>
                  {`${item.quantity} X ${formatCurrencyINR(
                    item.unitPriceWithTax
                  )}`}
                </Text>
                <Text style={singleProductStyles.price}>
                  Rs.{formatCurrencyINR(item?.linePriceWithTax)}
                </Text>
              </View>
              <View style={singleProductStyles.viewmoreWrapper}>
                <PDFLink
                  style={singleProductStyles.pdfLink}
                  src={`${window.location.origin}/product/${item.productVariant.product.slug}`}
                >
                  <Text style={singleProductStyles.viewMore}>View More...</Text>
                </PDFLink>
              </View>
            </View>
          </View>
        </View>
      ))}
    </View>
  );

  const quadProductView = () => (
    <View style={quatProductStyles.container}>
      {data?.data?.order?.lines.map((item: OrderLine, index: number) => (
        <View key={index} style={quatProductStyles.card}>
          <PDFLink
            style={quatProductStyles.mainImageContainer}
            src={`${window.location.origin}/product/${item.productVariant.product.slug}`}
          >
            <Image
              src={
                item?.imageUrl
                  ? `https://ckktfjvgua.cloudimg.io/${item.imageUrl.replace(
                      "https://",
                      ""
                    )}?width=800`
                  : DemoImageURL
              }
              style={quatProductStyles.image}
            />
          </PDFLink>
          <Text style={quatProductStyles.heading}>
            {truncateText(item?.productVariant?.name, 30)}
          </Text>
          <Text style={quatProductStyles.description}>
            {truncateText(item?.productVariant?.product?.description, 35)}
          </Text>
          <View style={threeProductStyles.priceLinkWrapper}>
            <View style={singleProductStyles.priceandquantity}>
              <Text style={singleProductStyles.priceQuantity}>
                {`${item.quantity} X ${formatCurrencyINR(
                  item.unitPriceWithTax
                )}`}
              </Text>
              <Text style={singleProductStyles.price}>
                Rs.{formatCurrencyINR(item?.linePriceWithTax)}
              </Text>
            </View>
            <View style={singleProductStyles.viewmoreWrapper}>
              <PDFLink
                style={singleProductStyles.pdfLink}
                src={`${window.location.origin}/product/${item.productVariant.product.slug}`}
              >
                <Text style={singleProductStyles.viewMore}>View More...</Text>
              </PDFLink>
            </View>
          </View>
        </View>
      ))}
    </View>
  );

  const fiveProductView = () => (
    <View style={quatProductStyles.container}>
      {data?.data?.order?.lines.map((item: OrderLine, index: number) => (
        <View key={index} style={sixProductStyles.card}>
          {item.productVariant ? (
            <>
              <PDFLink
                style={sixProductStyles.mainImageContainer}
                src={`${window.location.origin}/product/${item.productVariant.product.slug}`}
              >
                <Image
                  src={item?.imageUrl || DemoImageURL}
                  style={sixProductStyles.image}
                />
              </PDFLink>
              <Text style={sixProductStyles.heading}>
                {truncateText(item?.productVariant?.name, 20)}
              </Text>
              <Text style={sixProductStyles.description}>
                {truncateText(item?.productVariant?.product?.description, 25)}
              </Text>
              <View style={sixProductStyles.priceLinkWrapper}>
                <View style={sixProductStyles.priceQuantity}>
                  <Text>
                    {`${item.quantity} X ${formatCurrencyINR(
                      item.unitPriceWithTax
                    )}`}
                  </Text>
                  <Text>Rs.{formatCurrencyINR(item?.linePriceWithTax)}</Text>
                </View>
                <View style={sixProductStyles.viewmoreWrapper}>
                  <PDFLink
                    style={sixProductStyles.pdfLink}
                    src={`${window.location.origin}/product/${item.productVariant.product.slug}`}
                  >
                    <Text style={sixProductStyles.viewMore}>View More...</Text>
                  </PDFLink>
                </View>
              </View>
            </>
          ) : null}
        </View>
      ))}
    </View>
  );

  const sevenProductView = (index: number) => {
    const startIndex = index * 12;
    const endIndex = startIndex + 12;
    const lines = data?.data?.order?.lines?.slice(startIndex, endIndex); // Access the correct path for data

    return (
      <View style={twelveProductStyles.container}>
        {lines?.map(
          (
            item: OrderLine,
            idx: number // Use 'idx' for the key
          ) => (
            <View key={idx} style={twelveProductStyles.card}>
              <PDFLink
                style={twelveProductStyles.mainImageContainer}
                src={`${window.location.origin}/product/${item.productVariant.product.slug}`}
              >
                <Image
                  src={
                    item?.imageUrl
                      ? `https://ckktfjvgua.cloudimg.io/${item.imageUrl.replace(
                          "https://",
                          ""
                        )}?width=800`
                      : DemoImageURL
                  }
                  style={twelveProductStyles.image}
                />
              </PDFLink>
              <View style={twelveProductStyles.buttonNameWrapper}>
                <Text style={twelveProductStyles.heading}>
                  {truncateText(item?.productVariant?.name, 25)}
                </Text>
                <View style={twelveProductStyles.viewmoreWrapper}>
                  <PDFLink
                    style={twelveProductStyles.pdfLink}
                    src={`${window.location.origin}/product/${item.productVariant.product.slug}`}
                  >
                    <Text style={twelveProductStyles.viewMore}>
                      View More...
                    </Text>
                  </PDFLink>
                </View>
              </View>
            </View>
          )
        )}
      </View>
    );
  };

  const twelveProductView = (index: number) => {
    const startIndex = index * 12;
    const endIndex = startIndex + 12;
    const lines = data?.data?.order?.lines?.slice(startIndex, endIndex);

    return (
      <View style={twelveProductStyles.container}>
        {lines.map((item: OrderLine, index: number) => (
          <View style={twelveProductStyles.card}>
            <PDFLink
              style={twelveProductStyles.mainImageContainer}
              src={`${window.location.origin}/product/${item.productVariant.product.slug}`}
            >
              <Image
                src={
                  item?.imageUrl
                    ? `https://ckktfjvgua.cloudimg.io/${item.imageUrl.replace(
                        "https://",
                        ""
                      )}?width=800`
                    : DemoImageURL
                }
                style={twelveProductStyles.image}
              />
            </PDFLink>
            <View style={twelveProductStyles.buttonNameWrapper}>
              <Text style={twelveProductStyles.heading}>
                {truncateText(item?.productVariant?.name, 14)}
              </Text>
              <View style={twelveProductStyles.viewmoreWrapper}>
                <PDFLink
                  style={twelveProductStyles.pdfLink}
                  src={`${window.location.origin}/product/${item.productVariant.product.slug}`}
                >
                  <Text style={twelveProductStyles.viewMore}>View More...</Text>
                </PDFLink>
              </View>
            </View>
          </View>
        ))}
      </View>
    );
  };

  const productView = (index: number) => {
    // if (data?.data?.order?.lines?.length === 1) return singleProductView();
    if (data?.data?.order?.lines?.length === 2) return doubleProductView();
    if (data?.data?.order?.lines?.length === 3) return doubleProductView();
    if (data?.data?.order?.lines?.length === 4) return quadProductView();
    if (data?.data?.order?.lines?.length === 5) return fiveProductView();
    if (data?.data?.order?.lines?.length === 6) return fiveProductView();
    if (data?.data?.order?.lines?.length > 6) return sevenProductView(index);
  };

  return (
    <Document>
      <BrandingPage />

      {Array.from(Array(pagesNumber).keys()).map((item, index) => (
        <Page size="A4">
          {/* <View style={styles.catalogLine}></View> */}
          {/* <View style={styles.catalogContainer}>
            <Text style={styles.catalogText}>
              {data.data.order.customFields?.kitName
                ? data.data.order.customFields?.kitName
                : "Catalog"}
            </Text>
          </View> */}
          <Image src={diwaliHeader} style={styles.header} />
          <View style={{ ...styles.container }}>{productView(index)}</View>
          <Image src={diwaliFooter} style={styles.header} />

          {/* <View
            style={{ ...styles.catalogLine, height: 2, marginTop: 5 }}
          ></View> */}
          {/* <View style={styles.footer}>
            <View style={styles.companyLogo}>
              <Image
                src={"/logo192.png"}
                style={{ width: "100%", height: "100%", objectFit: "contain" }}
              />
            </View> */}

          {/* <View style={styles.contactInfo}>
              <Text style={styles.phoneNumber}>
                {data?.seller?.mobile && `+91${data?.seller?.mobile}`}
              </Text>
              <View style={{ flexDirection: "row", gap: 3 }}>
                <PDFLink
                  style={styles.website}
                  src="https://360customizer.com/"
                >
                  <Text>https://360customizer.com/</Text>
                </PDFLink>
              </View>
            </View> */}
          {/* <View style={styles.totalBox}>
              <View>
                <Text style={styles.totalText}>
                  Total items: {data?.data?.order?.lines?.length}
                </Text>
                <Text style={styles.totalAmount}>
                  {formatCurrencyINR(data?.data?.order?.totalWithTax || 0)}
                </Text>
              </View>
            </View> */}
          {/* </View> */}
        </Page>
      ))}

      <EndingPage />
    </Document>
  );
};

export const CheckOutPdf = (data: {
  data: MyCartQueryResponse;
  seller: any;
  kitname: string;
  downloadWithoutPrice: boolean;
}) => {
  if (!data?.data?.activeOrder?.lines) return null;
  const pagesNumber =
    data?.data?.activeOrder?.lines?.length > 12
      ? Math.ceil(data?.data?.activeOrder?.lines?.length / 12)
      : 1;

  const singleProductView = () => (
    <View style={singleProductStyles.container}>
      {data?.data?.activeOrder?.lines
        .slice(0, 1)
        .map((item: CartLine, index: number) => (
          <View key={index} style={singleProductStyles.card}>
            <PDFLink
              style={singleProductStyles.mainImageContainer}
              src={`${window.location.origin}/product/${item.productVariant.product.slug}`}
            >
              <Image
                source={getOptimizedImageURL(item?.imageUrl, 800)}
                style={singleProductStyles.image}
              />
            </PDFLink>

            <View style={singleProductStyles.content}>
              <Text style={singleProductStyles.heading}>
                {truncateText(item?.productVariant?.name, 35)}
              </Text>
              <Text style={singleProductStyles.description}>
                {truncateText(item?.productVariant?.product?.description, 45)}
              </Text>
              <View
                style={
                  !data.downloadWithoutPrice
                    ? singleProductStyles.priceLinkWrapper
                    : singleProductStyles.withoutPrice
                }
              >
                {!data.downloadWithoutPrice && (
                  <View style={singleProductStyles.priceandquantity}>
                    <Text style={singleProductStyles.priceQuantity}>
                      {`${item.quantity} X ${formatCurrencyINR(
                        item.unitPriceWithTax
                      )}`}
                    </Text>
                    <Text style={singleProductStyles.price}>
                      Rs.{formatCurrencyINR(item?.linePriceWithTax)}
                    </Text>
                  </View>
                )}
                <View style={singleProductStyles.viewmoreWrapper}>
                  <PDFLink
                    style={singleProductStyles.pdfLink}
                    src={`${window.location.origin}/product/${item.productVariant.product.slug}`}
                  >
                    <Text style={singleProductStyles.viewMore}>
                      View More...
                    </Text>
                  </PDFLink>
                </View>
              </View>
            </View>
          </View>
        ))}
    </View>
  );

  const doubleProductView = () => (
    <View style={doubleProductStyles.container}>
      {data?.data?.activeOrder?.lines
        .slice(0, 2)
        .map((item: CartLine, index: number) => (
          <View key={index} style={doubleProductStyles.row}>
            <PDFLink
              style={doubleProductStyles.mainImageContainer}
              src={`${window.location.origin}/product/${item.productVariant.product.slug}`}
            >
              <Image
                source={getOptimizedImageURL(item?.imageUrl, 800)}
                style={doubleProductStyles.image}
              />
            </PDFLink>

            <View style={doubleProductStyles.content}>
              <Text style={doubleProductStyles.heading}>
                {truncateText(item?.productVariant?.name, 30)}
              </Text>
              <Text style={doubleProductStyles.description}>
                {truncateText(item?.productVariant?.product?.description, 35)}
              </Text>
              <View
                style={
                  !data.downloadWithoutPrice
                    ? doubleProductStyles.priceLinkWrapper
                    : singleProductStyles.withoutPrice
                }
              >
                {!data.downloadWithoutPrice && (
                  <View style={singleProductStyles.priceandquantity}>
                    <Text style={singleProductStyles.priceQuantity}>
                      {`${item.quantity} X ${formatCurrencyINR(
                        item.unitPriceWithTax
                      )}`}
                    </Text>
                    <Text style={singleProductStyles.price}>
                      Rs.{formatCurrencyINR(item?.linePriceWithTax)}
                    </Text>
                  </View>
                )}
                <View style={singleProductStyles.viewmoreWrapper}>
                  <PDFLink
                    style={singleProductStyles.pdfLink}
                    src={`${window.location.origin}/product/${item.productVariant.product.slug}`}
                  >
                    <Text style={singleProductStyles.viewMore}>
                      View More...
                    </Text>
                  </PDFLink>
                </View>
              </View>
            </View>
          </View>
        ))}
    </View>
  );

  const threeProductView = () => (
    <View style={threeProductStyles.container}>
      {data?.data?.activeOrder?.lines
        .slice(0, 3)
        .map((item: CartLine, index: number) => (
          <View key={index} style={threeProductStyles.row}>
            <PDFLink
              style={threeProductStyles.mainImageContainer}
              src={`${window.location.origin}/product/${item.productVariant.product.slug}`}
            >
              <Image
                source={getOptimizedImageURL(item?.imageUrl, 800)}
                style={threeProductStyles.image}
              />
            </PDFLink>

            <View style={threeProductStyles.content}>
              <Text style={threeProductStyles.heading}>
                {truncateText(item?.productVariant?.name, 30)}
              </Text>
              <Text style={threeProductStyles.description}>
                {truncateText(item?.productVariant?.product?.description, 35)}
              </Text>
              <View
                style={
                  !data.downloadWithoutPrice
                    ? threeProductStyles.priceLinkWrapper
                    : singleProductStyles.withoutPrice
                }
              >
                {!data.downloadWithoutPrice && (
                  <View style={singleProductStyles.priceandquantity}>
                    <Text style={singleProductStyles.priceQuantity}>
                      {`${item.quantity} X ${formatCurrencyINR(
                        item.unitPriceWithTax
                      )}`}
                    </Text>
                    <Text style={singleProductStyles.price}>
                      Rs.{formatCurrencyINR(item?.linePriceWithTax)}
                    </Text>
                  </View>
                )}
                <View style={singleProductStyles.viewmoreWrapper}>
                  <PDFLink
                    style={singleProductStyles.pdfLink}
                    src={`${window.location.origin}/product/${item.productVariant.product.slug}`}
                  >
                    <Text style={singleProductStyles.viewMore}>
                      View More...
                    </Text>
                  </PDFLink>
                </View>
              </View>
            </View>
          </View>
        ))}
    </View>
  );

  const quadProductView = () => (
    <View style={quatProductStyles.container}>
      {data?.data?.activeOrder?.lines
        .slice(0, 4)
        .map((item: CartLine, index: number) => (
          <View key={index} style={quatProductStyles.card}>
            <PDFLink
              style={quatProductStyles.mainImageContainer}
              src={`${window.location.origin}/product/${item.productVariant.product.slug}`}
            >
              <Image
                src={
                  item?.imageUrl
                    ? `https://ckktfjvgua.cloudimg.io/${item.imageUrl.replace(
                        "https://",
                        ""
                      )}?width=800`
                    : DemoImageURL
                }
                style={quatProductStyles.image}
              />
            </PDFLink>
            <Text style={quatProductStyles.heading}>
              {truncateText(item?.productVariant?.name, 30)}
            </Text>
            <Text style={quatProductStyles.description}>
              {truncateText(item?.productVariant?.product?.description, 35)}
            </Text>
            <View
              style={
                !data.downloadWithoutPrice
                  ? threeProductStyles.priceLinkWrapper
                  : quatProductStyles.withoutPrice
              }
            >
              {!data.downloadWithoutPrice && (
                <View style={singleProductStyles.priceandquantity}>
                  <Text style={singleProductStyles.priceQuantity}>
                    {`${item.quantity} X ${formatCurrencyINR(
                      item.unitPriceWithTax
                    )}`}
                  </Text>
                  <Text style={singleProductStyles.price}>
                    Rs.{formatCurrencyINR(item?.linePriceWithTax)}
                  </Text>
                </View>
              )}
              <View style={singleProductStyles.viewmoreWrapper}>
                <PDFLink
                  style={singleProductStyles.pdfLink}
                  src={`${window.location.origin}/product/${item.productVariant.product.slug}`}
                >
                  <Text style={singleProductStyles.viewMore}>View More...</Text>
                </PDFLink>
              </View>
            </View>
          </View>
        ))}
    </View>
  );

  const fiveProductView = (index: number) => {
    const startIndex = index * 5;
    const endIndex = startIndex + 5;
    const lines = data?.data?.activeOrder?.lines?.slice(startIndex, endIndex);

    return (
      <View style={sixProductStyles.container}>
        {lines?.map((item: CartLine, idx: number) => (
          <View key={idx} style={sixProductStyles.card}>
            {item.productVariant ? (
              <>
                <PDFLink
                  style={sixProductStyles.mainImageContainer}
                  src={`${window.location.origin}/product/${item.productVariant.product.slug}`}
                >
                  <Image
                    src={item?.imageUrl || DemoImageURL}
                    style={sixProductStyles.image}
                  />
                </PDFLink>
                <Text style={sixProductStyles.heading}>
                  {truncateText(item?.productVariant?.name, 20)}
                </Text>
                <Text style={sixProductStyles.description}>
                  {truncateText(item?.productVariant?.product?.description, 25)}
                </Text>
                <View
                  style={
                    !data.downloadWithoutPrice
                      ? sixProductStyles.priceLinkWrapper
                      : sixProductStyles.withoutPrice
                  }
                >
                  {!data.downloadWithoutPrice && (
                    <View style={sixProductStyles.priceQuantity}>
                      <Text>
                        {`${item.quantity} X ${formatCurrencyINR(
                          item.unitPriceWithTax
                        )}`}
                      </Text>
                      <Text>
                        Rs.{formatCurrencyINR(item?.linePriceWithTax)}
                      </Text>
                    </View>
                  )}
                  <View style={sixProductStyles.viewmoreWrapper}>
                    <PDFLink
                      style={sixProductStyles.pdfLink}
                      src={`${window.location.origin}/product/${item.productVariant.product.slug}`}
                    >
                      <Text style={sixProductStyles.viewMore}>
                        View More...
                      </Text>
                    </PDFLink>
                  </View>
                </View>
              </>
            ) : null}
          </View>
        ))}
        {lines &&
          lines.length < 5 &&
          Array.from({ length: 5 - lines.length }).map((_, idx) => (
            <View key={`empty-${idx}`} style={sixProductStyles.card}></View>
          ))}
      </View>
    );
  };

  const sixProductView = (index: number) => {
    const startIndex = index * 6;
    const endIndex = startIndex + 6;
    const lines = data?.data?.activeOrder?.lines?.slice(startIndex, endIndex);

    return (
      <View style={sixProductStyles.container}>
        {lines?.map((item: CartLine, idx: number) => (
          <View key={idx} style={sixProductStyles.card}>
            <PDFLink
              style={sixProductStyles.mainImageContainer}
              src={`${window.location.origin}/product/${item.productVariant.product.slug}`}
            >
              <Image
                src={item?.imageUrl || DemoImageURL}
                style={sixProductStyles.image}
              />
            </PDFLink>
            <Text style={sixProductStyles.heading}>
              {truncateText(item?.productVariant?.name, 20)}
            </Text>
            <Text style={sixProductStyles.description}>
              {truncateText(item?.productVariant?.product?.description, 25)}
            </Text>
            <View
              style={
                !data.downloadWithoutPrice
                  ? sixProductStyles.priceLinkWrapper
                  : sixProductStyles.withoutPrice
              }
            >
              {!data.downloadWithoutPrice && (
                <View style={sixProductStyles.priceQuantity}>
                  <Text>
                    {`${item.quantity} X ${formatCurrencyINR(
                      item.unitPriceWithTax
                    )}`}
                  </Text>
                  <Text>Rs.{formatCurrencyINR(item?.linePriceWithTax)}</Text>
                </View>
              )}
              <View style={sixProductStyles.viewmoreWrapper}>
                <PDFLink
                  style={sixProductStyles.pdfLink}
                  src={`${window.location.origin}/product/${item.productVariant.product.slug}`}
                >
                  <Text style={sixProductStyles.viewMore}>View More...</Text>
                </PDFLink>
              </View>
            </View>
          </View>
        ))}
      </View>
    );
  };

  const sevenProductView = (index: number) => {
    const startIndex = index * 6;
    const endIndex = startIndex + 6;
    const lines = data?.data?.activeOrder?.lines?.slice(startIndex, endIndex);

    return (
      <View style={sixProductStyles.container}>
        {lines?.map((item: CartLine, idx: number) => (
          <View key={idx} style={sixProductStyles.card}>
            <PDFLink
              style={sixProductStyles.mainImageContainer}
              src={`${window.location.origin}/product/${item.productVariant.product.slug}`}
            >
              <Image
                src={
                  item?.imageUrl
                    ? `https://ckktfjvgua.cloudimg.io/${item.imageUrl.replace(
                        "https://",
                        ""
                      )}?width=800`
                    : DemoImageURL
                }
                style={sixProductStyles.image}
              />
            </PDFLink>
            <Text style={sixProductStyles.heading}>
              {truncateText(item?.productVariant?.name, 20)}
            </Text>
            <Text style={sixProductStyles.description}>
              {truncateText(item?.productVariant?.product?.description, 25)}
            </Text>
            <View
              style={
                !data.downloadWithoutPrice
                  ? sixProductStyles.priceLinkWrapper
                  : sixProductStyles.withoutPrice
              }
            >
              {!data.downloadWithoutPrice && (
                <View style={sixProductStyles.priceQuantity}>
                  <Text>
                    {`${item.quantity} X ${formatCurrencyINR(
                      item.unitPriceWithTax
                    )}`}
                  </Text>
                  <Text>Rs.{formatCurrencyINR(item?.linePriceWithTax)}</Text>
                </View>
              )}
              <View style={sixProductStyles.viewmoreWrapper}>
                <PDFLink
                  style={sixProductStyles.pdfLink}
                  src={`${window.location.origin}/product/${item.productVariant.product.slug}`}
                >
                  <Text style={sixProductStyles.viewMore}>View More...</Text>
                </PDFLink>
              </View>
            </View>
          </View>
        ))}
      </View>
    );
  };

  const twelveProductView = (index: number) => {
    const startIndex = index * 12;
    const endIndex = startIndex + 12;
    const lines = data?.data?.activeOrder?.lines?.slice(startIndex, endIndex);

    return (
      <View style={twelveProductStyles.container}>
        {lines?.map((item: CartLine, idx: number) => (
          <View key={idx} style={twelveProductStyles.card}>
            <PDFLink
              style={twelveProductStyles.mainImageContainer}
              src={`${window.location.origin}/product/${item.productVariant.product.slug}`}
            >
              <Image
                src={
                  item?.imageUrl
                    ? `https://ckktfjvgua.cloudimg.io/${item.imageUrl.replace(
                        "https://",
                        ""
                      )}?width=800`
                    : DemoImageURL
                }
                style={twelveProductStyles.image}
              />
            </PDFLink>
            <View style={twelveProductStyles.buttonNameWrapper}>
              <Text style={twelveProductStyles.heading}>
                {truncateText(item?.productVariant?.name, 25)}
              </Text>
              <View style={twelveProductStyles.viewmoreWrapper}>
                <PDFLink
                  style={twelveProductStyles.pdfLink}
                  src={`${window.location.origin}/product/${item.productVariant.product.slug}`}
                >
                  <Text style={twelveProductStyles.viewMore}>View More...</Text>
                </PDFLink>
              </View>
            </View>
          </View>
        ))}
      </View>
    );
  };

  const productView = (index: number) => {
    if (data?.data?.activeOrder?.lines?.length === 1)
      return singleProductView();
    if (data?.data?.activeOrder?.lines?.length === 2)
      return doubleProductView();
    if (data?.data?.activeOrder?.lines?.length === 3) return threeProductView();
    if (data?.data?.activeOrder?.lines?.length === 4) return quadProductView();
    if (data?.data?.activeOrder?.lines?.length === 5)
      return fiveProductView(index);
    if (data?.data?.activeOrder?.lines?.length === 6)
      return sixProductView(index);
    if (
      data?.data?.activeOrder?.lines &&
      data?.data?.activeOrder?.lines?.length > 6
    )
      return twelveProductView(index);
  };

  return (
    <Document>
      {/* Branding Page : 1st Page */}
      <BrandingPage />

      {Array.from(Array(pagesNumber).keys()).map((_, index) => (
        <Page size="A4">
          {/* <View style={styles.catalogLine}></View> */}
          {/* <View style={styles.catalogContainer}> */}
          {/* <Text style={styles.catalogText}>{data.kitname}</Text> */}
          <Image src={diwaliHeader} style={styles.header} />
          {/* </View> */}

          <View style={{ ...styles.container }}>{productView(index)}</View>

          {/* <View style={styles.footer}> */}
          {/* <View style={styles.companyLogoWrapper}> */}
          <Image src={diwaliFooter} style={styles.header} />
          {/* </View> */}

          {/* <PDFLink
              style={styles.websiteLink}
              src={"https://www.360customizer.com/"}
            >
              <Text style={styles.website}>www.360customizer.com</Text>
            </PDFLink>

            <View style={styles.totalBox}>
              {!data.downloadWithoutPrice && (
                <Text style={styles.totalText}>
                  Total items: {data?.data?.activeOrder?.lines?.length}
                </Text>
              )}
              {!data.downloadWithoutPrice && (
                <Text style={styles.totalAmount}>
                  {formatCurrencyINR(
                    data?.data?.activeOrder?.totalWithTax || 0
                  )}
                </Text>
              )}
            </View> */}
          {/* </View> */}
        </Page>
      ))}
      <EndingPage />
    </Document>
  );
};

export default MyDoc;
