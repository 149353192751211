import React, { FC, useEffect, useState, useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import bottles from "../../images/SectionStartCreatingYourPack/bottles.svg";
import tshirt from "../../images/SectionStartCreatingYourPack/tshirt.jpg";
import techGadgets from "../../images/SectionStartCreatingYourPack/techGadgets.jpg";
import bags from "../../images/SectionStartCreatingYourPack/bag.jpg";
import stationary from "../../images/SectionStartCreatingYourPack/stationary.jpg";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import backgroundEllipses from "../../images/hero/backgroundEllipses.png";

interface Product {
  id: number;
  name: string;
  imageUrl: any;
  info: string;
  cost: string;
  link: string;
}

interface SectionStartCreatingYourPackProps {
  heading?: string;
  subheading?: string;
}

const SectionStartCreatingYourPack: FC<SectionStartCreatingYourPackProps> = ({
  heading,
  subheading,
}) => {
  const [products, setProducts] = useState<Product[]>([]);
  const slider = useRef<Slider>(null);

  useEffect(() => {
    const fetchData = async () => {
      const data = await fetchProducts();
      setProducts(data);
    };

    fetchData();
  }, []);

  const fetchProducts = async () => {
    return new Promise<Product[]>((resolve) => {
      setTimeout(() => {
        resolve([
          {
            id: 1,
            name: "Drinkware",
            imageUrl: bottles,
            info: "Drinkware",
            cost: "₹2400",
            link: "/page-collection?filters=9",
          },
          {
            id: 2,
            name: "Clothing",
            imageUrl: tshirt,
            info: "Clothing",
            cost: "₹2400",
            link: "/page-collection?filters=1",
          },
          {
            id: 3,
            name: "Tech gadgets",
            imageUrl: techGadgets,
            info: "Tech gadgets",
            cost: "₹2400",
            link: "/page-collection?filters=47",
          },
          {
            id: 4,
            name: "bags",
            imageUrl: bags,
            info: "Bags",
            cost: "₹2400",
            link: "/page-collection?filters=26",
          },
          {
            id: 5,
            name: "Stationary",
            imageUrl: stationary,
            info: "Stationary",
            cost: "₹2400",
            link: "/page-collection?filters=13",
          },
        ]);
      }, 1000);
    });
  };

  const settings = {
    infinite: true,
    speed: 300,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 540,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const goToNext = () => {
    console.log("GO NEXT...");
    if (slider.current) {
      slider.current.slickNext();
    }
  };

  const goToPrev = () => {
    if (slider.current) {
      slider.current.slickPrev();
    }
  };

  return (
    <div
      className="nc-SectionStartCreatingYourPack py-10 bg-[#0068FF] w-full min-h-min h-auto flex flex-col lg:flex-row"
      style={{
        backgroundImage: `url(${backgroundEllipses})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
      }}
    >
      {/* Left Section */}
      <div className="left-section text-white lg:w-1/3">
        <div className="px-2 md:px-4 ml-0 md:ml-8 py-6 lg:py-12 lg:px-8">
          <h1 className="text-xl md:text-5xl font-righteous font-medium tracking-wide">
            {heading || "Start Creating Your Packs"}
          </h1>
          <h3 className="text-base lg:text-lg mt-2 lg:mt-4 font-inter">
            Elevate Your Gifting Game
          </h3>
          <div className="flex mt-4 lg:mt-6">
            <span
              className="cursor-pointer text-[#0068FF] text-5xl h-14 w-14 mr-4 bg-white rounded-full flex items-center justify-center"
              onClick={goToPrev}
            >
              {"<"}
            </span>
            <span
              className="cursor-pointer text-[#0068FF] text-5xl h-14 w-14 bg-white rounded-full flex items-center justify-center"
              onClick={goToNext}
            >
              {">"}
            </span>
          </div>
        </div>
      </div>

      {/* Right Section */}
      <div className="right-section w-full lg:w-2/3 overflow-hidden lg:py-4">
        <Slider {...settings} ref={slider}>
          {products.map((item, index) => (
            <div
              key={item.id}
              className="bg-[#0058D5] w-48 rounded-lg p-2 mx-0 md:m-2 lg:p-3 md:w-48 lg:w-[100%]"
            >
              <img
                src={item.imageUrl}
                alt={item.name}
                className="w-full h-56 lg:h-80 object-cover mb-2 rounded-lg"
              />
              <p className="text-white text-sm lg:text-xl mb-2">{item.info}</p>
              <ButtonPrimary
                href={item.link as any}
                className="w-full bg-[#FF8A48] rounded-md px-2 lg:px-4 py-1 lg:py-2 text-white text-sm lg:text-base mb-2 lg:mb-0"
              >
                View All
              </ButtonPrimary>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default SectionStartCreatingYourPack;
