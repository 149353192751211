import React from "react";
import { Helmet } from "react-helmet-async";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import SectionHero from "./SectionHero";
import SectionOurTeam from "./SectionOurTeam";
import AboutApplyNow from "./AboutApplyNow";
import SectionContent from "./SectionContent";
import AboutSectionHowItWorks from "./AboutSectionHowItWorks";
import SectionAboutOurStory from "./SectionAboutOurStory";
import heroImage from "images/about/about section.png";

export interface PageAboutProps {
  className?: string;
}

const PageAbout: React.FC<PageAboutProps> = ({ className = "" }) => {
  return (
    <div
      className={`nc-PageAbout overflow-hidden relative ${className}`}
      data-nc-id="PageAbout"
    >
      <Helmet>
        <title>About || 360CUSTOMIZER</title>
      </Helmet>

      <BgGlassmorphism />

      <div className="container py-6 lg:pb-28 space-y-6">
        <SectionHero rightImg={heroImage} />
        <SectionContent />
        <AboutSectionHowItWorks />
        <SectionAboutOurStory />
        <SectionOurTeam />
        <AboutApplyNow />
      </div>
    </div>
  );
};

export default PageAbout;
