import { useQuery } from "@apollo/client";
import { myProfile } from "graphql/queries/queries";
import useCurrentUser from "hooks/useCurrentUser";
import React, { useEffect } from "react";
import { FC } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import ButtonPrimary from "shared/Button/ButtonPrimary";

export interface CommonLayoutProps {
  children?: React.ReactNode;
}

const CommonLayout: FC<CommonLayoutProps> = ({ children }) => {
  const getUser = useCurrentUser((state)=>{return{user:state.user,getUser:state.getUser, isLoggedIn:state.isUserLoggedIn,loading:state.isLoading}});
  const navigate = useNavigate();

  const {data, loading, error, refetch} = useQuery(myProfile,{fetchPolicy:"no-cache"})

  // console.log(getUser,"getUser------------------")

  useEffect(()=>{
    getUser.getUser()
    refetch()
  },[])


  if (error) {
    return (
      <div className="flex flex-col justify-center items-center h-screen">
        <h1 className="text-2xl font-semibold text-center my-3">Something went wrong!</h1>
        <ButtonPrimary onClick={()=>{window.location.reload()}}>Reload</ButtonPrimary>
      </div>
    )
  }

  if (loading) return (
    <div className="flex justify-center items-center h-screen">
      <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-primary-500"></div>
    </div>
  )

  if(!loading && data?.activeCustomer === null){
    navigate("/login")
  }

 






  
  return (
    <div className="nc-CommonLayoutProps container">
      <div className="mt-14 sm:mt-20">
        <div className="max-w-4xl mx-auto">
          <div className="max-w-2xl">
            <h2 className="text-3xl xl:text-4xl font-semibold">Account</h2>
            <span className="block mt-4 text-neutral-500 dark:text-neutral-400 text-base sm:text-lg">
              <span className="text-slate-900 dark:text-slate-200 font-semibold">
               {getUser.user?.firstName} {getUser.user?.lastName}
              </span>{" "}
              {getUser.user?.emailAddress}
            </span>
          </div>
          <hr className="mt-10 border-slate-200 dark:border-slate-700"></hr>

          <div className="flex space-x-8 md:space-x-14 overflow-x-auto hiddenScrollbar">
            {[
              {
                name: " My Catalog",
                link: "/account-my-order",
              },
              {
                name: "Account info",
                link: "/account",
              },
              {
                name: "Change password",
                link: "/account-change-password",
              },
            
            ].map((item, index) => (
              <NavLink
                key={index}
                to={item.link}
                className={({ isActive }) =>
                  `block py-5 md:py-8 border-b-2 border-transparent flex-shrink-0  text-sm sm:text-base ${
                    isActive
                      ? "border-primary-500 font-medium text-slate-900 dark:text-slate-200"
                      : "text-slate-500 dark:text-slate-400 hover:text-slate-800 dark:hover:text-slate-200"
                  }`
                }
              >
                {item.name}
              </NavLink>
            ))}
          </div>
          <hr className="border-slate-200 dark:border-slate-700"></hr>
        </div>
      </div>
      <div className="max-w-4xl mx-auto pt-14 sm:pt-26 pb-24 lg:pb-32">
        {children}
      </div>
    </div>
  );
};

export default CommonLayout;
