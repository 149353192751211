import Checkbox from "shared/Checkbox/Checkbox";
import { FacetValue, FacetsData, FacetItem } from "graphql/queries/types";
import React, { useEffect, useRef, useState } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import NcModal from "shared/NcModal/NcModal";
import Input from "shared/Input/Input";
import ModalKitInput from "shared/ModalKitInput/ModalKitInput";
import { useSearchParams } from "react-router-dom";
import Select from "shared/Select/Select";

const renderXClear = () => {
  return (
    <span className="flex-shrink-0 w-4 h-4 rounded-full bg-primary-500 text-white flex items-center justify-center ml-3 cursor-pointer">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-3 w-3"
        viewBox="0 0 20 20"
        fill="currentColor"
      >
        <path
          fillRule="evenodd"
          d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
          clipRule="evenodd"
        />
      </svg>
    </span>
  );
};

const SidebarFilters = ({
  clearFilter,
  filterData,
  handleFilter,
  facets,
}: {
  clearFilter: () => void;
  filterData: string[];
  handleFilter: (facets: FacetValue, action: "add" | "remove") => void;
  facets?: FacetsData;
}) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const CheckBoxex = ({ item }: { item: FacetItem }) => {
    // check the checked item if it is more than 3 then increase the maxCheckboxesToShow
    // get the count of checked item
    // if count is more than 3 then show all
    // else show only 3

    const checkedCount = item?.values?.filter((item) =>
      filterData?.includes(item.id)
    )?.length;

    let items = item?.values;
    // rearrenge show checked items on top
    const checkedItems = item?.values?.filter((item) =>
      filterData?.includes(item.id)
    );
    const uncheckedItems = item?.values?.filter(
      (item) => !filterData?.includes(item.id)
    );
    items = [...checkedItems, ...uncheckedItems];

    const maxCheckboxesToShow = checkedCount > 3 ? checkedCount : 3;
    const [showAll, setShowAll] = useState(false);

    return (
      <>
        {items
          .slice(0, showAll ? items.length : maxCheckboxesToShow)
          ?.map((item, index) => (
            <div key={index}>
              <div>
                <Checkbox
                  defaultChecked={filterData?.includes(item.id)}
                  sizeClassName="w-5 h-5"
                  labelClassName="text-sm font-normal font-inter"
                  name={item.name}
                  label={item.name}
                  onChange={(e) => {
                    e
                      ? handleFilter(item, "add")
                      : handleFilter(item, "remove");
                  }}
                />
              </div>
              {/* show more if showAll false */}
              {!showAll &&
                index === maxCheckboxesToShow - 1 &&
                items.length > maxCheckboxesToShow && (
                  <span
                    className="text-sm text-[#0068FF] block font-semibold font-inter cursor-pointer mt-3"
                    onClick={() => setShowAll(true)}
                  >
                    Show all
                  </span>
                )}
            </div>
          ))}
      </>
    );
  };

  const DeskTopSideBar = () => {
    const maxCheckboxesToShow = 3;
    const [showAll, setShowAll] = useState(false);

    return (
      <div className="divide-y divide-[#9B9595] ml-1 dark:divide-slate-700">
        {/* clear filter button show only if there is  data in filterData */}
        {filterData?.length > 0 && (
          <div className="relative flex flex-col py-8 space-y-4">
            <h3 className="font-semibold mb-2.5">Clear Filters</h3>
            <div
              className={`flex flex-shrink-0 items-center justify-center px-4 py-2 text-sm rounded-full border border-primary-500 bg-primary-50 text-primary-900 focus:outline-none cursor-pointer select-none`}
              onClick={clearFilter}
            >
              <svg
                className="w-4 h-4"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M22 6.5H16"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M6 6.5H2"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M10 10C11.933 10 13.5 8.433 13.5 6.5C13.5 4.567 11.933 3 10 3C8.067 3 6.5 4.567 6.5 6.5C6.5 8.433 8.067 10 10 10Z"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M22 17.5H18"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M8 17.5H2"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M14 21C15.933 21 17.5 19.433 17.5 17.5C17.5 15.567 15.933 14 14 14C12.067 14 10.5 15.567 10.5 17.5C10.5 19.433 12.067 21 14 21Z"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>

              <span className="ml-2">Filters ({filterData?.length || 0})</span>
              {renderXClear()}
            </div>
          </div>
        )}

        {facets?.facets?.items?.map((item, index) => {
          if (item.values.length <= 0) return null;
          return (
            <div
              key={index}
              className="relative flex flex-col pb-2 pt-6 space-y-4"
            >
              <h3 className="font-semibold font-inter text-xl mb-2.5">
                {item.name}
              </h3>
              {/* render checkboxes */}
              <CheckBoxex item={item} key={index} />
            </div>
          );
        })}
      </div>
    );
  };

  const MobileSideBar = () => {
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);

    const toggleSidebar = () => {
      setIsSidebarOpen(!isSidebarOpen);
    };

    // console.log(isSidebarOpen,"isSidebarOpen")

    return (
      <div>
        {filterData?.length > 0 && (
          <button className="flex flex-shrink-0 items-center justify-center px-4 py-2 text-sm rounded-full border border-primary-500 bg-primary-50 text-primary-900 focus:outline-none cursor-pointer select-none">
            <span
              className="flex justify-center items-center"
              onClick={toggleSidebar}
            >
              <svg
                className="w-4 h-4"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M22 6.5H16"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M6 6.5H2"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M10 10C11.933 10 13.5 8.433 13.5 6.5C13.5 4.567 11.933 3 10 3C8.067 3 6.5 4.567 6.5 6.5C6.5 8.433 8.067 10 10 10Z"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M22 17.5H18"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M8 17.5H2"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M14 21C15.933 21 17.5 19.433 17.5 17.5C17.5 15.567 15.933 14 14 14C12.067 14 10.5 15.567 10.5 17.5C10.5 19.433 12.067 21 14 21Z"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <span className="ml-2">
                Filters
                {filterData.length > 0 && <>({filterData.length || 0})</>}
              </span>
            </span>
            <span
              onClick={() => {
                clearFilter();
                setIsSidebarOpen(false);
              }}
              className="m-1"
            >
              {renderXClear()}
            </span>
          </button>
        )}
        <div
          className={`transition-all ${
            isSidebarOpen ? "opacity-100 h-auto" : "opacity-0 h-0"
          }`}
        >
          {isSidebarOpen && (
            <div className="divide-y divide-[#9B9595] dark:divide-slate-700">
              {facets?.facets?.items?.map((item, index) => {
                if (item.values.length <= 0) return null;

                return (
                  <div
                    key={index}
                    className="relative flex flex-col py-8 space-y-4"
                  >
                    <h3 className="font-semibold mb-2.5">{item.name}</h3>
                    {/* render checkboxes */}
                    <CheckBoxex item={item} key={index} />
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>
    );
  };

  return (
    <div className="nc-SidebarFilters">
      {/* input  */}
      {/* <div className="mb-2">
        <ModalKitInput />
      </div> */}

      <div className="mb-2">
        {/* sort by price ASC or DSC */}
        {/* <div className="flex items-center justify-between md:flex-col  md:items-start gap-2">
          <span className="text-base font-semibold">Sort by price</span>
          <div className="flex  space-x-2 w-52">
            <Select
              className=" max-w-sm"
              onChange={(e) => {
                if (e.target.value === "ASC") {
                  setSearchParams({ price: "ASC" });
                }
                if (e.target.value === "DESC") {
                  setSearchParams({ price: "DESC" });
                }
                if (e.target.value === "") {
                  if (searchParams.get("price")) setSearchParams({});
                }
              }}
            >
              <option value="">sort by price</option>
              {searchParams.get("price") === "ASC" ? (
                <option value="ASC" selected>
                  Low
                </option>
              ) : (
                <option value="ASC">Low</option>
              )}
              {searchParams.get("price") === "DESC" ? (
                <option value="DESC" selected>
                  High
                </option>
              ) : (
                <option value="DESC">High</option>
              )}
            </Select>
          </div>
        </div> */}
      </div>
      <div className="hidden lg:block">{DeskTopSideBar()}</div>
      <div className="lg:hidden">{MobileSideBar()}</div>
    </div>
  );
};

export default SidebarFilters;
