import Label from "components/Label/Label";
import React from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Input from "shared/Input/Input";
import CommonLayout from "./CommonLayout";
import { useMutation } from "@apollo/client";
import { updateUserPasswordMutation } from "graphql/mutations/update-user-password";
import { Field, Form, Formik, FormikHelpers } from "formik";
import * as Yup from 'yup';
import { toast } from "react-hot-toast";

interface FormValues {
  currentPassword: string;
  newPassword: string;
  confirmPassword: string;
}

const AccountPass = () => {
  const[updateUserPassword,updateUserPasswordProp]= useMutation(updateUserPasswordMutation)

  const validationSchema = Yup.object().shape({
    currentPassword: Yup.string().required('Current password is required'),
    newPassword: Yup.string().required('New password is required'),
    confirmPassword: Yup.string()
      .required('Confirm password is required')
      .test('passwords-match', 'Passwords must match', function(value) {
        return value === this.parent.newPassword;
      }),
  });

const handleSubmit = (values: FormValues, { setSubmitting, resetForm }: FormikHelpers<FormValues>) => {
  // Handle form submission logic here
 
  
  updateUserPassword({variables:{currentPassword:values.currentPassword,newPassword:values.newPassword}})
  .then((res)=>{
    if(res.data?.updateCustomerPassword?.__typename==="Success"){
      toast.success("Password updated successfully")
    }
    // clear the form
    resetForm();
  })

};


if(updateUserPasswordProp.error) return (
  <div className="flex flex-col justify-center items-center h-screen">
    <h1 className="text-2xl font-semibold text-center my-3">Something went wrong!</h1>
    <ButtonPrimary onClick={()=>{window.location.reload()}}>Reload</ButtonPrimary>
  </div>
)



  return (
    <div>
      <CommonLayout>
        <div className="space-y-10 sm:space-y-12">
          {/* HEADING */}
          {/* <h2 className="text-2xl sm:text-3xl font-semibold">
            Update your password
          </h2> */}
          { 
            updateUserPasswordProp.loading && (
              <ButtonPrimary
              loading={true}
              className="flex justify-center items-center">
                Updating Password
              </ButtonPrimary>
            )
          }
          {updateUserPasswordProp?.data && (
            <div className="text-red-500">{updateUserPasswordProp.data?.updateCustomerPassword?.message}</div>
          )}
          <Formik
      initialValues={{
        currentPassword: '',
        newPassword: '',
        confirmPassword: '',
      }}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ errors, touched }) => (
        <Form className="max-w-xl space-y-6">
          <div>
            <Label>Current password</Label>
            <Field
              type="password"
              name="currentPassword"
              className="mt-1.5"
              as = {Input}

            />
            {errors.currentPassword && touched.currentPassword && (
              <div className="text-red-500">{errors.currentPassword}</div>
            )}
          </div>
          <div>
            <Label>New password</Label>
            <Field type="password" name="newPassword" className="mt-1.5" as ={Input} />
            {errors.newPassword && touched.newPassword && (
              <div className="text-red-500">{errors.newPassword}</div>
            )}
          </div>
          <div>
            <Label>Confirm password</Label>
            <Field
              type="password"
              name="confirmPassword"
              className="mt-1.5"
              as = {Input}
             
            />
            {errors.confirmPassword && touched.confirmPassword && (
              <div className="text-red-500">{errors.confirmPassword}</div>
            )}
          </div>
          <div className="pt-2">
            <ButtonPrimary type="submit">Update password</ButtonPrimary>
          </div>
        </Form>
      )}
    </Formik>
        </div>
      </CommonLayout>
    </div>
  );
};

export default AccountPass;
