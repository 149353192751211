import { gql } from "@apollo/client";
import { OrderDetails } from "graphql/queries/fragments/order-responce";



const modifyOrderMutation = gql`
${OrderDetails}
mutation($orderLineId:ID!,$quantity:Int!) {
    adjustOrderLine(orderLineId:$orderLineId,quantity:$quantity){
      __typename
      ...on OrderModificationError{
        errorCode
        message
      }
      ...OrderDetails
    }
  }`

export default modifyOrderMutation;