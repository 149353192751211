import React, { FC, useEffect, useLayoutEffect, useRef, useState } from "react";
import { MinusIcon, PlusIcon, CheckIcon } from "@heroicons/react/24/solid";
import { toast } from "react-hot-toast";
import Input from "shared/Input/Input";

export interface NcInputNumberProps {
  className?: string;
  defaultValue?: number;
  widthClass?: string;
  min?: number;
  max?: number;
  id?: string;
  productId?: string;

  onChange?: (value: number) => void;
  onCartChange?: (
    id: string,
    productId: string,
    qty: number,
    isAdding: Boolean,
    minQuantity: number
  ) => void;
  label?: string;
  desc?: string;
}

const NcInputNumber: FC<NcInputNumberProps> = ({
  className = "",
  defaultValue = 1,
  id,
  widthClass = "w-[150px]",
  productId,
  min = 1,
  max = 50000,
  onChange,
  onCartChange,
  label,
  desc,
}) => {
  const [value, setValue] = useState(defaultValue);

  const inputValueRef = useRef<HTMLInputElement>(null);
  const [isFocused, setIsFocused] = useState(false);

  useLayoutEffect(() => {
    setInputToValue();
  }, [value]);

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  const changeValue = (n: number) => {
    if (isNaN(n)) {
      toast.error("Only number is allowed");
      setValue(min);
    }

    setValue((p) => {
      console.log(p, "prvious value");
      if (min > n) {
        toast.error(`Minimum quantity is ${min}`);
        setInputToValue();
        return p;
      }
      if (max && max <= n) {
        setInputToValue();
        return p;
      }

      if (n < p) {
        onChange && onChange(n);
        if (id && onCartChange && productId && min)
          onCartChange(id, productId, n, false, min);
      } else if (n > p) {
        onChange && onChange(n);
        if (id && onCartChange && productId && min)
          onCartChange(id, productId, n - p, true, min);
      }
      return n;
    });
  };

  const setInputToValue = () => {
    if (inputValueRef.current) {
      inputValueRef.current.value = value.toString();
    }
  };

  const handleClickDecrement = () => {
    changeValue(value - 1);
  };

  const handleClickIncrement = () => {
    // console.log(id,onCartChange,productId)
    if (max && max <= value) return;
    changeValue(value + 1);
  };

  const renderLabel = () => {
    return (
      <div className="flex flex-col">
        <span className="font-medium text-neutral-800 dark:text-neutral-200">
          {label}
        </span>
        {desc && (
          <span className="text-xs text-neutral-500 dark:text-neutral-400 font-normal">
            {desc}
          </span>
        )}
      </div>
    );
  };

  return (
    <div
      className={`nc-NcInputNumber flex items-center justify-between border border-[#939393] h-9 rounded-md ${className}`}
    >
      {label && renderLabel()}

      <div
        className={`nc-NcInputNumber__content flex items-center justify-between ${widthClass}`}
      >
        {!isFocused ? (
          <button
            className="w-4 h-4 flex items-center justify-center bg-transparent focus:outline-none disabled:opacity-50 disabled:cursor-default"
            type="button"
            onClick={handleClickDecrement}
          >
            <MinusIcon className="w-4 h-4" />
          </button>
        ) : null}

        <Input
          ref={inputValueRef}
          defaultValue={value}
          onChange={() => setIsFocused(true)}
          type="number"
          className="select-none block flex-1 w-2 md:w-6 lg:w-6 text-center leading-none bg-transparent"
        />

        {isFocused ? (
          <button
            className="w-4 h-4 flex items-center justify-center bg-transparent focus:outline-none disabled:opacity-50 disabled:cursor-default"
            type="button"
            onClick={() => {
              console.log(inputValueRef?.current?.value);
              if (!inputValueRef.current?.value) {
                setIsFocused(false);
                setInputToValue();
                toast.error("Please enter value");
                return;
              }
              changeValue(parseInt(inputValueRef.current?.value));
              setIsFocused(false);
            }}
          >
            <CheckIcon className="w-4 h-4" />
          </button>
        ) : null}

        {!isFocused ? (
          <button
            className="w-4 h-4 flex items-center justify-center bg-transparent focus:outline-none disabled:opacity-50 disabled:cursor-default"
            type="button"
            onClick={handleClickIncrement}
            disabled={max ? max <= value : false}
          >
            <PlusIcon className="w-4 h-4" />
          </button>
        ) : null}
      </div>
    </div>
  );
};

export default NcInputNumber;
