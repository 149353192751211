import { useQuery } from "@apollo/client";
import ProductCard from "components/ProductCard";
import {  GET_PAGINATED_PRODUCTS, searchProductQuery } from "graphql/queries/queries";
import {  Product, ProductData } from "graphql/queries/types";
import { FC, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useSearchParams } from "react-router-dom";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Pagination from "shared/Pagination/Pagination";


let filterData:string[] = []
interface ProductPacksProps {
    className?: string;
}

const ProductPacks: FC<ProductPacksProps> = ({ className = "" }) => {

  const [isProducts, setIsProducts] = useState(true);


  const [searchParams, setSearchParams]=useSearchParams()
  const searchParamsIds =searchParams.getAll("filters")
  filterData = searchParamsIds

 



  

  const searchedProducts = useQuery(searchProductQuery,
    {variables:{ids:searchParamsIds}}
   );

   let filteredProductsIds = searchedProducts.data?.search?.items?.map((item: any) => item.id);
   filteredProductsIds = new Set(filteredProductsIds); // Convert to Set to store unique values
   filteredProductsIds = Array.from(filteredProductsIds); 


  const PAGE_SIZE = 10; 


  const [currentPage, setCurrentPage] = useState(1);
 
   const [products, setProducts] = useState<Product[]>([]);
  

   let productVariables = searchParamsIds.length<1? 
                                            {variables:{take:PAGE_SIZE,skip:(currentPage-1)*PAGE_SIZE,isPack:true}}
                                          : {variables:{take:PAGE_SIZE,skip:(currentPage-1)*PAGE_SIZE,ids:filteredProductsIds,isPack:true}}


  const { loading, error, data } = useQuery<ProductData>(GET_PAGINATED_PRODUCTS,productVariables);






  useEffect(() => {
    if (!loading && data) {
      setProducts([...data.products.items]);
      setIsProducts(true);

      if (data?.products?.items?.length < 1) {
        setIsProducts(false);
      }
      let isItems = 0;
      data.products.items.forEach((item) => {
        if (item.variantList.items.length > 0) {
          isItems++;
        }
      });
    
      if (isItems < 1) {
        setIsProducts(false);
      }

    }
  }, [data, loading]);
  
  
   







  if (loading) return (
    <div className="flex justify-center items-center h-screen">
      <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-primary-500"></div>
    </div>

  )

  if (error) {
    return (
      <div className="flex flex-col justify-center items-center h-screen">
        <h1 className="text-2xl font-semibold text-center my-3">Something went wrong!</h1>
        <ButtonPrimary onClick={()=>{window.location.reload()}}>Reload</ButtonPrimary>
      </div>
    )
  }



  return (
    <div
      className={`nc-PageCollection2 ${className}`}
      data-nc-id="PageCollection2"
    >
      <Helmet>
        <title>Packs || 360CUSTOMIZER</title>
      </Helmet>

      <div className="container py-16 lg:pb-28 lg:pt-20 space-y-16 sm:space-y-20 lg:space-y-28">
        <div className="space-y-10 lg:space-y-14">
          {/* HEADING */}
          <div className="max-w-screen-sm">
            <h2 className="block text-2xl sm:text-3xl lg:text-4xl font-semibold">
              Our Packs
            </h2>
            {/* <span className="block mt-4 text-neutral-500 dark:text-neutral-400 text-sm sm:text-base">
              We not only help you design exceptional products, but also make it
              easy for you to share your designs with more like-minded people.
            </span> */}
          </div>

          <hr className="border-slate-200 dark:border-slate-700" />
          <main>
            {/* LOOP ITEMS */}
            <div className="flex flex-col lg:flex-row">
              {/* <div className="lg:w-1/3 xl:w-1/4 pr-4">
                <SidebarFilters clearFilter={clearFilters}  filterData={filterData}  handleFilter={handleFilterPageData} facets={facetData.data} />
              </div> */}
              <div className="flex-shrink-0 mb-10 lg:mb-0 lg:mx-4 border-t lg:border-t-0"></div>
              <div className="flex-1 ">
                {isProducts?
                (<div className="flex-1 grid sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-x-8 gap-y-10 ">
                  {products?.map((item, index) => (
                    item?.variantList?.items?.length>0 && <ProductCard data={item} key={index} />
                  )
                  )}
                </div>):
                (
                  <div className="flex justify-center">
                  <ButtonPrimary 
                  className="mx-auto"
                  href="/product-packs"
                  >
                    No Packs Found
                  </ButtonPrimary>
                  </div>
                )

                }
                { isProducts && (<div className="flex  justify-center mt-10">
                  <Pagination total={data?.products.totalItems} pageSetter={setCurrentPage} currentPage={currentPage} />
                  </div>)
                }
                
              </div>
            </div>
          </main>
        </div>
      </div>
    </div>
  );
};

export default ProductPacks;
