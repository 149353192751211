import React, { FC } from "react";
import { Link } from "react-router-dom";
import firstLeftProduct from "../../images/SectionProductCatalogue/firstLeftProduct.svg";
import secondLeftProduct from "../../images/SectionProductCatalogue/secondLeftProduct.svg";
import thirdLeftProduct from "../../images/SectionProductCatalogue/thirdLeftProduct.svg";
import firstRightProduct from "../../images/SectionProductCatalogue/firstRightProduct.svg";
import secondRightProduct from "../../images/SectionProductCatalogue/secondRightProduct.svg";
import thirdRightProduct from "../../images/SectionProductCatalogue/thirdRightProduct.svg";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import styles from "../../styles/SectionProductCatalogue.module.css";

interface Product {
  id: number;
  image: string;
  title: string;
  link: string;
}

const SectionProductCatalogue: FC = () => {
  const leftKits: Product[] = [
    {
      id: 1,
      image: firstLeftProduct,
      title: "T-Shirts",
      link: "/page-collection?filters=1",
    },
    {
      id: 2,
      image: secondLeftProduct,
      title: "Bags",
      link: "/page-collection?filters=26",
    },
    {
      id: 3,
      image: thirdLeftProduct,
      title: "Electronics",
      link: "/page-collection?filters=34",
    },
  ];

  const rightKits: Product[] = [
    {
      id: 4,
      image: thirdRightProduct,
      title: "Bottles",
      link: "/page-collection?filters=9",
    },
    {
      id: 5,
      image: secondRightProduct,
      title: "Hoodies",
      link: "/page-collection?filters=3",
    },
    {
      id: 6,
      image: firstRightProduct,
      title: "Your Branding Kits",
      link: "/product-packs",
    },
  ];

  return (
    <div className="w-full py-4 md:py-10">
      <div className="flex justify-between items-center px-2 lg:px-16 mb-6">
        <div className="text-left">
          <h2 className="text-xl md:text-3xl tracking-wide font-righteous font-medium">
            Product Categories
          </h2>
          <p className="text-sm text-black dark:text-neutral-100 font-inter">
            Explore Our Range
          </p>
        </div>
        <div>
          <ButtonPrimary
            href={"/page-collection"}
            className="px-4 py-2 md:px-4 md:py-2 text-blue-500 bg-transparent rounded-md border border-blue-400 text-xs"
          >
            View All
          </ButtonPrimary>
        </div>
      </div>
      <div className="grid grid-cols-1 gap-3 lg:gap-0 mx-auto md:grid-cols-2 lg:grid-cols-6 lg:max-w-[92%]">
        {/* Left Section */}
        <div className="lg:col-span-3">
          <div
            className={`${styles["brand-card"]} w-[96%] mx-auto rounded-lg overflow-hidden mb-4 relative`}
          >
            <Link to={leftKits[0].link}>
              <img
                src={leftKits[0].image}
                alt={leftKits[0].title}
                className="w-[100%] h-52 object-cover"
                id={`product_${leftKits[0].id}`}
              />
            </Link>
            <div className={styles.overlay}>
              <p className={styles["overlay-text"]}>{leftKits[0].title}</p>
            </div>
          </div>
          <div className="flex justify-center items-center w-[98%] mx-auto">
            {leftKits.slice(1).map((kit) => (
              <div
                key={kit.id}
                className={`${styles["brand-card"]} w-[48%] mx-auto rounded-lg overflow-hidden relative`}
              >
                <Link to={kit.link}>
                  <img
                    src={kit.image}
                    alt={kit.title}
                    className="w-[100%] h-auto object-cover"
                    id={`product_${kit.id}`}
                  />
                </Link>
                <div className={styles.overlay}>
                  <p className={styles["overlay-text"]}>{kit.title}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
        {/* Right Section */}
        <div className="lg:col-span-3">
          <div className="flex justify-center items-center mb-4 w-[98%] mx-auto">
            {rightKits.slice(0, 2).map((kit) => (
              <div
                key={kit.id}
                className={`${styles["brand-card"]} w-[48%] mx-auto rounded-lg overflow-hidden relative `}
              >
                <Link to={kit.link}>
                  <img
                    src={kit.image}
                    alt={kit.title}
                    className="w-[100%] h-auto object-cover"
                    id={`product_${kit.id}`}
                  />
                </Link>
                <div className={styles.overlay}>
                  <p className={styles["overlay-text"]}>{kit.title}</p>
                </div>
              </div>
            ))}
          </div>
          <div
            className={`${styles["brand-card"]} w-[96%] mx-auto rounded-lg overflow-hidden mb-4 relative`}
          >
            <Link to={rightKits[2].link}>
              <img
                src={rightKits[2].image}
                alt={rightKits[2].title}
                className="w-[100%] h-52 object-cover"
                id={`product_${rightKits[2].id}`}
              />
            </Link>
            <div className={styles.overlay}>
              <p className={styles["overlay-text"]}>{rightKits[2].title}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionProductCatalogue;
