import React, { FC } from "react";
import NcImage from "shared/NcImage/NcImage";
import rightImgDemo from "images/product-kit.png";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Logo from "shared/Logo/Logo";
import backgroundLineSvg from "images/Moon.svg";
import { Builder } from '@builder.io/react'

export interface SectionPromo2Props {
  className?: string
  title?: string
  description?: string
  buttonText?: string
  buttonLink?: string
  image?: string
}

const SectionPromo2: FC<SectionPromo2Props> = ({ className = "lg:pt-10", title = 'Promo title', description = 'Promo Description', buttonLink = "", buttonText = "Button", image = rightImgDemo }) => {
  return (
    <div className={`nc-SectionPromo2 ${className}`}>
      <div className="relative flex flex-col lg:flex-row lg:justify-end bg-yellow-50 dark:bg-slate-800 rounded-2xl sm:rounded-[40px] p-4 pb-0 sm:p-5 sm:pb-0 lg:p-24">
        <div className="absolute inset-0">
          <img
            className="absolute w-full h-full object-contain dark:opacity-5"
            src={backgroundLineSvg}
            alt="backgroundLineSvg"
          />
        </div>

        <div className="lg:w-[45%] max-w-lg relative">
          <Logo className="w-28" />
          <h2 className="font-semibold text-3xl sm:text-4xl xl:text-5xl 2xl:text-6xl mt-6 sm:mt-10 !leading-[1.13] tracking-tight">
            {title}
          </h2>
          <span className="block mt-6 text-slate-500 dark:text-slate-400" dangerouslySetInnerHTML={{ __html: description }}>

          </span>
          <div className="flex space-x-2 sm:space-x-5 mt-6 sm:mt-12">
            <ButtonPrimary
              href={buttonLink}
              className="dark:bg-slate-200 dark:text-slate-900"
            >
              {buttonText}
            </ButtonPrimary>
          </div>
        </div>

        <NcImage
          containerClassName="relative rounded-xl   block lg:absolute lg:left-0 lg:bottom-0 mt-10 lg:mt-0 max-w-xl lg:max-w-[calc(55%-40px)]"
          src={image}
        />
      </div>
    </div>
  );
};
Builder.registerComponent(SectionPromo2, {
  name: 'Promo Content',
  inputs: [
    { name: 'className', type: 'text' },
    { name: 'title', type: 'longText' },
    { name: 'description', type: 'richText' },
    { name: 'buttonText', type: 'string' },
    { name: 'buttonLink', type: 'string' },
    { name: 'image', type: 'file', allowedFileTypes: ['jpeg', 'png', 'mp4', 'gif', 'pdf', 'svg'] },
  ],
})
export default SectionPromo2;
