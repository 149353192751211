import React, { FC, useRef } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { useMutation } from "@apollo/client";
import ADD_EMAIL_MUTATION from "graphql/mutations/add-email";
import { toast } from "react-hot-toast";
import heroImage from "../../images/SectionKnowMore/sectionWantToKnowMoreHeroImage.svg";

export interface SectionKnowMoreProps {
  className?: string;
  image?: string;
}

const SectionKnowMore: FC<SectionKnowMoreProps> = ({
  className = "",
  image,
}) => {
  const [addEmailMutation, { data, error, loading }] =
    useMutation(ADD_EMAIL_MUTATION);

  const email = useRef<HTMLInputElement>(null);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const emailValue = email.current?.value;
    if (!emailValue) return;

    addEmailMutation({ variables: { email: emailValue } });
  };

  if (loading)
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-primary-500"></div>
      </div>
    );

  if (error) {
    toast.error(error.message);
  }

  if (!loading && data && data?.createEmailSubscriber?.id) {
    toast.success("Thank you for subscribing");
  }

  return (
    <div
      className={`relative px-0 lg:px-14 py-6 lg:py-28 md:pb-20 lg:mt-2 mb-20 lg:mb-10 ${className}`}
    >
      <div className="relative flex flex-col lg:flex-row lg:justify-between items-start lg:items-center p-2 pb-0">
        <div className="w-[80%] lg:w-[50%] max-w-lg relative">
          <h2 className="tracking-wider font-righteous font-medium text-xl md:text-3xl">
            Want to know more
          </h2>
          <p className="text-sm text-black dark:text-neutral-100 font-inter">
            Contact us today
          </p>
          <ButtonPrimary
            className="bg-transparent border border-white bg-[#0169FF] text-white rounded-md mt-4"
            sizeClass="py-2 px-3 sm:py-2 sm:px-6"
            href={"/pages/contact-us"}
          >
            Book A Demo
          </ButtonPrimary>
        </div>
        <div className="lg:w-[80%] lg:max-w-7xl absolute right-0 mt-7 lg:mt-0 lg:ml-0 overflow-hidden">
          <img
            src={image ? image : heroImage}
            className="w-full h-full object-cover"
            alt="Hero Image"
          />
        </div>
      </div>
    </div>
  );
};

export default SectionKnowMore;
