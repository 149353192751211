import React, { FC } from "react";
import facebookSvg from "images/Facebook.svg";
import twitterSvg from "images/Twitter.svg";
import googleSvg from "images/Google.svg";
import { Helmet } from "react-helmet-async";
import Input from "shared/Input/Input";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { Link, useNavigate } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { userRegistrationMutation } from "graphql/mutations/user-registration.mutation";
import { Formik, Form, Field, ErrorMessage,FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { toast } from "react-hot-toast";


const inputClasses = `
      block w-full border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 
      bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900 
      disabled:bg-neutral-200 dark:disabled:bg-neutral-800 rounded-2xl text-sm font-normal h-11 px-4 py-3 `

export interface PageSignUpProps {
  className?: string,
}

const loginSocials = [
  {
    name: "Continue with Facebook",
    href: "#",
    icon: facebookSvg,
  },
  {
    name: "Continue with Twitter",
    href: "#",
    icon: twitterSvg,
  },
  {
    name: "Continue with Google",
    href: "#",
    icon: googleSvg,
  },
];

interface FormValues {
  firstName: string,
  lastName: string,
  email: string,
  mobile: string,
  password: string,
  confirmPassword: string
}


const validationSchema = Yup.object().shape({
  firstName: Yup.string().required('First Name is required'),
  lastName: Yup.string().required('Last Name is required'),
  email: Yup.string().email('Invalid email address').required('Email is required'),
  mobile: Yup.string()
  .matches(/^[789]\d{9}$/, 'Invalid mobile number')
  .required('Mobile is required'),
  password: Yup.string().required('Password is required'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password'), undefined], 'Passwords must match')
    .required('Confirm Password is required'),
});

const PageSignUp: FC<PageSignUpProps> = ({ className = "" }) => {
  const navigate = useNavigate();
  const [register, { error, loading }] = useMutation(userRegistrationMutation);


  const handleSubmit = async (values: FormValues, { setSubmitting, resetForm }: FormikHelpers<FormValues>) => {
    // console.log(values);
    const { email, firstName, lastName, mobile, password } = values;

    const resp = await register({
      variables: {
        email,
        firstName,
        lastName,
        phoneNumber:mobile.toString(),
        password,
      },
    });

    // console.log(resp);

    if (resp?.data?.registerCustomerAccount?.success) {
      resetForm();
      toast.success("Account created successfully");
      // check your email to verify your account
      toast.success("Check your email to verify your account");
      navigate("/login");
    }
    else {
      toast.error(resp?.data?.registerCustomerAccount?.message)
    }

    // console.log(resp);
  }

  if(loading){
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-primary-500"></div>
      </div>
    )
  }

  if(error) return (
    <div className="flex flex-col justify-center items-center h-screen">
      <h1 className="text-2xl font-semibold text-center my-3">Something went wrong!</h1>
      <ButtonPrimary onClick={()=>{window.location.reload()}}>Reload</ButtonPrimary>
    </div>
  )

  return (
    <div className={`nc-PageSignUp  ${className}`} data-nc-id="PageSignUp">
      <Helmet>
        <title>Sign up || 360CUSTOMIZER</title>
      </Helmet>
      <div className="container mb-24 lg:mb-32">
        <h2 className="my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          Signup
        </h2>
        <div className="max-w-md mx-auto space-y-6 ">
          {/* <div className="grid gap-3">
            {loginSocials.map((item, index) => (
              <a
                key={index}
                href={item.href}
                className=" flex w-full rounded-lg bg-primary-50 dark:bg-neutral-800 px-4 py-3 transform transition-transform sm:px-6 hover:translate-y-[-2px]"
              >
                <img
                  className="flex-shrink-0"
                  src={item.icon}
                  alt={item.name}
                />
                <h3 className="flex-grow text-center text-sm font-medium text-neutral-700 dark:text-neutral-300 sm:text-sm">
                  {item.name}
                </h3>
              </a>
            ))}
          </div> */}
          {/* OR */}
          {/* <div className="relative text-center">
            <span className="relative z-10 inline-block px-4 font-medium text-sm bg-white dark:text-neutral-400 dark:bg-neutral-900">
              OR
            </span>
            <div className="absolute left-0 w-full top-1/2 transform -translate-y-1/2 border border-neutral-100 dark:border-neutral-800"></div>
          </div> */}
          {/* FORM */}
          <Formik
      initialValues={{
        firstName: '',
        lastName: '',
        email: '',
        mobile: '',
        password: '',
        confirmPassword: '',
      }}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting }) => (
        <Form className="grid grid-cols-1 gap-6" action="#" method="post">
          <label className="block">
            <span className="text-neutral-800 dark:text-neutral-200">First Name</span>
            <Field type="text" name="firstName" className={`mt-1 ${inputClasses}`}/>
            <ErrorMessage name="firstName" component="div" className="text-red-500" />
          </label>

          <label className="block">
            <span className="text-neutral-800 dark:text-neutral-200">Last Name</span>
            <Field type="text" name="lastName" className={`mt-1 ${inputClasses}`}/>
            <ErrorMessage name="lastName" component="div" className="text-red-500" />
          </label>

          <label className="block">
            <span className="text-neutral-800 dark:text-neutral-200">Email address</span>
            <Field
              type="email"
              name="email"
              placeholder="example@example.com"
              className={`mt-1 ${inputClasses}`}
            />
            <ErrorMessage name="email" component="div" className="text-red-500" />
          </label>

          <label className="block">
            <span className="text-neutral-800 dark:text-neutral-200">Mobile</span>
            <Field
              type="number"
              name="mobile"
              placeholder="Mobile Number"
              className={`mt-1 remove-num-arrow ${inputClasses}`}
            />
            <ErrorMessage name="mobile" component="div" className="text-red-500" />
          </label>

          <label className="block">
            <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">Password</span>
            <Field type="password" name="password" className={`mt-1 ${inputClasses}`} />
            <ErrorMessage name="password" component="div" className="text-red-500" />
          </label>

          <label className="block">
            <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">Confirm Password</span>
            <Field   type="password" name="confirmPassword" className={`mt-1 ${inputClasses}`} />
            <ErrorMessage name="confirmPassword" component="div" className="text-red-500" />
          </label>

      
          <ButtonPrimary type="submit"  disabled={isSubmitting}>Continue</ButtonPrimary>
        </Form>
      )}
    </Formik>

          {/* ==== */}
          <span className="block text-center text-neutral-700 dark:text-neutral-300">
            Already have an account? {` `}
            <Link className="text-green-600" to="/login">
              Sign in
            </Link>
          </span>
        </div>
      </div>
    </div>
  );
};

export default PageSignUp;



