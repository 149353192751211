import React, { FC } from "react";
import { SocialType } from "shared/SocialsShare/SocialsShare";
import { useQuery } from "@apollo/client";
import { SocialLinks } from "shared/Footer/Footer";
import { SELLER_SOCIAL_LINKS } from "graphql/queries/queries";
// import facebook from "images/socials/faceboook.png";
// import instagram from "images/socials/instagram.png";
// import linkedIn from "images/socials/linedin.png";
// import twitter from "images/socials/twitter.svg";
// import telegram from "images/socials/telegram.svg";
// import youtube from "images/socials/youtube.svg";
import instagram from "../../images/socials/instagram.svg";
import linkedin from "../../images/socials/linkedIn.svg";
import facebook from "../../images/socials/facebook.svg";

export interface SocialsListProps {
  className?: string;
  itemClass?: string;
  socials?: SocialType[];
}

const SocialsList: FC<SocialsListProps> = ({
  className = "",
  itemClass = "block w-6 h-6",
}) => {
  const data = useQuery<SocialLinks>(SELLER_SOCIAL_LINKS, {
    fetchPolicy: "no-cache",
  });

  if (data.loading) return <p></p>;
  if (data.error) return <p></p>;

  let links = data.data;

  let socials: SocialType[] = [
    {
      name: "Instagram",
      icon: instagram,
      href: links?.activeChannel?.seller?.customFields?.instagram || "#",
    },
    {
      name: "LinkedIn",
      icon: linkedin,
      href: links?.activeChannel?.seller?.customFields?.linkedin || "#",
    },
    {
      name: "Facebook",
      icon: facebook,
      href: links?.activeChannel?.seller?.customFields?.facebook || "#",
    },
    // { name: "Youtube", icon: youtube, href: links?.activeChannel?.seller?.customFields?.youtube || "#" },
    // { name: "Telegram", icon: telegram, href: links?.activeChannel?.seller?.customFields?.telegram || "#" },
    // { name: "Twitter", icon: twitter, href: links?.activeChannel?.seller?.customFields?.twitter || "#" },
  ];
  // remove empty socials
  socials = socials.filter((social) => social.href !== "#");

  return (
    <nav
      className={`nc-SocialsList flex space-x-2.5 text-2xl text-neutral-6000 dark:text-neutral-300 ${className}`}
      data-nc-id="SocialsList"
    >
      {socials.map((item, i) => (
        <a
          key={i}
          className={`${itemClass}`}
          href={item.href}
          target="_blank"
          rel="noopener noreferrer"
          title={item.name}
        >
          <img src={item.icon} alt={item.name} />
        </a>
      ))}
    </nav>
  );
};

export default SocialsList;
