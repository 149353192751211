import React from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import backgroundEllipses from "../../images/about/backgroundEllipse.png";

export interface AboutApplyNowProps {
  className?: string;
  subTitle?: string;
  heading?: string;
  subHeading?: string;
}

const AboutApplyNow: React.FC<AboutApplyNowProps> = ({
  className = "",
  subHeading = "Join Our Team",
  heading = "Be a Customizer",
  subTitle = "360Customizer: Where Affordability Meets Reliability, Crafting Enduring Excellence",
}) => {
  return (
    <div
      className={`nc-AboutApplyNow flex flex-col md:flex-row gap-4 w-full py-6 md:py-10 rounded-2xl ${className}`}
      style={{
        backgroundImage: `url(${backgroundEllipses})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundColor: "#FF8A48",
      }}
    >
      <div className="flex-1 p-4 md:p-0 pl-0 md:pl-14 text-center md:text-left">
        <div>
          <h2 className="text-white text-2xl font-inter font-medium mb-1">
            {subHeading}
          </h2>
          <h1 className="text-white text-4xl md:text-5xl font-righteous font-bold mb-4 tracking-wider">
            {heading}
          </h1>
          <p className="text-sm text-white dark:text-neutral-100 font-inter">
            {subTitle}
          </p>
        </div>
      </div>

      <div className="flex flex-1 items-center justify-center mt-10 md:mt-0">
        <ButtonPrimary
          className="text-white bg-[#0169FF] hover:bg-black px-6 py-3 w-full max-w-[200px] rounded-md shadow-none"
          href="/pages/career"
        >
          Apply Now
        </ButtonPrimary>
      </div>
    </div>
  );
};

export default AboutApplyNow;
