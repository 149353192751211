import React, { FC } from "react";
import client1 from "../../images/clients/CLient1.png";
import client2 from "../../images/clients/CLient2.png";
import client3 from "../../images/clients/CLient3.png";
import client4 from "../../images/clients/CLient4.png";
import client5 from "../../images/clients/CLient5.png";
import client6 from "../../images/clients/CLient6.png";
import client7 from "../../images/clients/CLient7.png";
import client8 from "../../images/clients/CLient8.png";
import client9 from "../../images/clients/CLient9.png";
import client10 from "../../images/clients/CLient10.png";
import client11 from "../../images/clients/CLient11.png";
import client12 from "../../images/clients/CLient12.png";

interface ClientData {
  id: number;
  img: string;
  name: string;
}

const clientData: ClientData[] = [
  {
    id: 1,
    img: client1,
    name: "Tata Bluescope Steel",
  },
  {
    id: 2,
    img: client2,
    name: "Volkswagen",
  },
  {
    id: 3,
    img: client3,
    name: "Tata Autocomp",
  },
  {
    id: 4,
    img: client4,
    name: "SmithNephew",
  },
  {
    id: 5,
    img: client5,
    name: "Tsystem",
  },
  {
    id: 6,
    img: client6,
    name: "Philipse",
  },
  {
    id: 7,
    img: client7,
    name: "Persistent",
  },
  {
    id: 8,
    img: client8,
    name: "Hexaware",
  },
  {
    id: 9,
    img: client9,
    name: "One",
  },
  {
    id: 10,
    img: client10,
    name: "Eaton",
  },
  {
    id: 11,
    img: client11,
    name: "Rockwell Automation",
  },
  {
    id: 12,
    img: client12,
    name: "Wipropari",
  },
];

const SectionOurClients: FC = () => {
  return (
    <section className="py-4 md:py-10">
      <div className="px-0 overflow-hidden">
        <div className="ml-2 lg:ml-16 text-left">
          <h2 className="text-xl lg:text-3xl font-righteous font-medium tracking-wide ">
            Our Clients
          </h2>
          <p className="text-sm text-black dark:text-neutral-100 font-inter">
            "Meet our valued clients, inspiring partnerships."
          </p>
        </div>
        <div className="flex whitespace-nowrap overflow-hidden">
          <div
            className="flex gap-3"
            style={{ animation: "move 35s linear infinite" }}
          >
            {clientData.map((client, index) => (
              <div
                key={client.id}
                className="w-48 h-40 flex-shrink-0 mx-2"
                style={{ flex: "0 0 auto" }}
              >
                <img
                  src={client.img}
                  alt={client.name}
                  className="w-full h-full object-contain"
                  style={{ animationDelay: `${index * 2}s` }}
                />
              </div>
            ))}
          </div>
          <div
            className="flex gap-3"
            style={{ animation: "move 35s linear infinite" }}
          >
            {clientData.map((client, index) => (
              <div
                key={client.id}
                className="w-48 h-40 flex-shrink-0 mx-2"
                style={{ flex: "0 0 auto" }}
              >
                <img
                  src={client.img}
                  alt={client.name}
                  className="w-full h-full object-contain"
                  style={{ animationDelay: `${index * 2}s` }}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default SectionOurClients;
