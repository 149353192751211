import { gql } from "@apollo/client";



export const OrderDetails = gql`
fragment OrderDetails on Order {
    id
    code
    lines {
      id
      linePrice
      quantity
      productVariant {
        id
        name
        price
        priceWithTax
        featuredAsset {
          preview
        }
        product {
          featuredAsset {
            preview
          }
        }
      }
    }
  }
  `