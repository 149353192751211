import React, { FC, useState, useRef, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import clothingBrands from "../../images/SectionOurBrandParteners/clothingPartner.png";
import electronicsBrands from "../../images/SectionOurBrandParteners/electronicsPartner.png";
import luggageBrands from "../../images/SectionOurBrandParteners/luggagePartner.png";
import homeBrands from "../../images/SectionOurBrandParteners/HomeKitchenPartner.png";
import "../../styles/SectionOurTestimonials.module.css";

const SectionOurBrandPartners: FC = () => {
  const brands = [clothingBrands, electronicsBrands, luggageBrands, homeBrands];
  const [currentSlide, setCurrentSlide] = useState(0);
  const sliderRef = useRef<Slider>(null);
  const interval = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    interval.current = setInterval(() => {
      sliderRef.current?.slickNext();
    }, 4000);

    return () => {
      if (interval.current) clearInterval(interval.current);
    };
  }, []);

  const settings = {
    // dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    beforeChange: (current: number, next: number) => setCurrentSlide(next),
    customPaging: (i: number) => (
      <span
        key={i}
        style={{
          width: "15px",
          height: "15px",
          background: i === currentSlide ? "#000" : "#C7C7C7",
          borderRadius: "50%",
          display: "inline-block",
          marginRight: "10px",
          cursor: "pointer",
        }}
        onClick={() => handleDotClick(i)}
      ></span>
    ),
  };

  const handleDotClick = (index: number) => {
    sliderRef.current?.slickGoTo(index);
  };

  return (
    <div className="w-full py-2 md:py-10 my-2 md:my-4 bg-[#F8F8F8]">
      <div className="mb-8 ml-2 lg:ml-16 text-left">
        <h2 className="text-xl md:text-3xl tracking-wide font-righteous font-medium">
          Our Brand Partners
        </h2>
        <p className="text-sm text-black dark:text-neutral-100 font-inter">
          Discover our trusted partners
        </p>
      </div>
      <div className="w-full md:w-[90%] mx-auto">
        <Slider ref={sliderRef} {...settings}>
          {brands.map((brand, index) => (
            <div key={index} className="px-4">
              <div className="brand-card">
                <img
                  src={brand}
                  alt={`Brand ${index + 1}`}
                  className="w-full h-auto mx-auto my-auto"
                />
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default SectionOurBrandPartners;
