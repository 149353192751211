import React, { FC } from "react";
import SectionOurServicesHeroImage from "../../images/SectionOurServices/ourServicesBackgroundImage.svg";
import image1 from "../../images/SectionOurServices/ourServices1.svg";
import image2 from "../../images/SectionOurServices/ourServices2.svg";
import image3 from "../../images/SectionOurServices/ourServices3.svg";
import image4 from "../../images/SectionOurServices/ourServices4.svg";
import image5 from "../../images/SectionOurServices/ourServices5.svg";

export interface SectionOurServicesProps {
  className?: string;
}

const SectionOurServices: FC<SectionOurServicesProps> = ({
  className = "",
}) => {
  return (
    <div
      className={`section-our-services py-4 md:py-6 w-full h-auto ${className}`}
    >
      {/* Content */}
      <div className="relative flex flex-col md:flex-row pb-6 py-4 md:py-12">
        {/* Hero Image */}
        <div className="absolute mt-6 md:mt-0 max-w-7xl w-[100%] h-auto bottom-0 left-[50%] md:left-0">
          <img
            className="w-[100%] h-[100%] object-contain lg:object-cover"
            src={SectionOurServicesHeroImage}
            alt="Our Offerings Image"
          />
        </div>

        {/* Left Section */}
        <div className="mb-8 md:mb-0 ml-2 md:ml-8 lg:ml-16 z-10">
          <h2 className="tracking-wide font-righteous font-medium text-xl md:text-3xl">
            Discover What We Provide{" "}
          </h2>
          <p className="text-sm text-black dark:text-neutral-100 font-inter">
            Elevating Gifting Excellence Together
          </p>
        </div>

        {/* Right Section */}
        <div className="flex flex-col w-[80%] mx-auto md:w-[65%] px-2 pb-20 z-10">
          {/* First Row */}
          <div className="flex flex-col gap-3 md:gap-0 md:flex-row justify-between mb-3 md:mb-6">
            <div className="flex md:flex-col lg:flex-row items-center w-full md:w-[48%] md:mb-0">
              <img
                src={image1}
                alt="Welcome/Joining Kits"
                className="w-24 md:w-28 h-auto mr-2 md:mr-0"
              />
              <div className="px-1 md:px-2">
                <h1 className="text-md md:text-lg font-semibold mb-2">
                  Welcome/Joining Kits
                </h1>
                <p className="text-xs md:text-sm">
                  A Warm Welcome with Thoughtful Gifts
                </p>
              </div>
            </div>
            <div className="flex md:flex-col lg:flex-row items-center w-full md:w-[48%] md:mb-0">
              <img
                src={image2}
                alt="Gift cards & E-vouchers"
                className="w-24 md:w-28 h-auto mr-2 md:mr-0"
              />
              <div className="px-1 md:px-2">
                <h1 className="text-md md:text-lg font-semibold mb-2">
                  Gift cards & E-vouchers
                </h1>
                <p className="text-xs md:text-sm">
                  The Gift of Choice and Convenience
                </p>
              </div>
            </div>
          </div>
          {/* Second Row */}
          <div className="flex flex-col gap-3 md:gap-0 md:flex-row justify-between mb-3 md:mb-6">
            <div className="flex md:flex-col lg:flex-row items-center w-full md:w-[48%] md:mb-0">
              <img
                src={image3}
                alt="Customize Merchandise"
                className="w-24 md:w-28 h-auto mr-2 md:mr-0"
              />
              <div className="px-1 md:px-2">
                <h1 className="text-md md:text-lg font-semibold mb-2">
                  Customize Merchandise
                </h1>
                <p className="text-xs md:text-sm">
                  Tailored Treasures for Every Occasion
                </p>
              </div>
            </div>
            <div className="flex md:flex-col lg:flex-row items-center w-full md:w-[48%] md:mb-0">
              <img
                src={image4}
                alt="Promotional & Festive Gifting"
                className="w-24 md:w-28 h-auto mr-2 md:mr-0"
              />
              <div className="px-1 md:px-2">
                <h1 className="text-md md:text-lg font-semibold mb-2">
                  Promotional & Festive Gifting
                </h1>
                <p className="text-xs md:text-sm">
                  Spread Joy with Branded Celebrations
                </p>
              </div>
            </div>
          </div>
          {/* Third Row */}
          <div className="flex flex-col gap-3 md:gap-0 md:flex-row justify-between mb-3 md:mb-6">
            <div className="flex md:flex-col lg:flex-row items-center w-full md:w-[48%] md:mb-0">
              <img
                src={image5}
                alt="Marketing Collateral"
                className="w-24 md:w-28 h-auto mr-2 md:mr-0"
              />
              <div className="px-1 md:px-2">
                <h1 className="text-md md:text-lg font-semibold mb-2">
                  Marketing Collateral
                </h1>
                <p className="text-xs md:text-sm">
                  Boost Brand Visibility with Impactful Collateral
                </p>
              </div>
            </div>
            <div className="flex md:flex-col lg:flex-row items-center w-full md:w-[48%] md:mb-0">
              <img
                src={image2}
                alt="Brand Store"
                className="w-24 md:w-28 h-auto mr-2 md:mr-0"
              />
              <div className="px-1 md:px-2">
                <h1 className="text-md md:text-lg font-semibold mb-2">
                  Brand Store
                </h1>
                <p className="text-xs md:text-sm">
                  Showcase Your Brand with Pride
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionOurServices;
