import React, { FC, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import Input from "shared/Input/Input";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { useMutation, useQuery } from "@apollo/client";
import { userTokenVerifyMutation } from "graphql/mutations/user-token-verify.mutation";
import { ErrorMessage, Field, Form, Formik, FormikHelpers } from "formik";
import { resetPasswordMutation } from "graphql/mutations/request-password-reset";
import toast from "react-hot-toast";

export interface PageLoginProps {
  className?: string;
}



function useUrlQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const inputClasses = `
      block w-full border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 
      bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900 
      disabled:bg-neutral-200 dark:disabled:bg-neutral-800 rounded-2xl text-sm font-normal h-11 px-4 py-3 `

interface Values {
  password: string;
}


const PageResetPasswordVerify: FC<PageLoginProps> = ({ className = "" }) => {
  const urlQuery = useUrlQuery();
  const navigate = useNavigate();
  const token = urlQuery.get("token")
  const [formError, setFormError] = useState("");

  const formInitValues: Values = {
    password: "",
  };

  const [resetPassword, { loading, error }] = useMutation(resetPasswordMutation)

  const handleSubmit = async (
    values: Values,
  ) => {
    // console.log(values)
    if (values.password.length < 6) {
      setFormError("Password must be at least 6 characters");
      return;
    }
    if (token === null || token === undefined || token === "") {
      setFormError("Some thing is wrong");
      return;
    }
    resetPassword({ variables: { token: token, password: values.password } })
      .then((res) => {
        // console.log(res)
        if (res.data.resetPassword.__typename === "CurrentUser") {
          toast.success("Password changed successfully");
          navigate("/");
        } else {
          toast.error("Some thing is wrong");
        }
      })

  };

  if (loading) return (
    <div className="flex justify-center items-center h-screen">
      <div className="animate-spin rounded-full h-32 w-32 border-b-2 border-primary-900"></div>
    </div>
  );

  if (error) return (
    <div className="flex justify-center items-center h-screen">
      <p className="text-red-500">{error.message}</p>
    </div>
  );


  return (
    <div className={`nc-PageLogin ${className}`} data-nc-id="PageLogin">
      <Helmet>
        <title>Login || 360CUSTOMIZER</title>
      </Helmet>
      <div className="container mb-24 lg:mb-32">
        <h2 className="my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          Change Password
        </h2>
        <div className="max-w-md mx-auto space-y-6">
          <p className="text-red-500">{formError}</p>
          <Formik initialValues={formInitValues} onSubmit={handleSubmit}>
            {({ errors, touched, isSubmitting }) => (
              <Form className="grid grid-cols-1 gap-6" >
                <label className="block">
                  <span className="text-neutral-800 dark:text-neutral-200">
                    Provide a new Password
                  </span>
                  <Field
                    id="password"
                    name="password"
                    type="password"
                    placeholder="********"
                    className={`mt-1 ${inputClasses}`}
                  />
                  <ErrorMessage name="password" component="div" errors={errors} touched={touched} className="text-red-500" />
                </label>
                <ButtonPrimary type="submit" disabled={isSubmitting}>Continue</ButtonPrimary>
              </Form>
            )}
          </Formik>
          {/* ==== */}
          <span className="block text-center text-neutral-700 dark:text-neutral-300">
            New user? {` `}
            <Link className="text-green-600" to="/signup">
              Create an account
            </Link>
          </span>
        </div>
      </div>
    </div>
  );
};

export default PageResetPasswordVerify;
