import  cheerio  from 'cheerio';
const truncateText = (text:string, maxLength:number) => {
    // Load the HTML content using cheerio
  
    const $ = cheerio.load(text);
  
    // Check if the text contains HTML tags
    if ($('body').children().length > 0) {
      // Get the text content from the HTML
      const textContent = $('body').text();
  
      // Truncate the text content
      if (textContent.length > maxLength) {
        return textContent.slice(0, maxLength) + '...';
      }
  
      return textContent;
    }
  
    // If there are no HTML tags, simply truncate the text
    if (text.length > maxLength) {
      return text.slice(0, maxLength) + '...';
    }
  
    return text;
  };

export default truncateText;