import React, { FC, useRef } from "react";
import { useInView } from "react-intersection-observer";
import NcImage from "shared/NcImage/NcImage";
import Group1 from "../../images/SectionHowItWorks/howItWorks1.svg";
import Group2 from "../../images/SectionHowItWorks/howItWorks2.svg";
import Group3 from "../../images/SectionHowItWorks/howItWorks3.svg";
import Group4 from "../../images/SectionHowItWorks/howItWorks4.svg";
import VectorImg from "images/VectorHIW.svg";
import "../../styles/SectionHowItWork.css";

export interface SectionHowItWorkProps {
  className?: string;
  data?: {
    id: number;
    img: string;
    title: string;
    desc: string;
  }[];
  title?: string;
}

const DEMO_DATA = [
  {
    id: 1,
    img: Group1,
    title: "Filter & Discover",
    desc: "Smart filtering and suggestions make it easy to find",
  },
  {
    id: 2,
    img: Group2,
    title: "Add to bag",
    desc: "Easily select the correct items and add them to the cart",
  },
  {
    id: 3,
    img: Group3,
    title: "Download Your Kit & Place Order",
    desc: "Download the catalogue in Pdf and share it with your team",
  },
  {
    id: 4,
    img: Group4,
    title: "Enjoy your Kit",
    desc: "Have fun and enjoy your 5-star quality products",
  },
];

const SectionHowItWork: FC<SectionHowItWorkProps> = ({
  className = "",
  data = DEMO_DATA,
  title = "Steps to get your product pack.",
}) => {
  const threshold = {
    mobile: 0.1,
    desktop: 0.2,
  };

  const { ref, inView } = useInView({
    threshold: threshold.mobile,
  });

  const isDesktop = window.innerWidth > 768;
  if (isDesktop) {
    threshold.mobile = threshold.desktop;
  }

  return (
    <div
      ref={ref}
      className={`nc-SectionHowItWork px-2 py-4 md:py-10 bg-[#F8F8F8] ${className}`}
    >
      <div className="ml-0 lg:ml-14 text-left">
        <h2 className="text-xl lg:text-3xl font-righteous font-medium tracking-wide ">
          How It Works?
        </h2>
        <p className="text-sm text-black dark:text-neutral-100 font-inter">
          Curating welcome kits
        </p>
      </div>

      <div className="relative lg:px-10 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-10 sm:gap-16 xl:gap-10 mt-8">
        <img
          className="hidden md:block absolute inset-x-0 top-5"
          src={VectorImg}
          alt="vector"
        />
        {data.map((item, index) => (
          <AnimatedCard
            key={item.id}
            inView={inView}
            delay={index * 0.2}
            item={item}
          />
        ))}
      </div>
    </div>
  );
};

interface AnimatedCardProps {
  inView: boolean;
  delay: number;
  item: {
    id: number;
    img: string;
    title: string;
    desc: string;
  };
}

const AnimatedCard: FC<AnimatedCardProps> = ({ inView, delay, item }) => {
  const cardRef = useRef<HTMLDivElement>(null);

  return (
    <div
      ref={cardRef}
      className={`relative flex flex-col items-center animated-card ${
        inView ? "fade-in" : "fade-out"
      }`}
      style={{ transitionDelay: `${delay}s` }}
    >
      <NcImage
        containerClassName="mb-4 sm:mb-10 max-w-[140px] mx-auto"
        className="rounded-3xl"
        src={item.img}
      />
      <div className="w-full text-center mt-auto space-y-4 px-2 m-6">
        <h3 className="text-base font-semibold font-inter">{item.title}</h3>
        <div className="bg-[#FF8A48] h-1 w-28 mx-auto mt-1"></div>
        <span className="block text-sm text-black dark:text-neutral-100 font-inter">
          {item.desc}
        </span>
      </div>
    </div>
  );
};

export default SectionHowItWork;
