import React from "react";
import siliconIndiaMagzine from "../../images/about/siliconIndia.jpg";

export interface SectionAboutOurStoryProps {
  className?: string;
}

const SectionAboutOurStory: React.FC<SectionAboutOurStoryProps> = ({
  className = "",
}) => {
  return (
    <div className={`section-about-our-story py-2 md:py-6 ${className}`}>
      <h2 className="text-2xl md:text-4xl tracking-wider font-righteous font-medium text-left">
        Our Story
      </h2>
      <p className="text-sm text-black dark:text-neutral-100 font-inter mb-6">
        Get to Know us Better
      </p>

      <div className="flex justify-center">
        <div className="w-full md:w-full">
          <div className="flex flex-col md:flex-row gap-4">
            <div className="w-full md:w-[35%] h-[70%]">
              <img
                src={siliconIndiaMagzine}
                alt="360Customizer Magezine"
                className="w-full h-full max-h-max object-cover rounded-xl mb-6"
              />
              <div className="">
                <h2 className="text-xl md:text-3xl lg:text-4xl font-medium font-righteous tracking-wider">
                  Nilesh
                </h2>
                <h2 className="text-3xl font-inter font-medium mb-4">
                  Founder, CEO
                </h2>
                <p className="text-base font-inter font-medium mb-4">
                  In the bustling heart of Pune, a revolution is underway in the
                  world of customization, led by the visionary Nilesh Ranaware
                  and his brainchild, 360CUSTOMIZER. Since our inception in
                  2018, 360CUSTOMIZER has not only redefined the concept of
                  personalized branding but has emerged as a beacon of
                  innovation and excellence in the field of customized
                  merchandise and corporate gifting solutions.
                </p>
              </div>
            </div>
            <div className="flex flex-col px-2 w-full md:w-[65%]">
              <div className="text-left">
                <h4 className="text-2xl font-inter font-medium">
                  ★ How we Grew.
                </h4>
                <p className="text-base font-inter font-medium my-2">
                  Starting from humble beginnings, Nilesh's journey from
                  door-to-door T-shirt sales to securing Fortune 500 clients,
                  from securing partnerships with major corporations to
                  establishing ourselves as a leader in the industry. Every
                  challenge was a stepping stone, every setback a setup for a
                  greater comeback. From those early days, our journey has been
                  a thrilling adventure of growth, learning, and conquering new
                  horizons. The relentless pursuit of client satisfaction and
                  quality has propelled us to the forefront of the industry.
                  <b>
                    &nbsp;And we got recognized as India's most promising
                    corporate gifting company, 360CUSTOMIZER graced the cover of
                    Silicon India Magazine.
                  </b>
                </p>

                <h4 className="text-2xl font-inter font-medium mt-6">
                  ★ Our Power & Technology-Oriented Mindset
                </h4>
                <p className="text-base font-inter font-medium my-2">
                  What sets 360CUSTOMIZER apart is its commitment to marrying
                  technology with creativity. Embracing a youthful and
                  tech-savvy approach, and in-house software for production and
                  order management exemplifies our dedication to innovation.
                  However, it's not just about technology; it's about people.
                  Nilesh emphasizes, "We work at the lowest possible margin so
                  that the customer ultimately benefits. We believe more in
                  Customer Satisfaction than our profit margins."
                </p>

                <h4 className="text-2xl font-inter font-medium mt-6">
                  ★ Our Strong and Huge Team
                </h4>
                <p className="text-base font-inter font-medium my-2">
                  360CUSTOMIZER isn't just a company; it's a family of diverse
                  talents and backgrounds united by a shared passion for
                  excellence. Their relentless pursuit of client satisfaction
                  and quality has propelled them to the forefront of the
                  industry. Recognized as India's premier corporate gifting
                  company, 360CUSTOMIZER graced the cover of Silicon India, a
                  testament to their unparalleled service and innovation.
                </p>

                <h4 className="text-2xl font-medium font-inter  mt-6">
                  ★ Future Goals
                </h4>
                <p className="text-base font-medium font-inter my-2">
                  360CUSTOMIZER envisions expanding its horizons, with plans
                  open offices new cities and reach more audience through its
                  website catering to both individual and corporate clientele.
                  Their journey is not just about growth; it's about evolution.
                  As we continue to pioneer new frontiers in customization and
                  merchandising, 360CUSTOMIZER remains steadfast in its
                  commitment to transforming customer’s visions into tangible
                  reality through creativity, dedication, and above all, a
                  passion for excellence
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionAboutOurStory;
