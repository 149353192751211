import React, { FC, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import Pagination from "shared/Pagination/Pagination";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import SectionSliderCollections from "components/SectionSliderLargeProduct";
import SectionPromo1 from "components/SectionPromo1";
import HeaderFilterSearchPage from "components/HeaderFilterSearchPage";
import Input from "shared/Input/Input";
import ButtonCircle from "shared/Button/ButtonCircle";
import ProductCard from "components/ProductCard";
import { useQuery } from "@apollo/client";
import { FacetItem, FacetsData, ProductData } from "graphql/queries/types";
import { GET_FACETS, GET_PAGINATED_PRODUCTS, searchProductQuery } from "graphql/queries/queries";
// import { PRODUCTS } from "data/data";
import { Link, useLocation, useSearchParams } from "react-router-dom";
import AccordionInfo from "./ProductDetailPage/AccordionInfo";
import { Disclosure } from "@headlessui/react";
import { MinusIcon, PlusIcon } from "@heroicons/react/24/solid";
export interface PageSearchProps {
  className?: string;
}

const PageShop: FC<PageSearchProps> = ({ className = "" }) => {
  const { data, loading, error } = useQuery<FacetsData>(GET_FACETS);

  console.log(data, "data ***")

  if (loading) return (
    <div className="flex justify-center items-center h-screen">
      <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-primary-500"></div>
    </div>
  )

  if (error) {
    return (
      <div className="flex flex-col justify-center items-center h-screen">
        <h1 className="text-2xl font-semibold text-center my-3">Something went wrong!</h1>
        <ButtonPrimary onClick={() => { window.location.reload() }}>Reload</ButtonPrimary>
      </div>
    )
  }





  return (
    <div className={`nc-PageSearch  ${className}`} data-nc-id="PageSearch">
      <Helmet>
        <title>Shop || 360CUSTOMIZER</title>
      </Helmet>





      <div className="container py-16 lg:pb-28 lg:pt-20 space-y-16 lg:space-y-28">
        <main>
          {data?.facets?.items?.map((item: FacetItem, index) => {
            if (item.values?.length === 0) return null
            return (

              <Disclosure key={index} defaultOpen={index < 2}>
                {({ open }) => (
                  <>
                    <Disclosure.Button className="flex items-center justify-between w-full px-4 py-2 mb-2 font-medium text-left bg-slate-100/80 hover:bg-slate-200/60 dark:bg-slate-800 dark:hover:bg-slate-700 rounded-lg focus:outline-none focus-visible:ring focus-visible:ring-slate-500 focus-visible:ring-opacity-75 ">
                      <span>{item.name}</span>
                      {!open ? (
                        <PlusIcon className="w-4 h-4 text-slate-600 dark:text-slate-400" />
                      ) : (
                        <MinusIcon className="w-4 h-4 text-slate-600 dark:text-slate-400" />
                      )}
                    </Disclosure.Button>
                    <Disclosure.Panel
                      className="p-4 pt-3 last:pb-0 text-slate-600 text-sm dark:text-slate-300 leading-6"
                      as="ul"
                    >
                      {
                        item?.values?.map((value) => {
                          return <li>
                            <Link
                              rel="noopener noreferrer"
                              className="font-normal text-slate-600 hover:text-black dark:text-slate-400 dark:hover:text-white"
                              to={{
                                pathname: "/page-collection",
                                search: `?filters=${value?.id}`,

                              }}
                            >

                              {value.name}
                            </Link>
                          </li>
                        })
                      }
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>


            )
          })}
        </main>

      </div>
    </div>
  );
};

export default PageShop;
