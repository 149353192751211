import React, { FC, useRef } from "react";
import NcImage from "shared/NcImage/NcImage";
import rightImgDemo from "images/promo3.png";
import backgroundLineSvg from "images/BackgroundLine.svg";
import Badge from "shared/Badge/Badge";
import Input from "shared/Input/Input";
import ButtonCircle from "shared/Button/ButtonCircle";
import { ArrowSmallRightIcon } from "@heroicons/react/24/solid";
import { useMutation } from "@apollo/client";
import ADD_EMAIL_MUTATION from "graphql/mutations/add-email";
import { toast } from "react-hot-toast";
import { Builder, withChildren } from '@builder.io/react';
import { chdir } from "process";
import { children } from "cheerio/lib/api/traversing";

export interface SectionPromo3Props {
  className?: string;
  children?: any;
  heading?: string;
  body?: string;
  keyPoint1?: string;
  keyPoint2?: string;
  keyPoint3?: string;
  keyPoint4?: string;
  image?: string;
}

const SectionPromo3: FC<SectionPromo3Props> = ({
  className = "lg:pt-10",
  children,
  heading = "Don't miss out on special offers",
  body = "Register to receive news about the latest, savings combos, discount codes...",
  keyPoint1 = "Savings combos",
  keyPoint2 = "Freeship",
  keyPoint3 = "Premium Magzines",
  keyPoint4 = "",
  image }) => {
  const [addEmailMutation, { data, error, loading }] = useMutation(ADD_EMAIL_MUTATION)

  const email = useRef<HTMLInputElement>(null)

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const emailValue = email.current?.value
    if (!emailValue) return

    addEmailMutation({ variables: { email: emailValue } })

  }

  if (loading) return (
    <div className="flex justify-center items-center h-screen">
      <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-primary-500"></div>
    </div>
  )

  if (error) {
    toast.error(error.message)

    // clear input 
  }

  // console.log(data,"data ***")

  if (!loading && data && data?.createEmailSubscriber?.id) {
    toast.success("Thank you for subscribing")
    // if email is not null clear input
    // how to check email is null or not

  }
  return (
    <div className={`nc-SectionPromo3 ${className}`}>
      <div className="relative flex flex-col lg:flex-row bg-slate-50 dark:bg-slate-800 rounded-2xl sm:rounded-[40px] p-4 pb-0 sm:p-5 sm:pb-0 lg:p-24">
        <div className="absolute inset-0">
          <img
            className="absolute w-full h-full object-contain object-bottom dark:opacity-5"
            src={backgroundLineSvg}
            alt="backgroundLineSvg"
          />
        </div>

        <div className="lg:w-[50%] max-w-lg relative">
          <h2 className="font-semibold text-4xl md:text-5xl">
            {heading}
          </h2>
          <span className="block mt-5 text-neutral-500 dark:text-neutral-400">
            {body}
          </span>
          <ul className="space-y-4 mt-10">
            {keyPoint1.length > 0 ?
              <li className="flex items-center space-x-4">
                <Badge color="purple" name="01" />
                <span className="font-medium text-neutral-700 dark:text-neutral-300">
                  {keyPoint1}
                </span>
              </li> : null}
            {keyPoint2.length > 0 ?
              <li className="flex items-center space-x-4">
                <Badge name="02" />
                <span className="font-medium text-neutral-700 dark:text-neutral-300">
                  {keyPoint2}
                </span>
              </li> : null}
            {keyPoint3.length > 0 ?
              <li className="flex items-center space-x-4">
                <Badge color="red" name="03" />
                <span className="font-medium text-neutral-700 dark:text-neutral-300">
                  {keyPoint3}
                </span>
              </li> : null}
            {keyPoint4.length > 0 ?
              <li className="flex items-center space-x-4">
                <Badge color="red" name="03" />
                <span className="font-medium text-neutral-700 dark:text-neutral-300">
                  {keyPoint4}
                </span>
              </li> : null}

          </ul>
          {children}
        </div>

        <NcImage
          containerClassName="relative block lg:absolute lg:right-0 lg:bottom-0 mt-10 lg:mt-0 max-w-lg lg:max-w-[calc(50%-40px)]"
          src={image ? image : rightImgDemo}
        />
      </div>
    </div>
  );
};

const HeroWithBuilderChildren = withChildren(SectionPromo3)

// specify defaultChildren when you register the component
Builder.registerComponent(HeroWithBuilderChildren, {
  name: 'Promo Section 3',
  // Adding defaults is important for easy usability
  inputs: [
    { name: 'heading', type: 'string' },
    { name: 'body', type: 'string' },
    { name: 'keyPoint1', type: 'string' },
    { name: 'keyPoint2', type: 'string' },
    { name: 'keyPoint3', type: 'string' },
    { name: 'keyPoint4', type: 'string' },
    { name: 'image', type: 'file', allowedFileTypes: ['jpeg', 'png', 'mp4', 'gif', 'pdf', 'svg'] },
  ],
  defaultChildren: [

  ]
})

export default SectionPromo3;
