import { gql } from "@apollo/client";
import { OrderDetails } from "graphql/queries/fragments/order-responce";

export const addItemToOrderMutation = gql`
  ${OrderDetails}
  mutation ($productId: ID!, $qty: Int!) {
    addItemToOrder(productVariantId: $productId, quantity: $qty) {
      __typename
      ...on OrderModificationError{
      errorCode
      message
    }
    ...on OrderLimitError{
      errorCode
      message
      maxItems
    }
    ...on NegativeQuantityError{
      errorCode
      message
    }
    ...on InsufficientStockError{
      errorCode
      message
      quantityAvailable
      order{
        id
    code
    lines {
      id
      linePrice
      linePriceWithTax
      quantity
      productVariant {
        id
        name
        price
        priceWithTax
        featuredAsset {
          preview
        }
        product {
          featuredAsset {
            preview
          }
        }
      }
    }
      }
      
    }
    ... OrderDetails
  }
  }
`;


