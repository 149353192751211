import React, { useEffect } from "react";
import SocialsList1 from "shared/SocialsList1/SocialsList1";
import { CustomLink } from "data/types";
import { Link } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { GET_ALL_MENU, SELLER_SOCIAL_LINKS } from "graphql/queries/queries";
import whiteLogo from "../../images/360logo/360WhiteLogo.svg";

export interface WidgetFooterMenu {
  id: string;
  title: string;
  menus?: CustomLink[];
}

export interface SocialLinks {
  activeChannel: {
    seller: {
      customFields: {
        facebook: string | null;
        twitter: string | null;
        instagram: string | null;
        telegram: string | null;
        youtube: string | null;
        linkedin: string | null;
      };
    };
  };
}

const Footer: React.FC = () => {
  const { data, error, loading } = useQuery(GET_ALL_MENU, {
    fetchPolicy: "no-cache",
  });

  const socialLinks = useQuery<SocialLinks>(SELLER_SOCIAL_LINKS);

  const [footerMenu, setFooterMenu] = React.useState<WidgetFooterMenu[]>([]);

  useEffect(() => {
    if (!loading && data) {
      // filter titles from menu items which item have isHeading true
      const titles1 = data?.navigations?.items?.filter(
        (item: any) =>
          item?.isHeading === true && item?.type === "footerSection1"
      );
      const titles2 = data?.navigations?.items?.filter(
        (item: any) =>
          item?.isHeading === true && item?.type === "footerSection2"
      );
      const titles3 = data?.navigations?.items?.filter(
        (item: any) =>
          item?.isHeading === true && item?.type === "footerSection3"
      );
      const titles4 = data?.navigations?.items?.filter(
        (item: any) =>
          item?.isHeading === true && item?.type === "footerSection4"
      );

      // filter footer menu 1 except heading which item have footerSection1 type
      const footerMenu1 = data?.navigations?.items?.filter(
        (item: any) =>
          item?.type === "footerSection1" && item?.isHeading === false
      );
      const footerMenu2 = data?.navigations?.items?.filter(
        (item: any) =>
          item?.type === "footerSection2" && item?.isHeading === false
      );
      const footerMenu3 = data?.navigations?.items?.filter(
        (item: any) =>
          item?.type === "footerSection3" && item?.isHeading === false
      );
      const footerMenu4 = data?.navigations?.items?.filter(
        (item: any) =>
          item?.type === "footerSection4" && item?.isHeading === false
      );

      // now build footer menu object  dynamically
      const footerMenuD = [
        {
          id: titles1?.length ? titles1[0]?.id : 1,
          title: titles1?.length ? titles1[0]?.title : "",
          menus: [...footerMenu1],
        },
        {
          id: titles2?.length ? titles2[0]?.id : 2,
          title: titles2?.length ? titles2[0]?.title : "",
          menus: [...footerMenu2],
        },
        {
          id: titles3?.length ? titles3[0]?.id : 3,
          title: titles3?.length ? titles3[0]?.title : "",
          menus: [...footerMenu3],
        },
        {
          id: titles4?.length ? titles4[0]?.id : 4,
          title: titles4?.length ? titles4[0]?.title : "",
          menus: [...footerMenu4],
        },
      ];

      setFooterMenu(footerMenuD);
    }
  }, [data, loading, socialLinks.data, socialLinks.loading]);

  if (loading && socialLinks.loading)
    return (
      <div className="nc-Footer relative py-20 lg:pt-28 lg:pb-10 dark:border-neutral-700">
        <div className="container grid grid-cols-2 gap-y-10 gap-x-5 sm:gap-x-8 md:grid-cols-4 lg:grid-cols-5 lg:gap-x-10">
          <div className="grid grid-cols-4 gap-5 col-span-2 md:col-span-4 lg:md:col-span-1 lg:flex lg:flex-col">
            <div className="col-span-2 md:col-span-1 animate-pulse">
              <div className="bg-gray-300 h-12 w-full rounded-md mb-4"></div>
            </div>
            <div className="col-span-2 flex items-center md:col-span-3">
              <div className="bg-gray-300 h-6 w-36 rounded-md mr-4 animate-pulse"></div>
              <div className="bg-gray-300 h-6 w-36 rounded-md animate-pulse"></div>
            </div>
          </div>
          {/* Render menu item placeholders four times */}
          {Array.from({ length: 4 }).map((_, index) => (
            <div key={index}>
              <div className="col-span-3 md:col-span-1 animate-pulse">
                <div className="bg-gray-300 h-6 w-36 rounded-md mb-4"></div>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  if (error && socialLinks.error) return <p></p>;

  const RenderWidgetMenuItem = ({
    menu,
    index,
  }: {
    menu: any;
    index: number;
  }) => {
    return (
      <>
        {menu?.title && (
          <div key={index} className="text-sm">
            <h2 className="font-semibold text-white dark:text-neutral-200">
              {menu?.title}
            </h2>
            <ul className="mt-5 space-y-4">
              {Object.keys(menu?.menus)?.length > 0
                ? menu?.menus?.map((item: any, i: number) => (
                    <li key={`${item.title}-${i}`}>
                      {item?.url?.includes(window.location.origin) ||
                      item?.url?.startsWith("/pages") ? (
                        <Link
                          className="text-white dark:text-neutral-300 hover:text-black dark:hover:text-white"
                          to={item.url}
                        >
                          {item?.title}
                        </Link>
                      ) : (
                        <a
                          className="text-white dark:text-neutral-300 hover:text-black dark:hover:text-white"
                          href={item.url}
                          rel="noopener noreferrer"
                        >
                          {item?.title}
                        </a>
                      )}
                    </li>
                  ))
                : null}
            </ul>
          </div>
        )}
      </>
    );
  };

  return (
    <footer className="nc-Footer relative py-10 md:py-16 lg:pb-10 bg-[#0169FF]">
      <div className="container">
        <div className="flex md:flex-row justify-between">
          <div>
            <img src={whiteLogo} className="block w-40 h-32 md:w-48 md:h-48" />
          </div>
          <div className="hidden md:flex justify-center">
            <div className="grid grid-cols-3 gap-4 md:gap-8 lg:gap-32">
              {footerMenu.map((item, key) => (
                <RenderWidgetMenuItem menu={item} key={key} index={key} />
              ))}
            </div>
          </div>
          <div>
            <SocialsList1 />
          </div>
        </div>
        {/* Render footer menu for mobile view */}
        <div className="md:hidden mt-4">
          <div className="grid grid-cols-2 gap-10">
            {footerMenu.map((item, key) => (
              <RenderWidgetMenuItem menu={item} key={key} index={key} />
            ))}
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
