import Label from "components/Label/Label";
import React, { FC } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Input from "shared/Input/Input";
import Select from "shared/Select/Select";
import Textarea from "shared/Textarea/Textarea";
import CommonLayout from "./CommonLayout";
import { Helmet } from "react-helmet-async";

import { useMutation, useQuery } from "@apollo/client";
import { myProfile } from "graphql/queries/queries";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from 'yup';
import { UPDATE_CUSTOMER_MUTATION } from "graphql/mutations/update-user-profile";
import { toast } from "react-hot-toast";
import useCurrentUser from "hooks/useCurrentUser";

export interface AccountPageProps {
  className?: string;
}

const AccountPage: FC<AccountPageProps> = ({ className = "" }) => {

  const {data, loading, error, refetch} = useQuery(myProfile)
  const [updateCustomerMutation,updateCustomerMutationProp] = useMutation(UPDATE_CUSTOMER_MUTATION);
  const getUser = useCurrentUser((state)=>{return{user:state.user,getUser:state.getUser, isLoggedIn:state.isUserLoggedIn}});


  if (loading || updateCustomerMutationProp.loading) return (
    <div>
      <CommonLayout>
        <div className="space-y-10 sm:space-y-12">
          {/* HEADING */}
          {/* <h2 className="text-2xl sm:text-3xl font-semibold">Account infomation</h2> */}
          <div className="flex justify-center items-center">
            <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-primary-500"></div>
          </div>
        </div>
      </CommonLayout>
    </div>
  )

  if (error || updateCustomerMutationProp.error) return (
    <div className="flex flex-col justify-center items-center h-screen">
        <h1 className="text-2xl font-semibold text-center my-3">Something went wrong!</h1>
        <ButtonPrimary onClick={()=>{window.location.reload()}}>Reload</ButtonPrimary>
      </div>
  )

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required('Required'),
    lastName: Yup.string().required('Required'),
    emailAddress: Yup.string().email('Invalid email address').required('Required'),
    phoneNumber: Yup.string()
              .matches(/^[789]\d{9}$/, "Invalid  mobile number")
              .min(10, 'Must be exactly 10 digits')
              .max(10, 'Must be exactly 10 digits')
              .required('Required'),
  });

  return (
    <div className={`nc-AccountPage ${className}`} data-nc-id="AccountPage">
      <Helmet>
        <title>Account || 360CUSTOMIZER</title>
      </Helmet>
      <CommonLayout>
        <div className="space-y-10 sm:space-y-12">
          {/* HEADING */}
          {/* <h2 className="text-2xl sm:text-3xl font-semibold">
            Account infomation
          </h2> */}
          <div className="flex flex-col md:flex-row">
            <div className="flex-shrink-0 flex items-start">
              {/* AVATAR */}
              <div className="relative rounded-full overflow-hidden flex">
                
                <div className="absolute inset-0 bg-black bg-opacity-60 flex flex-col items-center justify-center text-neutral-50 cursor-pointer">
                  <svg
                    width="30"
                    height="30"
                    viewBox="0 0 30 30"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M17.5 5H7.5C6.83696 5 6.20107 5.26339 5.73223 5.73223C5.26339 6.20107 5 6.83696 5 7.5V20M5 20V22.5C5 23.163 5.26339 23.7989 5.73223 24.2678C6.20107 24.7366 6.83696 25 7.5 25H22.5C23.163 25 23.7989 24.7366 24.2678 24.2678C24.7366 23.7989 25 23.163 25 22.5V17.5M5 20L10.7325 14.2675C11.2013 13.7988 11.8371 13.5355 12.5 13.5355C13.1629 13.5355 13.7987 13.7988 14.2675 14.2675L17.5 17.5M25 12.5V17.5M25 17.5L23.0175 15.5175C22.5487 15.0488 21.9129 14.7855 21.25 14.7855C20.5871 14.7855 19.9513 15.0488 19.4825 15.5175L17.5 17.5M17.5 17.5L20 20M22.5 5H27.5M25 2.5V7.5M17.5 10H17.5125"
                      stroke="currentColor"
                      strokeWidth={1.5}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>

                  <span className="mt-1 text-xs">Change Image</span>
                </div>
                <input
                  type="file"
                  className="absolute inset-0 opacity-0 cursor-pointer"
                />
              </div>
            </div>


    <Formik
  initialValues={{
    firstName: data?.activeCustomer?.firstName || '',
    lastName: data?.activeCustomer?.lastName || '',
    emailAddress: data?.activeCustomer?.emailAddress || '',
    phoneNumber: data?.activeCustomer?.phoneNumber || '',
  }}


  onSubmit={async (values, { setSubmitting }) => {
    try {
      const { data } = await updateCustomerMutation({
        variables: {
          input: {
            firstName: values.firstName,
            lastName: values.lastName,
            phoneNumber: values.phoneNumber,
          },
        },
      });

      // Handle success, maybe show a success message
      toast.success('Profile updated successfully');
      refetch();
      getUser.getUser()

      // You can reset form values if needed
      // resetForm();
    } catch (error) {
      // Handle error, maybe show an error message
      console.error('Error updating customer:', error);
    }

    setSubmitting(false);
  }}

  validationSchema={validationSchema}
>
  {() => (
    <Form className="flex-grow mt-10 md:mt-0 md:pl-16 max-w-3xl space-y-6">
      <div>
        <Label>First name</Label>
        <Field
          className="mt-1.5"
          name="firstName"
          as={Input} // Replace with your custom Input component
        />
        <ErrorMessage name="firstName" component="div" className="text-red-500" />
      </div>
      <div>
        <Label>Last name</Label>
        <Field
          className="mt-1.5"
          name="lastName"
          as={Input} // Replace with your custom Input component
        />
        <ErrorMessage name="lastName" component="div" className="text-red-500" />
      </div>

      <div>
        <Label>Email</Label>
        <Field
          className="mt-1.5"
          name="emailAddress"
          as={Input} // Replace with your custom Input component
          disabled
        />
        <ErrorMessage name="emailAddress" component="div" className="text-red-500" />
      </div>

      <div>
        <Label>Phone number</Label>
        <div className="mt-1.5 flex">
          <span className="inline-flex items-center px-2.5 rounded-l-2xl border border-r-0 border-neutral-200 dark:border-neutral-700 bg-neutral-50 dark:bg-neutral-800 text-neutral-500 dark:text-neutral-400 text-sm">
            <i className="text-2xl las la-phone-volume"></i>
          </span>
          <Field
            className="!rounded-l-none"
            name="phoneNumber"
            as={Input} 
          />
        </div>
        <ErrorMessage name="phoneNumber" component="div" className="text-red-500" />
      </div>

      <div className="pt-2">
        <ButtonPrimary type="submit">Update account</ButtonPrimary>
      </div>
    </Form>
  )}
</Formik>


           
            
          </div>
        </div>
      </CommonLayout>
    </div>
  );
};

export default AccountPage;
