import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment, useRef, useState } from "react";
import toast from "react-hot-toast";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import Input from "shared/Input/Input";
import NcModal from "shared/NcModal/NcModal";

const KITNAME_SIZE = 80;

const ModalKitInput = () => {
  const [isOpen, setIsOpen] = useState(false);

  const kitName = useRef<HTMLInputElement>(null);

  const closeModal = () => {
    setIsOpen(false);
  };

  const openModal = () => {
    setIsOpen(true);
  };

  const [kitNameValue, setKitNameValue] = useState(
    localStorage.getItem("kitName") || ""
  );

  const onSave = () => {
    // take kit name and save it into local storage and close the model and
    // check if the kit name is empty or not
    let value = kitNameValue;
    if (value === "") {
      // if the kit is already in the local storage then remove it

      localStorage.removeItem("kitName");
      closeModal();
      return;
    }

    if (value.length > KITNAME_SIZE) {
      value = value.slice(0, KITNAME_SIZE);
      setKitNameValue(value);
      toast.error(`Kit name should be less than ${KITNAME_SIZE}  characters`);
    }

    localStorage.setItem("kitName", value);
    closeModal();
  };
  // ?? is nullish coalescing operator
  // if the value is null or undefined then it will take the value after ??

  const triggerText = localStorage.getItem("kitName") ?? "Create Your Kit";

  const modalContent = () => {
    return (
      <div>
        <Input
          ref={kitName}
          value={kitNameValue}
          placeholder="Kit Name"
          onChange={(e) => setKitNameValue(e.target.value)}
          onKeyUp={(e) => {
            if (e.key === "Enter") {
              onSave();
            }
          }}
        />
        <div className="mt-4">
          <ButtonPrimary onClick={onSave}>Save</ButtonPrimary>
        </div>
      </div>
    );
  };
  return (
    <NcModal
      renderContent={modalContent}
      modalTitle="Name Your Kit"
      triggerText={`${triggerText}`}
      isOpenProp={isOpen}
      onCloseModal={closeModal}
      onOpenModal={openModal}
    />
  );
};

export default ModalKitInput;

interface ModalKitUpdateProps {
  orderId: string;
  kitName: string;
  isOpen: boolean;
  closeModal: () => void;
  modalTitle?: string;
  contentPaddingClass?: string;
  updateKitname: (kitName: string, orderId: string) => void;
  loading: boolean;
}

export const ModalKitUpdate: React.FC<ModalKitUpdateProps> = ({
  orderId,
  kitName,
  isOpen,
  closeModal,
  modalTitle,
  contentPaddingClass = "py-4 px-6 md:py-5",
  updateKitname,
  loading,
}) => {
  const [kitNameValue, setKitNameValue] = useState(kitName);

  const onSave = () => {
    // take kit name and make it small case and remove all the spaces and add - in between

    let name = kitNameValue.toLowerCase().split(" ").join("-");
    // check if kit name lenght is more than 25 characters
    if (name.length > KITNAME_SIZE) {
      name = name.slice(0, KITNAME_SIZE);
      toast.error(`Kit name should be less than ${KITNAME_SIZE}  characters`);
    }

    // take kit name and save it into local storage and close the model and
    updateKitname(name, orderId);
  };

  return (
    <div className="nc-NcModal">
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-50 overflow-y-auto"
          onClose={closeModal}
        >
          <div className="min-h-screen px-1 text-center md:px-4">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-75"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-75"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-neutral-900 bg-opacity-50 dark:bg-opacity-80" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-75"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-75"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div
                className={`inline-block w-full my-5 overflow-hidden text-left align-middle transition-all transform bg-white border border-black border-opacity-5 shadow-xl rounded-2xl sm:my-8 dark:bg-neutral-800 dark:border-neutral-700 text-neutral-900 dark:text-neutral-300 max-w-screen-xl`}
              >
                <div className="py-4 px-6 text-center relative border-b border-neutral-100 dark:border-neutral-700 md:py-5">
                  <ButtonClose
                    onClick={closeModal}
                    className="absolute left-2 top-1/2 transform -translate-y-1/2 sm:left-4"
                  />
                  {modalTitle && (
                    <Dialog.Title
                      as="h3"
                      className="text-base font-semibold text-neutral-900 lg:text-xl dark:text-neutral-200 mx-10"
                    >
                      {modalTitle}
                    </Dialog.Title>
                  )}
                </div>
                <div className={contentPaddingClass}>
                  <Input
                    value={kitNameValue}
                    onChange={(e) => setKitNameValue(e.target.value)}
                    onKeyUp={(e) => {
                      if (e.key === "Enter") {
                        onSave();
                      }
                    }}
                    placeholder="Kit Name"
                  />
                  <div className="mt-4">
                    <ButtonPrimary loading={loading} onClick={onSave}>
                      Update
                    </ButtonPrimary>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
};
