import client from "graphql/apploClient";
import { myProfile } from "graphql/queries/queries";
import { create } from "zustand";


interface User {
    firstName: string;
    lastName: string;
    emailAddress: string;
    phoneNumber?: string;
}

interface UserStoreState {
    user: User | null;
    isUserLoggedIn: boolean;
    isLoading: boolean;
    getUser: () => void;
}








const useCurrentUser = create<UserStoreState>((set) => {
    return {
      user: null,
      isLoading:false,
      isUserLoggedIn: false,
      getUser: async () => {
        const res = await client.query({
          query: myProfile,
          variables: {},
          fetchPolicy:"no-cache"
        })
        set({isLoading:true})
          const users = res.data; // Assuming 'activeCustomer' is a field of the 'data' object
          
          // console.log(users, "users");
  
          if (res.errors) {
            // Handle errors if needed
            set({ isUserLoggedIn: false });
            set({isLoading:false})
            return;
          }
  
          if (!users.activeCustomer) {
            // Handle case where activeCustomer is not available
            set({ isUserLoggedIn: false });
            set({isLoading:false})
            return
          }
  
          const { firstName, lastName, emailAddress, phoneNumber } = users.activeCustomer;
  
          set({ user: { firstName, lastName, emailAddress, phoneNumber } });
          set({ isUserLoggedIn: true });
          set({isLoading:false})
      },
     
    };
  });

  
  
  
  
  

export default useCurrentUser;