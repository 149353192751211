import React, { FC, useEffect, useLayoutEffect } from "react";
import * as Yup from "yup";
import { useQuery } from "@apollo/client";
import Label from "components/Label/Label";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { myAddressesQuery } from "graphql/queries/queries";
import useCurrentUser from "hooks/useCurrentUser";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import { AddOrderAddressInput } from "type";
import Input from "shared/Input/Input";
import Radio from "shared/Radio/Radio";
import Select from "shared/Select/Select";
import { get } from "http";
import Button from "shared/Button/Button";

interface Props {
  isActive: boolean;
  onCloseActive: () => void;
  onOpenActive: () => void;
  handleAddOrderAddress: (data: AddOrderAddressInput) => any;
  orderAddress: AddOrderAddressInput;
  setOrderAddress: React.Dispatch<React.SetStateAction<AddOrderAddressInput>>;
}

const sizeClass = "h-11 px-4 py-3";
const fontClass = "text-sm font-normal";
const rounded = "rounded-2xl";

const textareaStyle = `block w-full text-sm rounded-2xl border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900 `;
const inputStyle = `${sizeClass} ${fontClass} ${rounded} block w-full border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900 disabled:bg-neutral-200 dark:disabled:bg-neutral-800`;
const selectStyle =
  "nc-Select block w-full text-sm rounded-2xl border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900";

const ShippingAddress: FC<Props> = ({
  isActive,
  onCloseActive,
  onOpenActive,
  orderAddress,
  handleAddOrderAddress,
  setOrderAddress,
}) => {
  const getUser = useCurrentUser((state) => {
    return {
      user: state.user,
      getUser: state.getUser,
      isLoggedIn: state.isUserLoggedIn,
    };
  });
  const myAddress = useQuery(myAddressesQuery, { fetchPolicy: "no-cache" });
  const [showForm, setShowForm] = React.useState(true);
  const [showOtherFields, setShowOtherFields] = React.useState(showForm);

  const [initialValues, setInitialValues] = React.useState({
    firstName: "",
    lastName: "",
    streetLine1: "",
    streetLine2: "",
    city: "",
    province: "",
    postalCode: "",
    countryCode: "IN",
    phoneNumber: "",
    email: getUser?.user?.emailAddress || "",
  });

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required("First name is required"),
    lastName: Yup.string().required("Last name is required"),
    // streetLine1: Yup.string().required('Street Line 1 is required'),
    // streetLine2: Yup.string(),
    // city: Yup.string().required('City is required'),
    // province: Yup.string().required('Province is required'),
    // postalCode: Yup.string()
    //   .matches(/^[1-9][0-9]{5}$/, 'Invalid postal code')
    //   .required('Postal code is required'),
    // countryCode: Yup.string().required('Country code is required'),
    phoneNumber: Yup.string()
      .matches(/^[789]\d{9}$/, "Invalid  mobile number")
      .required("Mobile is required"),
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
  });

  const handleSubmit = async (values: any, { setSubmitting }: any) => {
    const data: AddOrderAddressInput = {
      fullName: values.firstName + " " + values?.lastName,
      streetLine1: values.streetLine1,
      streetLine2: values.streetLine2,
      city: values.city,
      province: values.province,
      postalCode: values.postalCode,
      countryCode: values.countryCode,
      phoneNumber: values?.phoneNumber?.toString(),
      email: values?.email,
    };
    handleAddOrderAddress(data);

    setSubmitting(false);
    setShowForm(!showForm);
    onCloseActive();
    onOpenActive();
  };

  useLayoutEffect(() => {
    getUser.getUser();
  }, []);

  useEffect(() => {
    if (
      myAddress.data &&
      myAddress.data?.activeCustomer?.addresses.length > 0
    ) {
      const countryCodesMap: { [key: string]: string } = {
        India: "IN",
        China: "CN",
        Canada: "CA",
        Mexico: "MX",
        Israel: "IL",
        France: "FR",
        Germany: "DE",
      };

      const address = myAddress.data?.activeCustomer?.addresses[0];
      const countryCode = countryCodesMap[address.country?.name || ""];
      const NewInitialValues = {
        firstName: address?.fullName?.split(" ")[0] || "",
        lastName: address?.fullName?.split(" ")[1] || "",
        streetLine1: address?.streetLine1 || "",
        streetLine2: address.streetLine2 || "",
        city: address.city || "",
        province: address.province || "",
        postalCode: address.postalCode || "",
        countryCode: countryCode || "IN",
        phoneNumber: address.phoneNumber || "",
        email: address?.customFields?.email || "",
      };

      setInitialValues(NewInitialValues);
    } else if (orderAddress.fullName && orderAddress.phoneNumber) {
      const countryCodesMap: { [key: string]: string } = {
        India: "IN",
        China: "CN",
        Canada: "CA",
        Mexico: "MX",
        Israel: "IL",
        France: "FR",
        Germany: "DE",
      };

      const countryCode = countryCodesMap[orderAddress.countryCode || ""];
      const addressData = {
        firstName: orderAddress?.fullName?.split(" ")[0] || "",
        lastName:
          orderAddress?.fullName?.split(" ")[2] ||
          orderAddress?.fullName?.split(" ")[1] ||
          "",
        streetLine1: orderAddress?.streetLine1 || "",
        streetLine2: orderAddress?.streetLine2 || "",
        city: orderAddress?.city || "",
        province: orderAddress?.province || "",
        postalCode: orderAddress?.postalCode || "",
        countryCode: countryCode || "IN",
        phoneNumber: orderAddress?.phoneNumber || "",
        email: orderAddress?.email || "",
      };

      setShowForm(false);
      setInitialValues(addressData);
    } else if (getUser.isLoggedIn) {
      setInitialValues({
        firstName: getUser.user?.firstName || "",
        lastName: getUser.user?.lastName || "",
        streetLine1: "",
        streetLine2: "",
        city: "",
        province: "",
        postalCode: "",
        countryCode: "IN",
        phoneNumber: getUser.user?.phoneNumber || "",
        email: getUser.user?.emailAddress || "",
      });
    }

    if (showForm === false) {
      return () => {
        // Cleanup function to be executed when the component unmounts
        setShowForm(false);
      };
    }
  }, [
    myAddress.data,
    myAddress.loading,
    orderAddress,
    getUser.user,
    getUser.isLoggedIn,
  ]);

  const handleChangeAddress = () => {
    onOpenActive();
    setShowForm(!showForm);
  };

  const handleCancelButton = () => {
    setShowForm(!showForm);
  };

  const renderShippingAddress = () => {
    return (
      <div className="border border-slate-200 dark:border-slate-700 rounded-xl ">
        <div className="p-6 flex flex-col sm:flex-row items-start">
          <span className="hidden sm:block">
            <svg
              className="w-6 h-6 text-slate-700 dark:text-slate-400 mt-0.5"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.1401 15.0701V13.11C12.1401 10.59 14.1801 8.54004 16.7101 8.54004H18.6701"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M5.62012 8.55005H7.58014C10.1001 8.55005 12.1501 10.59 12.1501 13.12V13.7701V17.25"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M7.14008 6.75L5.34009 8.55L7.14008 10.35"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M16.8601 6.75L18.6601 8.55L16.8601 10.35"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </span>

          <div className="sm:ml-8">
            <h3 className=" text-slate-700 dark:text-slate-300 flex font-inter">
              <span className="uppercase">ADDRESS</span>
              <svg
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="2.5"
                stroke="currentColor"
                className="w-5 h-5 ml-3 text-slate-900 dark:text-slate-100"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M4.5 12.75l6 6 9-13.5"
                />
              </svg>
            </h3>
            <div className=" mt-1 text-sm font-inter">
              <span className="font-inter">
                {orderAddress?.fullName && orderAddress?.phoneNumber && (
                  <span>
                    <span className="font-bold font-inter block">
                      {orderAddress?.fullName},
                    </span>
                    {orderAddress.streetLine1 && orderAddress.streetLine2 && (
                      <span>
                        {" "}
                        {orderAddress.streetLine1} {orderAddress.streetLine2}
                      </span>
                    )}
                    {orderAddress.city &&
                      orderAddress.province &&
                      orderAddress.postalCode &&
                      orderAddress.countryCode && (
                        <span>
                          {orderAddress.city}, {orderAddress.province} -{" "}
                          {orderAddress.postalCode},
                        </span>
                      )}
                    <span>
                      {orderAddress.countryCode}, Mobile:{" "}
                      {orderAddress.phoneNumber}
                    </span>
                  </span>
                )}
              </span>
            </div>
          </div>
          {showForm === false && (
            <ButtonSecondary
              sizeClass="py-2 px-4 "
              fontSize="text-sm font-medium"
              className={`bg-slate-50 dark:bg-slate-800 mt-5 sm:mt-0 sm:ml-auto !rounded-lg font-inter ${
                isActive ? "block" : "hidden"
              }`}
              onClick={handleChangeAddress}
            >
              Change
            </ButtonSecondary>
          )}
        </div>
        {showForm && (
          <div
            className={`border-t border-slate-200 dark:border-slate-700 px-6 py-7 space-y-4 sm:space-y-6 ${
              isActive ? "block" : "block"
            }`}
          >
            <Formik
              initialValues={initialValues}
              onSubmit={handleSubmit}
              validationSchema={validationSchema}
              enableReinitialize={true}
            >
              {({ isSubmitting, isValid, dirty }) => (
                <Form
                  className={`border-t border-slate-200 dark:border-slate-700 px-6 py-7 space-y-4 sm:space-y-6 font-inter`}
                >
                  <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-3">
                    <div>
                      <Label htmlFor="firstName" className="text-sm">
                        First name <span className="text-red-400">*</span>
                      </Label>
                      <Field
                        type="text"
                        name="firstName"
                        className={`mt-1.5 ${inputStyle} `}
                      />
                      <ErrorMessage
                        className="text-red-400"
                        name="firstName"
                        component="div"
                      />
                    </div>
                    <div>
                      <Label htmlFor="lastName" className="text-sm">
                        Last name <span className="text-red-400">*</span>
                      </Label>
                      <Field
                        type="text"
                        name="lastName"
                        className={`mt-1.5 ${inputStyle} `}
                      />
                      <ErrorMessage
                        className="text-red-400"
                        name="lastName"
                        component="div"
                      />
                    </div>
                  </div>

                  <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-3">
                    <div>
                      <Label htmlFor="phoneNumber" className="text-sm">
                        Mobile <span className="text-red-400">*</span>
                      </Label>
                      <Field
                        type="text"
                        name="phoneNumber"
                        className={`mt-1.5 ${inputStyle} `}
                      />
                      <ErrorMessage
                        className="text-red-400"
                        name="phoneNumber"
                        component="div"
                      />
                    </div>
                    <div>
                      <Label htmlFor="email" className="text-sm">
                        Email <span className="text-red-400">*</span>
                      </Label>
                      <Field
                        type="text"
                        name="email"
                        className={`mt-1.5 ${inputStyle} `}
                      />
                      <ErrorMessage
                        className="text-red-400"
                        name="email"
                        component="div"
                      />
                    </div>
                  </div>

                  <div>
                    <ButtonSecondary
                      sizeClass="py-2 px-4 "
                      type="button"
                      fontSize="text-sm font-medium"
                      className={`bg-slate-50 dark:bg-slate-800 mt-5 sm:mt-0 sm:ml-auto !rounded-lg ${
                        isActive ? "block" : "hidden"
                      }`}
                      onClick={() => {
                        setShowOtherFields(!showOtherFields);
                      }}
                    >
                      {showOtherFields ? "Hide" : "Add Address"}
                    </ButtonSecondary>
                  </div>

                  <div
                    className={`${
                      showOtherFields ? "block" : "hidden"
                    } ease-linear transition-all`}
                  >
                    <div className="sm:flex space-y-4 sm:space-y-0 sm:space-x-3">
                      <div className="flex-1">
                        <Label htmlFor="streetLine1" className="text-sm">
                          Address
                        </Label>
                        <Field
                          as="textarea"
                          name="streetLine1"
                          className={`mt-1.5 ${textareaStyle} `}
                          placeholder=""
                        />
                        <ErrorMessage
                          className="text-red-400"
                          name="streetLine1"
                          component="div"
                        />
                      </div>
                    </div>

                    <div className="sm:flex space-y-4 sm:space-y-0 sm:space-x-3">
                      <div className="flex-1">
                        <Label htmlFor="streetLine2" className="text-sm">
                          Address line 2
                        </Label>
                        <Field
                          as="textarea"
                          name="streetLine2"
                          className={`mt-1.5 ${textareaStyle} `}
                        />
                        <ErrorMessage
                          className="text-red-400"
                          name="streetLine2"
                          component="div"
                        />
                      </div>
                    </div>

                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-3">
                      <div>
                        <Label htmlFor="countryCode" className="text-sm">
                          Country
                        </Label>
                        <Field
                          as="select"
                          name="countryCode"
                          className={`mt-1.5 ${selectStyle}`}
                        >
                          <option value="" disabled={true}>
                            Country
                          </option>
                          <option value="IN">India</option>
                          <option value="CN">China</option>
                          <option value="CA">Canada</option>
                          <option value="MX">Mexico</option>
                          <option value="IL">Israel</option>
                          <option value="FR">France</option>
                          <option value="DE">Germany</option>
                        </Field>
                        <ErrorMessage
                          className="text-red-400"
                          name="countryCode"
                          component="div"
                        />
                      </div>

                      <div>
                        <Label htmlFor="city" className="text-sm">
                          City
                        </Label>
                        <Field
                          type="text"
                          name="city"
                          className={`mt-1.5 ${inputStyle} `}
                        />
                        <ErrorMessage
                          className="text-red-400"
                          name="city"
                          component="div"
                        />
                      </div>
                    </div>

                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-3">
                      <div>
                        <Label htmlFor="postalCode" className="text-sm">
                          Postal code
                        </Label>
                        <Field
                          type="text"
                          name="postalCode"
                          className={`mt-1.5 ${inputStyle} `}
                        />
                        <ErrorMessage
                          className="text-red-400"
                          name="postalCode"
                          component="div"
                        />
                      </div>
                      <div>
                        <Label htmlFor="province" className="text-sm">
                          State/Province
                        </Label>
                        <Field
                          type="text"
                          name="province"
                          className={`mt-1.5 ${inputStyle} `}
                        />
                        <ErrorMessage
                          className="text-red-400"
                          name="province"
                          component="div"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="flex flex-col sm:flex-row pt-6">
                    <ButtonPrimary
                      type="submit"
                      className="sm:!px-7 shadow-none"
                      disabled={isSubmitting && isValid && dirty}
                    >
                      {isSubmitting
                        ? "submiting.."
                        : getUser.isLoggedIn
                        ? "Confirm"
                        : "Add"}
                    </ButtonPrimary>
                    <ButtonSecondary
                      type="button"
                      className="mt-3 sm:mt-0 sm:ml-3"
                      onClick={handleCancelButton}
                      disabled={isSubmitting}
                    >
                      Cancel
                    </ButtonSecondary>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        )}
      </div>
    );
  };
  return renderShippingAddress();
};

export default ShippingAddress;
