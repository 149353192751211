import React, { FC, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import Pagination from "shared/Pagination/Pagination";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import SectionSliderCollections from "components/SectionSliderLargeProduct";
import SectionPromo1 from "components/SectionPromo1";
import HeaderFilterSearchPage from "components/HeaderFilterSearchPage";
import Input from "shared/Input/Input";
import ButtonCircle from "shared/Button/ButtonCircle";
import ProductCard from "components/ProductCard";
import { useQuery } from "@apollo/client";
import { ProductData } from "graphql/queries/types";
import { GET_PAGINATED_PRODUCTS, searchProductQuery } from "graphql/queries/queries";
// import { PRODUCTS } from "data/data";
import { useLocation, useSearchParams } from "react-router-dom";
export interface PageSearchProps {
  className?: string;
}

const PageSearch: FC<PageSearchProps> = ({ className = "" }) => {

  const PAGE_SIZE = 12; 


  const [currentPage, setCurrentPage] = useState(1);
  
  const [searchParams, setSearchParams]=useSearchParams()
  const searchParamTearm = searchParams.get("term")
  
  const [searchTerm, setSearchTerm] = useState(searchParamTearm||"")

  useEffect(()=>{
    if(searchParamTearm && searchTerm!==searchParamTearm){
      setSearchTerm(searchParamTearm)
    }
  },[searchParamTearm])

  

  const searchedProducts = useQuery(searchProductQuery,
    {variables:{term:searchParamTearm }, fetchPolicy:"no-cache"}
   );

  // console.log(searchedProducts.data,"searchedProducts.data ***")

   let filteredProductsIds = searchedProducts.data?.search?.items?.map((item: any) => item.id);
   filteredProductsIds = new Set(filteredProductsIds); // Convert to Set to store unique values
   filteredProductsIds = Array.from(filteredProductsIds); 

  //  console.log(filteredProductsIds,"filteredProductsIds ***")

  let productVariables =!searchParamTearm? 
  {variables:{take:PAGE_SIZE,skip:(currentPage-1)*PAGE_SIZE}}
: {variables:{take:PAGE_SIZE,skip:(currentPage-1)*PAGE_SIZE,ids:filteredProductsIds}}


  const { loading, error, data } = useQuery<ProductData>(GET_PAGINATED_PRODUCTS,{...productVariables, fetchPolicy:"no-cache"});


  // console.log(data,"data ***") 
    
  if (loading) return (
    <div className="flex justify-center items-center h-screen">
      <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-primary-500"></div>
    </div>
  )

  if (error) {
    return (
      <div className="flex flex-col justify-center items-center h-screen">
        <h1 className="text-2xl font-semibold text-center my-3">Something went wrong!</h1>
        <ButtonPrimary onClick={()=>{window.location.reload()}}>Reload</ButtonPrimary>
      </div>
    )
  }
   


  return (
    <div className={`nc-PageSearch  ${className}`} data-nc-id="PageSearch">
      <Helmet>
        <title>Search || 360CUSTOMIZER</title>
      </Helmet>

      <div
        className={`nc-HeadBackgroundCommon h-24 2xl:h-28 top-0 left-0 right-0 w-full bg-primary-50 dark:bg-neutral-800/20 `}
        data-nc-id="HeadBackgroundCommon"
      />
      <div className="container">
        <header className="max-w-2xl mx-auto -mt-10 flex flex-col lg:-mt-7">
          <form
            className="relative w-full"
            onSubmit={(e) => {
              e.preventDefault()
              // get serach term from input
              const term = e.currentTarget["search-input"].value
              // update search params
              setSearchParams({term})
              setCurrentPage(1)
            }
            }
          >
            <label
              htmlFor="search-input"
              className="text-neutral-500 dark:text-neutral-300"
            >
              <span className="sr-only">Search all icons</span>
              <Input
                className="shadow-lg border-0 dark:border"
                id="search-input"
                type="search"
                placeholder="Type your keywords"
                sizeClass="pl-14 py-5 pr-5 md:pl-16"
                rounded="rounded-full"
                name="search-input"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <ButtonCircle
                className="absolute right-2.5 top-1/2 transform -translate-y-1/2"
                size=" w-11 h-11"
                type="submit"
              >
                <i className="las la-arrow-right text-xl"></i>
              </ButtonCircle>
              <span className="absolute left-5 top-1/2 transform -translate-y-1/2 text-2xl md:left-6">
                <svg
                  className="h-5 w-5"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11.5 21C16.7467 21 21 16.7467 21 11.5C21 6.25329 16.7467 2 11.5 2C6.25329 2 2 6.25329 2 11.5C2 16.7467 6.25329 21 11.5 21Z"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M22 22L20 20"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </span>
            </label>
          </form>
        </header>
      </div>


      <div className="container py-16 lg:pb-28 lg:pt-20 space-y-16 lg:space-y-28">
        <main>
          {/* FILTER */}
          {/* <HeaderFilterSearchPage /> */}

          { !data?.products?.items.length && (
            
              <div className="flex justify-center">
                  <ButtonPrimary 
                  className="mx-auto"
                  href="/search"
                  >
                    No Products Found
                  </ButtonPrimary>
                  </div>
            
          )}

          {/* LOOP ITEMS */}
          <div className="grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-x-8 gap-y-10 mt-8 lg:mt-10">
            {data?.products?.items.map((item, index) => (
              <ProductCard data={item} key={index} />
            ))
            }
          </div>

          {/* PAGINATION */}
          <div className="">
              { data?.products?.items && (<div className="flex  justify-center mt-10">
                  <Pagination itemPerPage={PAGE_SIZE} total={data?.products.totalItems} pageSetter={setCurrentPage} currentPage={currentPage} />
                  </div>)
                }
          </div>
        </main>

      </div>
    </div>
  );
};

export default PageSearch;
