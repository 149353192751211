import React, { FC, useState } from "react";

import { Helmet } from "react-helmet-async";
import {  Link, useNavigate } from "react-router-dom";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { useMutation } from "@apollo/client";
import { userLoginMutation } from "graphql/mutations/user-login.mutation";
import { ErrorMessage, Field, Formik, FormikHelpers, Form } from "formik";
import { toast } from "react-hot-toast";
import { requestPasswordReset } from "graphql/mutations/request-password-reset";

export interface PageForgotPassProps {
  className?: string;
}

const inputClasses = `
      block w-full border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 
      bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900 
      disabled:bg-neutral-200 dark:disabled:bg-neutral-800 rounded-2xl text-sm font-normal h-11 px-4 py-3 `




interface Values {
  email: string;
}

const AccountForgotPass: FC<PageForgotPassProps> = ({ className = "" }) => {

  const navigate = useNavigate();
  const [requestReset, { error }] = useMutation(requestPasswordReset);

  const [formError, setFormError]=useState("");

  const formInitValues: Values = {
    email: "",
  };
  
  const handleSubmit = async (
    values: Values,
  ) => {
    // console.log(values)

    requestReset({ variables: {email:values.email} })
    .then((res)=>{
      // console.log(res)
      if(res.data.requestPasswordReset.__typename==="Success"){
        toast.success("Email sent successfully");
        navigate("/");
      }else{
        toast.error("Email not found");
      }
    })  
  };
  
  return (
    <div className={`nc-PageLogin ${className}`} data-nc-id="PageLogin">
      <Helmet>
        <title>Login || 360CUSTOMIZER</title>
      </Helmet>
      <div className="container mb-24 lg:mb-32">
        <h2 className="my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          Send password reset email
        </h2>
        <div className="max-w-md mx-auto space-y-6">
        <p className="text-red-500">{formError}</p>
        <Formik initialValues={formInitValues} onSubmit={handleSubmit}>
        {({ errors, touched, isSubmitting }) => (
          <Form className="grid grid-cols-1 gap-6" >
            <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                Email address
              </span>
              <Field
                id="email"
                name="email"
                type="email"
                placeholder="example@example.com"
                className={`mt-1 ${inputClasses}`}
              />
              <ErrorMessage name="email" component="div" errors={errors} touched={touched} className="text-red-500" />
            </label>
            <ButtonPrimary type="submit" disabled={isSubmitting}>Continue</ButtonPrimary>
          </Form>
           )}
          </Formik>
          {/* ==== */}
          <span className="block text-center text-neutral-700 dark:text-neutral-300">
            New user? {` `}
            <Link className="text-green-600" to="/signup">
              Create an account
            </Link>
          </span>
        </div>
      </div>
    </div>
  );
};

export default AccountForgotPass;
