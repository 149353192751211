import React from "react";
import SectionHero2 from "components/SectionHero/SectionHero2";
import SectionWhyChooseUs from "components/SectionWhyChooseUs/WhyChooseUs";
import SectionSliderProductCard from "components/SectionSliderProductCard";
import { SectionGridMoreExploreWraper } from "components/SectionGridMoreExplore/SectionGridMoreExplore";
import SectionClientSay from "components/SectionClientSay/SectionClientSay";
import SectionPromo2 from "components/SectionPromo2";
import SectionPromo3 from "components/SectionPromo3";
import SectionOurClients from "components/SectionOurClients/SectionOurClients";
import SectionHowItWork from "components/SectionHowItWork/SectionHowItWork";
import SectionKnowMore from "components/SectionKnowMore/SectionKnowMore";
import SectionStartCreatingYourPack from "components/SectionStartCreatingYourPack/SectionStartCreatingYourPack";
import SectionOurBrandPartners from "components/SectionOurBrandPartners/SectionOurBrandPartners";
import SectionOurPreCreatedPacks from "components/SectionOurPreCreatedPacks/SectionOurPreCreatedPacks";
import SectionProductCatalogue from "components/SectionProductCatalogue/SectionProductCatalogue";
import SectionOurServices from "components/SectionOurServices/SectionOurServices";
import SectionOurTestimonials from "components/SectionOurTestimonials/SectionOurTestimonials";

function PageHome() {
  return (
    <div className="nc-PageHome relative overflow-hidden">
      <div className="relative">
        <SectionHero2 />
        <SectionOurClients />
        <SectionHowItWork />
        <SectionStartCreatingYourPack />
        <SectionProductCatalogue />
        <SectionOurPreCreatedPacks />
        <SectionOurServices />
        <SectionOurBrandPartners />
        <SectionOurTestimonials />
        <SectionKnowMore />
      </div>
    </div>
  );
}

export function dummyPage() {
  return (
    <div className="nc-PageHome relative overflow-hidden">
      <SectionHero2 />
      <div className="container relative space-y-24 my-24 lg:space-y-32 lg:my-32">
        <div className="py-24 lg:py-32">
          <SectionWhyChooseUs />
        </div>
        <SectionSliderProductCard heading="Latest Products" />
        <SectionGridMoreExploreWraper />
        <div className="py-24 lg:py-32 ">
          <SectionClientSay />
        </div>
        <SectionPromo2 />
        <SectionSliderProductCard isPack={true} heading="Latest Packs" />
        <SectionPromo3 />
      </div>
    </div>
  );
}

export default PageHome;
