import React from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import backgroundEllipses from "../../images/smallBanner.svg";
import ModalKitInput from "shared/ModalKitInput/ModalKitInput";

export interface AboutApplyNowProps {
  className?: string;
  subTitle?: string;
  heading?: string;
  subHeading?: string;
}

const SmallBanner: React.FC<AboutApplyNowProps> = ({
  className = "",
  heading = "Our Product Selections",
  subTitle = "Meet Our Valued Clients: Inspiring Partnerships.",
}) => {
  return (
    <div
      className={`flex flex-col md:flex-row gap-4 w-full py-6 md:py-10 rounded-2xl ${className}`}
      style={{
        backgroundImage: `url(${backgroundEllipses})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundColor: "#0068FF",
      }}
    >
      <div className="flex-1 p-2 md:p-0 pl-4 md:pl-14 text-left md:text-left space-y-4">
        <h1 className="text-white text-3xl md:text-5xl font-righteous font-normal tracking-wider">
          {heading}
        </h1>
        <p className="text-sm md:text-base text-white dark:text-neutral-100 font-inter">
          "{subTitle}"
        </p>
        <div className="mb-2">
          <ModalKitInput />
        </div>
      </div>
    </div>
  );
};

export default SmallBanner;
