import { create } from "zustand";

interface ProductVariant {
  id: string;
  name: string;
  price: number;
  priceWithTax: number;
  featuredAsset: {
    preview: string;
  };
  product: {
    featuredAsset: {
      preview: string;
    };
  };
}

export interface OrderLine {
  id: string;
  linePrice: number;
  quantity: number;
  productVariant: ProductVariant;
}

export interface Order {
  id: string | null;
  code: string | null;
  lines: OrderLine[];
}

interface OrderStoreState {
  order: Order | null;
}

interface OrderStoreActions {
  setOrder: (newOrder: Order | null) => void;
  resetOrder: () => void;
}

const useOrderStore = create<OrderStoreState & OrderStoreActions>((set) => {
  const storedOrder = localStorage.getItem("order");

  const initialOrder = storedOrder ? JSON.parse(storedOrder) : null;

  return {
    order: initialOrder,
    setOrder: (newOrder) => {
      set({ order: newOrder });
      if (newOrder?.lines === null) {
        localStorage.removeItem("order");
      } else {
        localStorage.setItem("order", JSON.stringify(newOrder));
      }
    },
    resetOrder: () => {
      set({ order: null });
      localStorage.removeItem("order");
    },
  };
});

export default useOrderStore;
