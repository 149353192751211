import React, { FC, useState, useRef, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import heroImage from "../../images/hero/heroImage2.png";
import heroSlide from "../../images/hero/TrialBg.jpg";
import downArrow from "../../images/hero/downArrow.svg";
import backgroundLineSvg from "images/hero/backgroundEllipses.png";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import "../../styles/HomePage.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

interface Hero2DataType {
  image: string;
  heading: string;
  subHeading: string;
  btnText: string;
  btnLink: string;
}

export interface SectionHero2Props {
  className?: string;
  data?: Hero2DataType[];
}

interface CountUpProps {
  end: number;
  duration: number;
}

const DATA: Hero2DataType[] = [
  {
    image: heroImage,
    heading: "Exclusive collection for everyone",
    subHeading: "In this season, find the best 🔥",
    btnText: "Explore now",
    btnLink: "/page-collection",
  },
];

const SectionHero2: FC<SectionHero2Props> = ({
  className = "",
  data = DATA,
}) => {
  const [indexActive, setIndexActive] = useState(1);
  const sliderRef = useRef<Slider>(null);
  const interval = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    interval.current = setInterval(() => {
      sliderRef.current?.slickNext();
    }, 5500);

    return () => {
      if (interval.current) clearInterval(interval.current);
    };
  }, []);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5500,
    beforeChange: (current: number, next: number) => {
      setIndexActive(next);
    },
  };

  const CountUp: FC<CountUpProps> = ({ end, duration }) => {
    const [count, setCount] = useState<number | string>(0);
    const start = useRef(0);
    const increment = (end - start.current) / duration;

    useEffect(() => {
      const timer = setInterval(() => {
        const newCount = start.current + increment;
        if (newCount >= end) {
          clearInterval(timer);
          setCount(end.toLocaleString());
        } else {
          start.current += increment;
          setCount(Math.floor(start.current).toLocaleString());
        }
      }, 1000 / 600);

      return () => clearInterval(timer);
    }, []);

    return <div>{count}</div>;
  };

  const handleClickPrev = () => {
    sliderRef.current?.slickPrev();
  };

  const handleClickNext = () => {
    sliderRef.current?.slickNext();
  };

  return (
    <div className="h-[100%] p-2 md:px-8 md:py-2">
      <Slider className="slider-container" ref={sliderRef} {...settings}>
        {data.map((item, index) => (
          <div
            className={`rounded-3xl md:rounded-[40px] flex-col md:flex-col lg:flex-row relative overflow-hidden ${className}`}
            key={index}
          >
            <div className="absolute bottom-4 left-1/2 -translate-x-1/2 z-20 flex justify-center">
              {data.map((_, index) => {
                const isActive = indexActive === index;
                return (
                  <div
                    key={index}
                    onClick={() => {
                      setIndexActive(index);
                    }}
                    className={`relative px-1 py-1.5 cursor-pointer`}
                  >
                    <div className={`relative w-20 h-1 shadow-sm rounded-md`}>
                      {isActive && (
                        <div
                          className={`nc-SectionHero2Item__dot absolute inset-0 bg-slate-900 rounded-md ${
                            isActive ? " " : " "
                          }`}
                        ></div>
                      )}
                    </div>
                  </div>
                );
              })}
            </div>

            {/* Background Ellipses */}
            <div className="absolute inset-0 bg-[#0068ff]">
              <img
                className="absolute w-full h-full object-cover"
                src={backgroundLineSvg}
                alt="hero"
              />
            </div>

            <div className="relative min-h-[318px] md:min-h-[575px] lg:py-52 lg:pt-2">
              <div
                className={`relative z-[1] w-[65%] md:w-[55%] lg:w-[45%] ml-5 mt-8 md:ml-10 md:mt-6 lg:mt-14 lg:ml-28 space-y-4 nc-SectionHero2Item__left`}
              >
                {/* HERO SECTION HEADINGS */}
                <div className="space-y-4 md:space-y-5">
                  <h1 className="nc-SectionHero2Item__heading tracking-wide font-righteous font-medium text-3xl md:text-5xl xl:text-7xl 2xl:text-7xl !leading-[114%] text-white">
                    Exclusive kits for everyone
                  </h1>
                  <span className="nc-SectionHero2Item__subheading block text-sm md:text-xl text-white font-inter font-medium">
                    "Empower Connections, Elevate Gifts."
                  </span>
                </div>

                <div className="flex flex-nowrap space-x-6 mt-6">
                  <ButtonPrimary
                    className="bg-transparent bg-black text-white text-xs md:text-lg rounded-md"
                    sizeClass="py-2 px-3 sm:py-1 sm:px-3"
                    href={item.btnLink as any}
                  >
                    Shop Now
                  </ButtonPrimary>

                  <ButtonPrimary
                    className="bg-transparent border border-white text-white text-xs md:text-lg rounded-md"
                    sizeClass="ml-6 py-2 px-3 sm:py-2 sm:px-6"
                    href={item.btnLink as any}
                  >
                    Book A Demo
                  </ButtonPrimary>
                </div>
              </div>
              <div className="hidden absolute lg:flex flex-row gap-4 bottom-10 left-[7.5rem] cursor-pointer">
                <img src={downArrow} />
                <p className="text-white font-inter font-light">Scroll Down</p>
              </div>

              {/* CountUp component */}
              <div className="absolute w-[85%] lg:w-[70%] h-auto md:h-[24%] bottom-[-1%] right-[-1%] lg:right-[-40px] z-20 p-4 bg-white rounded-lg">
                <div className="flex flex-row lg:flex-row items-center justify-evenly w-full h-full">
                  <div className="flex flex-col flex-1 lg:flex-none w-full md:w-full lg:w-1/3 pr-4 lg:pr-0 pl-0 lg:pl-4 lg:relative">
                    <div className="text-black font-bold text-lg md:text-3xl lg:text-5xl relative">
                      <CountUp end={1000000} duration={400} />
                      <div className="absolute top-[-70%] right-[-18%] md:right-[-1rem] lg:right-2 mr-2 mt-2 lg:mt-1 lg:text-sm text-xs text-black">
                        <FontAwesomeIcon icon={faPlus} />
                      </div>
                    </div>
                    <div className="text-[#0068ff] text-xs lg:text-base md:text-lg sm:text-sm lg:hidden whitespace-nowrap font-inter">
                      Products Sold
                    </div>
                    <div className="text-[#0068ff] hidden lg:block font-inter">
                      Products Sold
                    </div>
                  </div>
                  <div className="relative h-[2rem] md:h-[4rem] w-[1px] md:w-[3px] bg-black mx-auto"></div>

                  <div className="pl-6 flex flex-col lg:flex-col flex-1 w-full lg:w-1/3 lg:pl-12 relative">
                    <div className="text-black font-bold text-lg md:text-3xl md:w-1/3 lg:text-5xl relative">
                      <CountUp end={500} duration={400} />
                      <div className="absolute top-[-70%] right-[25%] md:right-[-2rem] lg:right-[-4rem] mr-2 mt-2 lg:mt-1 lg:text-sm text-xs text-black">
                        <FontAwesomeIcon icon={faPlus} />
                      </div>
                    </div>
                    <div className="text-[#0068ff] text-xs lg:text-base md:text-lg sm:text-sm lg:hidden font-inter">
                      Clients
                    </div>
                    <div className="text-[#0068ff] hidden lg:block font-inter">
                      Clients
                    </div>
                  </div>
                  <div className="relative h-[2rem] md:h-[4rem] w-[1px] md:w-[3px] bg-black mx-auto"></div>

                  <div className="pl-6 flex flex-col lg:flex-col flex-1 w-full lg:w-1/3 lg:pl-12 relative">
                    <div className="text-black font-bold text-lg md:text-3xl md:w-1/3 lg:text-5xl relative">
                      <CountUp end={400} duration={400} />
                      <div className="absolute top-[-70%] right-[25%] md:right-[-2rem] lg:right-[-4rem] mr-2 mt-2 lg:mt-1 lg:text-sm text-xs text-black">
                        <FontAwesomeIcon icon={faPlus} />
                      </div>
                    </div>
                    <div className="text-[#0068ff] text-xs lg:text-base md:text-lg sm:text-sm lg:hidden font-inter">
                      Brands
                    </div>
                    <div className="text-[#0068ff] hidden lg:block font-inter">
                      Brands
                    </div>
                  </div>
                  <div className="hidden lg:flex flex-col items-center justify-around w-44 h-24">
                    <button
                      className="cursor-pointer h-8 w-8 bg-black hover:bg-[#FF8A48] text-white rounded-full flex justify-center items-center"
                      onClick={handleClickPrev}
                    >
                      &lt;
                    </button>
                    <button
                      className="cursor-pointer h-8 w-8 bg-black hover:bg-[#FF8A48] text-white rounded-full flex justify-center items-center mt-1"
                      onClick={handleClickNext}
                    >
                      &gt;
                    </button>
                  </div>
                </div>
              </div>

              {/* Hero image */}
              <div className="hero_image-wrapper md:absolute md:mt-0 lg:mt-0 right-0 md:right-[-5rem] lg:right-20 bottom-[-4rem] md:bottom-0 top-0 w-full max-w-5xl xl:max-w-5xl 2xl:max-w-5xl">
                <img
                  className="hidden md:block w-full h-full object-contain object-right-bottom nc-SectionHero2Item__image"
                  src={heroImage}
                  alt={item.heading}
                />
              </div>
            </div>
          </div>
        ))}
        {data.map((item, index) => (
          <div
            className={`nc-SectionHero2Item nc-SectionHero2Item--animation relative rounded-3xl md:rounded-[40px] flex flex-col-reverse lg:flex-col overflow-hidden min-h-[350px] w-full bg-[#0068ff] {className}`}
            key={index}
          >
            {/* Background Image */}
            <div className="relative pt-[10rem] pb-[11rem] md:p-72">
              <img
                className="absolute inset-0 w-full min-h-[350px] md:min-h-[575px] object-cover"
                src={heroSlide}
                alt="hero"
              />
            </div>

            <div
              className={`absolute w-[65%] md:w-[55%] lg:w-[45%] top-8 md:top-14 lg:mt-14 left-5 md:left-10 lg:left-24 z-[1] space-y-4 md:space-y-8 nc-SectionHero2Item__left`}
            >
              {/* HERO SECTION HEADINGS */}
              <div className="space-y-5 sm:space-y-3">
                <h1 className="nc-SectionHero2Item__heading tracking-wide font-righteous font-medium text-3xl sm:text-4xl md:text-5xl xl:text-7xl 2xl:text-7xl !leading-[114%] text-white">
                  Exclusive kits for everyone
                </h1>
                <span className="nc-SectionHero2Item__subheading block text-sm md:text-xl text-white font-medium font-inter">
                  "Empower Connections, Elevate Gifts."
                </span>
              </div>

              <div className="flex flex-nowrap space-x-6 mt-6">
                <ButtonPrimary
                  className="bg-transparent bg-black text-white text-xs md:text-lg rounded-md"
                  sizeClass="py-2 px-3 sm:py-2 sm:px-6"
                  href={item.btnLink as any}
                >
                  Shop Now
                </ButtonPrimary>

                <ButtonPrimary
                  className="bg-transparent border border-white text-white text-xs md:text-lg rounded-md"
                  sizeClass="py-2 px-3 sm:py-2 sm:px-6"
                  href={item.btnLink as any}
                >
                  Book A Demo
                </ButtonPrimary>
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default SectionHero2;
