import React, { FC, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import Input from "shared/Input/Input";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { useMutation, useQuery } from "@apollo/client";
import { userTokenVerifyMutation } from "graphql/mutations/user-token-verify.mutation";
import { ErrorMessage, Field, Form, Formik, FormikHelpers } from "formik";

import { me, myCartQuery } from "graphql/queries/queries";
import ButtonSecondary from "shared/Button/ButtonSecondary";
export interface PageLoginProps {
  className?: string;
}

interface Values {
  token: string;
}

function useUrlQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}


const PageTokenVerify: FC<PageLoginProps> = ({ className = "" }) => {
  const urlQuery= useUrlQuery();
  const navigate = useNavigate();
  const token=urlQuery.get("token")

  const [verify, { error,loading,data, }] = useMutation(userTokenVerifyMutation,{fetchPolicy:"no-cache"});

  useEffect(()=>{
    verify({ variables: { token: token } });
  },[])

  // console.log(data,"data")

  if(loading) return (
    <div className="flex justify-center items-center h-screen">
      <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-primary"></div>
    </div>
  )

  if (error) {
    return (
      <div className="flex flex-col justify-center items-center h-screen">
        <h1 className="text-2xl font-semibold text-center my-3">Something went wrong!</h1>
        <ButtonPrimary
          onClick={() => window.location.reload()}
          className="btn btn-primary mt-5"
        >
          Try again
        </ButtonPrimary>
      </div>
    )
  }

  // if(!loading &&  data?.verifyCustomerAccount?.__typename === "CurrentUser") {
  //   navigate("/login")
  // }

  if(!loading && data?.verifyCustomerAccount?.__typename === "VerificationTokenInvalidError") return (
    <div className="flex flex-col justify-center items-center h-screen">
      <h1 className="my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">Token Invalid</h1>

      <div className="flex gap-2">

      <ButtonPrimary
        onClick={() => window.location.reload()}
        >
        Try again
      </ButtonPrimary>
      <ButtonSecondary
        href="/"
        >
        Go to Home
      </ButtonSecondary>

      </div>
    </div>
  )


 





 
  
 
  return (
    <div className={`nc-PageLogin ${className}`} data-nc-id="PageLogin">
      <Helmet>
        <title>Verify || 360CUSTOMIZER</title>
      </Helmet>
      <div className="container mb-24 lg:mb-32">
        <h2 className="my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          Verify Account
        </h2>
        <div className=" flex justify-center flex-col  items-center max-w-md mx-auto space-y-6">
            <h2 className="text-lg text-lime-500"> Your account has been verified successfully</h2>
            
            <ButtonPrimary href="/login">Login</ButtonPrimary>
       
        </div>
      </div>
    </div>
  );
};

export default PageTokenVerify;
