import React, { useEffect } from "react";
import MyRouter from "routers/index";
import { Helmet, HelmetProvider } from "react-helmet-async";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  gql,
  useQuery,
} from "@apollo/client";
import client from "graphql/apploClient";
import { Orders } from "graphql/queries/queries";
import useOrderStore from "hooks/useCart";
import useCurrentUser from "hooks/useCurrentUser";

function App() {
  const orderStore = useOrderStore((state) => {
    return {
      setOrder: state.setOrder,
      order: state.order,
    };
  });

  const result = client.query({
    query: Orders,
    variables: {},
  });

  useEffect(() => {
    result.then((res) => {
      orderStore.setOrder({
        code: res?.data?.activeOrder?.code,
        id: res?.data?.activeOrder?.id,
        lines: res?.data?.activeOrder?.lines,
      });
    });
  }, []);

  useEffect(() => {
    if (localStorage.theme === "dark") {
      toDark();
    } else {
      toLight();
    }
  }, []);

  const toDark = () => {
    const root = document.querySelector("html");
    if (!root) return;
    !root.classList.contains("dark") && root.classList.add("dark");
    localStorage.theme = "dark";
  };

  const toLight = () => {
    const root = document.querySelector("html");
    if (!root) return;
    root.classList.remove("dark");
    localStorage.theme = "light";
  };

  return (
    <HelmetProvider>
      <Helmet>
        <title>360CUSTOMIZER</title>
        <meta
          name="description"
          content="content=Best custom printed products for gifting and corporate needs. Custom printed Tshirts, Uniforms, Gadgets etc."
        />
      </Helmet>

      {/* MAIN APP */}
      <div className="bg-white text-base dark:bg-slate-900 text-slate-900 dark:text-slate-200">
        <ApolloProvider client={client}>
          <MyRouter />
        </ApolloProvider>
      </div>
    </HelmetProvider>
  );
}

export default App;
