export default function formatCurrencyINR(number: number): string {
  const formatter = new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
    minimumFractionDigits: 2,
  });

  const num = number / 100;

  const formattedCurrency = formatter.format(num);

  return formattedCurrency;
}
