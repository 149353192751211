import React from 'react';

interface ProductDescriptionProps {
  description?: string;
  line?: number;
  className?: string;
}

function Description({ description,line,className }: ProductDescriptionProps) {


  let TRUNCATE_LENGTH = 40;
  if(line){
    TRUNCATE_LENGTH = line;
  }



  // Handle the case where description is undefined
  const desc = description ?? '';

  return (
    <div>
      {desc.length > TRUNCATE_LENGTH ? (
        <p className={`${className}`}>{desc.slice(0, TRUNCATE_LENGTH)}...</p>
      ) : (
        <p className={`${className}`}>{desc}</p>
      )}
    </div>
  );
}

export default Description;
