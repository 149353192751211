import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ScrollToTop from "./ScrollToTop";
import Footer from "shared/Footer/Footer";
import PageHome from "containers/PageHome/PageHome";
import AccountPage from "containers/AccountPage/AccountPage";
import PageSignUp from "containers/PageSignUp/PageSignUp";
import PageLogin from "containers/PageLogin/PageLogin";
import SiteHeader from "containers/SiteHeader";
import PageSearch from "containers/PageSearch";
import ProductDetailPage from "containers/ProductDetailPage/ProductDetailPage";
import AccountPass from "containers/AccountPage/AccountPass";
import AccountOrder from "containers/AccountPage/AccountOrder";
import CartPage from "containers/ProductDetailPage/CartPage";
import CheckoutPage from "containers/PageCheckout/CheckoutPage";
import PageCollection2 from "containers/PageCollection2";
import { Toaster } from "react-hot-toast";
import PageTokenVerify from "containers/PageTokenVerify/PageTokenVaerify";
import AccountOrderDetail from "containers/AccountPage/AccountOrderDetail";
import AccountForgotPass from "containers/AccountPage/AccountForgotPass";
import PageResetPasswordVerify from "containers/PageTokenVerify/PageResetPassword";
import ProductPacks from "containers/ProductPacks/ProductPacks";
import useCurrentUser from "hooks/useCurrentUser";
import CatchAllRoute from "./builder";
import { builder } from "@builder.io/react";
import PageShop from "containers/PageShop";
import PageAbout from "containers/PageAbout/PageAbout";
import PageContact from "containers/PageContact/PageContact";
import PageCareers from "containers/PageCareers/PageCareers";

// Put your API key here
builder.init("17a838b7c1b944799e239066672300ff");

const MyRoutes = () => {
  const getUser = useCurrentUser((state) => {
    return {
      user: state.user,
      getUser: state.getUser,
      isLoggedIn: state.isUserLoggedIn,
    };
  });

  React.useEffect(() => {
    getUser.getUser();
  }, []);

  return (
    <BrowserRouter>
      <Toaster />
      <ScrollToTop />
      <SiteHeader />
      <Routes>
        {/* {pages.map(({ component: Component, path }, index) => {
          return <Route key={index} element={<Component />} path={path} />;
        })} */}
        <Route element={<PageCollection2 />} path="/page-collection" />
        <Route element={<PageCollection2 />} path="/product" />
        <Route path="/product">
          <Route element={<ProductDetailPage />} path=":slug" />
        </Route>
        <Route element={<ProductPacks />} path="/product-packs" />

        <Route element={<PageSearch />} path="/search" />

        <Route element={<PageShop />} path="/shop" />

        <Route element={<PageHome />} path="/" />

        <Route element={<PageLogin />} path="/login" />
        <Route element={<PageAbout />} path="/about-us" />
        <Route element={<PageContact />} path="/pages/contact-us" />
        <Route element={<PageCareers />} path="/pages/career" />

        <Route element={<PageSignUp />} path="/signup" />
        <Route element={<PageTokenVerify />} path="/verify" />
        <Route element={<CartPage />} path="cart" />
        <Route element={<CheckoutPage />} path="checkout" />
        <Route element={<AccountForgotPass />} path="forgot-pass" />
        <Route element={<PageResetPasswordVerify />} path="password-reset" />

        {/* protected routes how to protect   */}

        <Route element={<AccountPage />} path="account" />
        <Route element={<AccountOrder />} path="account-my-order" />
        <Route element={<AccountOrderDetail />} path="account-my-order/:id" />

        <Route element={<AccountPass />} path="account-change-password" />
        <Route element={<CatchAllRoute />} path="*" />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
};

export default MyRoutes;
