import { gql } from "@apollo/client";
import { OrderDetails } from "graphql/queries/fragments/order-responce";

export const removeItemFromOrderMutation = gql`
  ${OrderDetails}
  mutation ($id: ID!) {
    removeOrderLine(orderLineId: $id) {
      ... on ErrorResult {
        errorCode
        message
      }
      ...OrderDetails
    }
  }
`;
