import React, { FC, useEffect, useState } from "react";
import {
  GET_FACETS,
  GET_PAGINATED_PRODUCTS,
  searchProductQuery,
} from "graphql/queries/queries";
import {
  FacetValue,
  FacetsData,
  Product,
  ProductData,
} from "graphql/queries/types";
import { useSearchParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { useQuery } from "@apollo/client";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonCircle from "shared/Button/ButtonCircle";
import ProductCard from "components/ProductCard";
import SidebarFilters from "./SidebarFilters";
import SmallBanner from "shared/SmallBanner/SmallBanner";
import Select from "shared/Select/Select";

let filterData: string[] = [];
export interface PageCollection2Props {
  className?: string;
}
let count = 1;
const PageCollection2: FC<PageCollection2Props> = ({ className = "" }) => {
  const [isProducts, setIsProducts] = useState(true);

  const [searchParams, setSearchParams] = useSearchParams();
  const searchParamsIds = searchParams.getAll("filters");
  const sortParamsPrice = searchParams.get("price");

  filterData = searchParamsIds;

  const searchedProducts = useQuery(searchProductQuery, {
    variables: { ids: searchParamsIds },
  });

  let filteredProductsIds = searchedProducts.data?.search?.items?.map(
    (item: any) => item.id
  );
  filteredProductsIds = new Set(filteredProductsIds); // Convert to Set to store unique values
  filteredProductsIds = Array.from(filteredProductsIds);

  const PAGE_SIZE = 18;

  const [currentPage, setCurrentPage] = useState(1);
  const [loadingMore, setLoadingMore] = useState(false);

  const [products, setProducts] = useState<Product[]>([]);

  let productVariables =
    searchParamsIds.length >= 1 || sortParamsPrice
      ? {
          variables: {
            take: PAGE_SIZE,
            ids: filteredProductsIds,
            isPack: false,
          },
        }
      : { variables: { take: PAGE_SIZE, isPack: false } };

  const { loading, error, data, fetchMore } = useQuery<ProductData>(
    GET_PAGINATED_PRODUCTS,
    {
      ...productVariables,
      fetchPolicy: "cache-and-network", // Use cache and fetch from the network
      nextFetchPolicy: "cache-first", // Use cache for subsequent fetches
    }
  );

  const facetData = useQuery<FacetsData>(GET_FACETS);

  const fetchMoreProducts = async () => {
    setLoadingMore(true);

    await fetchMore({
      variables: {
        skip: currentPage * PAGE_SIZE,
        take: PAGE_SIZE,
      },

      updateQuery: (prev, { fetchMoreResult }) => {
        setLoadingMore(false);
        if (!fetchMoreResult) return prev;
        return {
          products: {
            ...prev.products,
            items: [...prev.products.items, ...fetchMoreResult.products.items],
          },
        };
      },
    });

    setCurrentPage(currentPage + 1);
  };

  useEffect(() => {
    if (!loading && data) {
      setProducts([...data.products.items]);
      setIsProducts(true);

      if (data?.products?.items?.length < 1) {
        setIsProducts(false);
      }
      let isItems = 0;
      data.products.items.forEach((item) => {
        if (item.variantList.items.length > 0) {
          isItems++;
        }
      });

      if (isItems < 1) {
        setIsProducts(false);
      }
    }
  }, [data, loading]);

  useEffect(() => {
    // Calculate the threshold as 60% of the page height
    const pageHeight = document.documentElement.scrollHeight;
    const threshold = 0.9 * pageHeight;

    // Add an event listener to detect scrolling to the threshold
    const handleScroll = () => {
      if (
        data?.products?.totalItems &&
        data?.products?.totalItems <= products?.length
      ) {
        return;
      }
      if (
        window.innerHeight + document.documentElement.scrollTop >= threshold &&
        !loadingMore
      ) {
        fetchMoreProducts();
      }
    };

    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [loadingMore, currentPage]);

  const handleFilterPageData = (data: FacetValue, action: "add" | "remove") => {
    if (action === "add") {
      filterData.push(data.id);
    } else {
      filterData = filterData.filter((item) => item !== data.id);
    }

    setSearchParams({ filters: [...filterData] });
    setCurrentPage(1);
  };

  const clearFilters = () => {
    setSearchParams({});
  };

  if (loading)
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-primary-500"></div>
      </div>
    );

  if (error) {
    return (
      <div className="flex flex-col justify-center items-center h-screen">
        <h1 className="text-2xl font-semibold text-center my-3">
          Something went wrong!
        </h1>
        <ButtonPrimary
          onClick={() => {
            window.location.reload();
          }}
        >
          Reload
        </ButtonPrimary>
      </div>
    );
  }

  return (
    <div
      className={`nc-PageCollection2 ${className}`}
      data-nc-id="PageCollection2"
    >
      <Helmet>
        <title>Products || 360CUSTOMIZER</title>
      </Helmet>

      <div className="container pt-6 py-16 lg:pb-28 lg:pt-8 space-y-16 sm:space-y-20 lg:space-y-14">
        <SmallBanner />
        <div className="space-y-10 lg:space-y-0">
          <div className="max-w-screen-sm">
            <ScrollToTopButton />
          </div>

          <main>
            {/* sort by price ASC or DSC */}
            <div className="flex items-center justify-between gap-2 mb-4">
              <h1 className="text-3xl font-righteous font-medium mb-2 tracking-wide">
                Categories
              </h1>

              <div className="flex space-x-2 w-56">
                <Select
                  className="max-w-sm"
                  value={
                    searchParams.get("price")
                      ? `Sort by: ${
                          searchParams.get("price") === "ASC" ? "Low" : "High"
                        }`
                      : ""
                  }
                  onChange={(e) => {
                    const selectedValue = e.target.value;
                    if (selectedValue === "ASC") {
                      setSearchParams({ price: "ASC" });
                    } else if (selectedValue === "DESC") {
                      setSearchParams({ price: "DESC" });
                    } else {
                      if (searchParams.get("price")) setSearchParams({});
                    }
                  }}
                >
                  <option value="">
                    Sort by :{" "}
                    {searchParams.get("price")
                      ? searchParams.get("price") === "ASC"
                        ? "Low"
                        : "High"
                      : "Recommended"}
                  </option>
                  <option value="">Recommended</option>
                  <option value="ASC">Low</option>
                  <option value="DESC">High</option>
                </Select>
              </div>
            </div>

            {/* LOOP ITEMS */}
            <div className="flex flex-col lg:flex-row">
              <div className=" lg:w-1/5 xl:w-1/5 pr-4`">
                <div className="sticky top-28 lg:pb-28 lg:h-screen overflow-auto hide-scroll-bar ">
                  <SidebarFilters
                    clearFilter={clearFilters}
                    filterData={filterData}
                    handleFilter={handleFilterPageData}
                    facets={facetData.data}
                  />
                </div>
              </div>
              <div className="flex-shrink-0 mb-10 lg:mb-0 lg:mx-4 lg:border-t-0">
                <div className="h-full border-r border-[#9B9595]"></div>
              </div>
              <div className="flex-1">
                {isProducts ? (
                  <div className="flex-1 grid grid-cols-2 sm:grid-cols-3 xl:grid-cols-4 gap-x-6 lg:gap-x-4 gap-y-10">
                    {products.map(
                      (item, index) =>
                        item?.variantList?.items?.length > 0 && (
                          <ProductCard
                            data={item}
                            key={`${index}-${item?.slug}`}
                          />
                        )
                    )}
                  </div>
                ) : (
                  <div className="flex justify-center">
                    <ButtonPrimary className="mx-auto" href="/page-collection">
                      No Products Found
                    </ButtonPrimary>
                  </div>
                )}

                {isProducts &&
                  data?.products?.totalItems &&
                  data?.products?.totalItems > products?.length &&
                  loadingMore && (
                    <div className="flex  justify-center mt-10">
                      <ButtonPrimary loading={loadingMore}>
                        Loading...
                      </ButtonPrimary>
                    </div>
                  )}
              </div>
            </div>
          </main>
        </div>
      </div>
    </div>
  );
};

export default PageCollection2;

function ScrollToTopButton() {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    // Function to handle scroll event
    const handleScroll = () => {
      // Check the scroll position
      if (document.documentElement.scrollTop > 100) {
        // If the user has scrolled down at least 100 pixels, show the button
        setIsVisible(true);
      } else {
        // If the user is at the top of the page, hide the button
        setIsVisible(false);
      }
    };

    // Add scroll event listener
    window.addEventListener("scroll", handleScroll);

    // Remove the event listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <ButtonCircle
      size="w-10 h-10 md:w-16 md:h-16 "
      className={`fixed z-50 bottom-4 dark:bg-slate-100 shadow-lg right-4 md:bottom-20 md:right-20  transition-opacity duration-300 ${
        isVisible ? "opacity-100" : "opacity-0 pointer-events-none"
      }`}
      onClick={scrollToTop}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth="1.5"
        stroke="currentColor"
        className="w-6 h-6 md:w-8 md:h-8 dark:text-slate-800 text-slate-100 "
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M15 11.25l-3-3m0 0l-3 3m3-3v7.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
        />
      </svg>
    </ButtonCircle>
  );
}
