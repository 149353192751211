import React from "react";
import Image1 from "../../images/about/VectorImage1.png";
import Image2 from "../../images/about/VectorImage2.png";
import Image3 from "../../images/about/VectorImage3.png";
import "../../styles/PageAbout.css";

export interface AboutSectionHowItWorksProps {
  className?: string;
}

const AboutSectionHowItWorks: React.FC<AboutSectionHowItWorksProps> = ({
  className = "",
}) => {
  const cardsData = [
    {
      id: 1,
      image: Image1,
      title: "Our Vision",
      info: "Becoming the world's leading choice for customized merchandise, empowering individuals and businesses to express their unique identities proudly",
    },
    {
      id: 2,
      image: Image2,
      title: "Our Values",
      info: "- Customer focus. - Creativity and innovation. - Quality and attention to detail. - Collaboration and teamwork. - Accountability and responsibility",
    },
    {
      id: 3,
      image: Image3,
      title: "Our Mission",
      info: "Empowering self-expression through personalized merchandise, making customization accessible to everyone, everywhere",
    },
  ];

  return (
    <div className={`about-section-how-it-works py-2 md:py-6 ${className}`}>
      <h2 className="text-2xl md:text-4xl tracking-wider font-righteous font-medium text-left">
        Our Purpose and Promise
      </h2>
      <p className="text-sm text-black dark:text-neutral-100 font-inter mb-6">
        Our Values and Actions
      </p>
      <div className="flex flex-col items-center">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 w-full">
          {cardsData.map((card) => (
            <div
              key={card.id}
              className="Card relative py-4 px-4 w-full bg-white rounded-xl overflow-hidden hover:text-white hover:bg-[#0068FF] transition duration-300 flex flex-col justify-center items-center"
            >
              <div className="overflow-hidden m-4">
                <img src={card.image} alt={card.title} />
              </div>
              <div className="p-4 text-center">
                <h3 className="text-xl font-semibold font-inter mb-4">
                  {card.title}
                </h3>
                {card.id === 2 ? (
                  card.info
                    .split("-")
                    .filter((point) => point.trim() !== "")
                    .map((point, index) => (
                      <p key={index} className="text-medium font-inter">
                        - {point.trim()}
                      </p>
                    ))
                ) : (
                  <p className="text-medium font-inter">{card.info}</p>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AboutSectionHowItWorks;
