import { gql } from "@apollo/client";


export const updateUserPasswordMutation = gql`
  mutation($currentPassword: String!, $newPassword: String!) {
    updateCustomerPassword(currentPassword: $currentPassword, newPassword: $newPassword) {
      ... on Success {
        __typename
      }
      ...on InvalidCredentialsError{
        errorCode
        message
      }
      ...on PasswordValidationError{
        message
        errorCode
      }
    }
  }
`;