import React, { FC, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { addItemToOrderMutation } from "graphql/mutations/add-item-to-order";
import { useMutation } from "@apollo/client";
import { Product } from "graphql/queries/types";
import NotifyAddTocart from "./NotifyAddTocart";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import NcImage from "shared/NcImage/NcImage";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ProductStatus from "./ProductStatus";
import toast from "react-hot-toast";
import BagIcon from "./BagIcon";
import Select from "shared/Select/Select";
import useOrderStore from "hooks/useCart";
import ProductDescription from "./ProductDescription";
import truncateText from "utils/truncateText";
import NcInputNumber from "./NcInputNumber";
import formatCurrencyINR from "utils/convertNumbToCurrency";
// import visibility from "images/products/visibility.png";
// import { Transition } from "@headlessui/react";
// import ModalQuickView from "./ModalQuickView";
// import Description from "./ProductDescription";
// import {
//   ArrowsPointingOutIcon,
//   BugAntIcon,
//   PlusIcon,
// } from "@heroicons/react/24/outline";
// import Button from "shared/Button/Button";
// import LikeButton from "./LikeButton";
// import { StarIcon } from "@heroicons/react/24/solid";
// import ButtonSecondary from "shared/Button/ButtonSecondary";
// import ButtonClose from "shared/ButtonClose/ButtonClose";
// import ButtonCircle from "shared/Button/ButtonCircle";

export interface ProductCardProps {
  className?: string;
  data?: Product;
  isLiked?: boolean;
}

const ProductCard: FC<ProductCardProps> = ({
  className = "",
  data,
  isLiked,
}) => {
  const [addItemToOrder, addItemToOrderProp] = useMutation(
    addItemToOrderMutation
  );

  const orderStore = useOrderStore((state) => {
    return {
      setOrder: state.setOrder,
      order: state.order,
    };
  });

  const [product, setProduct] = useState({
    id: data?.variantList?.items[0]?.id as string,
    name: data?.variantList?.items[0]?.name as string,
    price: data?.variantList?.items[0]?.price as number,
    priceWithTax: data?.variantList?.items[0]?.priceWithTax as number,
    stockLevel: data?.variantList?.items[0]?.stockLevel,
    image: data?.variantList?.items[0]?.featuredAsset?.preview
      ? data?.variantList?.items[0]?.featuredAsset?.preview
      : (data?.featuredAsset?.preview as string),
    quantity: data?.customFields.minOrderQuantity
      ? data?.customFields.minOrderQuantity
      : 1,
    description: data?.description as string,
  });

  const [qualitySelected, setQualitySelected] = useState(product.quantity);

  useEffect(() => {
    const firstProduct = {
      id: data?.variantList?.items[0]?.id as string,
      name: data?.variantList?.items[0]?.name as string,
      price: data?.variantList?.items[0]?.price as number,
      priceWithTax: data?.variantList?.items[0]?.priceWithTax as number,
      stockLevel: data?.variantList?.items[0]?.stockLevel,
      image: data?.variantList?.items[0]?.featuredAsset?.preview
        ? data?.variantList?.items[0]?.featuredAsset?.preview
        : (data?.featuredAsset?.preview as string),
      quantity: data?.customFields.minOrderQuantity
        ? data?.customFields.minOrderQuantity
        : 1,
      description: data?.description as string,
    };
    setProduct(firstProduct);
    setQualitySelected(firstProduct.quantity);
  }, [data]);

  const addToCart = () => {
    addItemToOrder({
      variables: { productId: product.id.toString(), qty: qualitySelected },
    }).then((res) => {
      if (res.data?.addItemToOrder?.__typename === "Order") {
        const code = res.data?.addItemToOrder?.code;
        const id = res.data?.addItemToOrder?.id;
        const lines = res.data?.addItemToOrder?.lines;
        orderStore.setOrder({ code: code, id: id, lines: lines });
        notifyAddTocart();
      } else {
        if (
          res.data?.addItemToOrder?.order?.code &&
          res.data?.addItemToOrder?.order?.id &&
          res.data?.addItemToOrder?.order?.lines
        ) {
          const code = res.data?.addItemToOrder?.order?.code;
          const id = res.data?.addItemToOrder?.order?.id;
          const lines = res.data?.addItemToOrder?.order?.lines;
          orderStore.setOrder({ code: code, id: id, lines: lines });
          toast.error(res.data?.addItemToOrder?.message);
        }
      }
    });
  };

  const notifyAddTocart = () => {
    toast.custom(
      (t) => (
        <NotifyAddTocart
          productImage={product.image}
          qualitySelected={qualitySelected}
          show={t.visible}
          sizeSelected=""
          variantActive={0}
          price={product.priceWithTax}
          name={product.name}
        />
      ),
      { position: "top-right", id: "nc-product-notify", duration: 1000 }
    );
  };

  const renderVariants = () => {
    if (!data?.variantList || !data.variantList?.items.length) {
      return null;
    }

    return (
      <div>
        <div className="flex">
          <Select
            sizeClass="my-0 py-0"
            onChange={(e) => {
              const getVarient = data?.variantList?.items?.find(
                (variant) => variant.id === e.target.value
              );
              const productImage = getVarient?.featuredAsset?.preview
                ? getVarient?.featuredAsset?.preview
                : (data?.featuredAsset?.preview as string);
              setProduct({
                ...product,
                id: getVarient?.id as string,
                name: getVarient?.name as string,
                priceWithTax: getVarient?.priceWithTax as number,
                price: getVarient?.price as number,
                image: productImage,
              });
            }}
          >
            {data?.variantList?.items.map((variant, index) => {
              return (
                <option key={index} value={variant.id}>
                  {variant.name}
                </option>
              );
            })}
          </Select>
        </div>
      </div>
    );
  };

  return (
    <>
      <div
        className={`nc-ProductCard relative flex flex-col rounded-3xl ${className}`}
        data-nc-id="ProductCard"
      >
        <div className="relative flex-shrink-0 bg-transparent dark:bg-slate-300 overflow-hidden z-1 group">
          <Link to={`/product/${data?.slug}`} className="block relative">
            <div className="absolute top-1 right-1 z-10">
              <FontAwesomeIcon icon={faEye} />
            </div>
            <NcImage
              containerClassName="flex aspect-w-11 aspect-h-12 w-full h-0"
              src={product.image}
              className="object-cover w-full h-full "
            />
          </Link>
          {product.stockLevel === "OUT_OF_STOCK" && (
            <ProductStatus status={"Sold Out"} />
          )}
        </div>

        <div className="space-y-3 px-1.5 md:px-2.5 pt-5 pb-2.5">
          <div>
            <Link to={`/product/${data?.slug}`}>
              <h2
                className={`nc-ProductCard__title text-base sm:font-semibold font-inter transition-colors mb-1 whitespace-nowrap`}
              >
                <ProductDescription line={16} description={data?.name} />
              </h2>
            </Link>

            {data?.variantList?.items?.length &&
              data?.variantList?.items?.length > 1 &&
              renderVariants()}

            {data?.variantList?.items?.length &&
              data?.variantList?.items?.length === 1 && (
                <div
                  className={`text-sm font-inter text-[#0068FF] dark:text-slate-400 mt-1 whitespace-nowrap`}
                >
                  {truncateText(product?.description, 20)}
                </div>
              )}
          </div>

          {/* Replaced Prices Component*/}
          <h2 className="w-full text-left text-base text-black font-semibold font-inter">
            {formatCurrencyINR(product?.priceWithTax)}
          </h2>

          <div className="flex flex-row gap-1.5 md:gap-2.5 md:flex-row justify-between items-center">
            <NcInputNumber
              min={product.quantity}
              defaultValue={qualitySelected}
              onChange={setQualitySelected}
              widthClass="w-20 md:w-24"
              className="py-0 px-1 md:px-2"
            />
            <div className="flex items-center w-full">
              {product.stockLevel === "IN_STOCK" && (
                <ButtonPrimary
                  className="shadow-lg flex flex-1 items-center hover:bg-[#0068FF] justify-center w-full rounded-md"
                  fontSize="text-xs"
                  sizeClass="py-2.5 px-1 md:px-2 lg:px-1 "
                  loading={addItemToOrderProp.loading}
                  onClick={() => addToCart()}
                >
                  <span>Add</span>
                  <BagIcon className="ml-1 w-4 h-4" />
                </ButtonPrimary>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductCard;
