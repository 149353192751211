import Prices from "components/Prices";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import CommonLayout from "./CommonLayout";
import { useMutation, useQuery } from "@apollo/client";
import { SellerDetails, myOrdersQuery } from "graphql/queries/queries";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { Link } from "react-router-dom";
import NcImage from "shared/NcImage/NcImage";
import formatCurrencyINR from "utils/convertNumbToCurrency";
import Input from "shared/Input/Input";
import ButtonDropdown from "components/ButtonDropdown";
import { Menu } from "@headlessui/react";
import ButtonCircle from "shared/Button/ButtonCircle";
import { useEffect, useState } from "react";
import { PDFDocument } from "pdf-lib";
import { usePDF, pdf } from "@react-pdf/renderer";
import MyDoc from "components/CatelogPdf/Pdf";
import { ModalKitUpdate } from "shared/ModalKitInput/ModalKitInput";
import { removeItemFromOrderMutation } from "graphql/mutations/remove-from-cart.mutation";
import { updateKitNameMutaion } from "graphql/mutations/update-kitname_mutation";
const DemoImageURL = "/assets/placeholder-small.png"; // Replace with your demo image URL

const isImageURLValid = async (url: any) => {
  try {
    const response = await fetch(url);
    return response.ok;
  } catch (error) {
    return false;
  }
};

const AccountOrder = () => {
  const { data, loading, error, refetch } = useQuery(myOrdersQuery, {
    fetchPolicy: "no-cache",
  });
  const FetchSeller = useQuery(SellerDetails, { fetchPolicy: "no-cache" });
  const [updateKitNameMutation, updateKitNameMutationProps] =
    useMutation(updateKitNameMutaion);

  // selected order state
  const [selectedOrder, setSelectedOrder] = useState<any>([]);
  //  bulkPdfCreatingstate
  const [bulkPdfCreating, setBulkPdfCreating] = useState(false);
  const [seller, setSeller] = useState<{
    mobile: string;
    website: string;
  } | null>(null);

  // console.log(selectedOrder, "selected order");

  useEffect(() => {
    if (
      !FetchSeller.loading &&
      FetchSeller.data &&
      FetchSeller.data.activeChannel &&
      FetchSeller.data.activeChannel.seller
    ) {
      const mobile =
        FetchSeller.data?.activeChannel?.seller?.customFields?.mobile;
      const website =
        FetchSeller.data?.activeChannel?.seller?.customFields?.website;
      setSeller({ mobile: mobile, website: website });
    }
  }, [FetchSeller.data, FetchSeller.loading]);

  const editOrderData = async (data: any) => {
    // console.log(data, "data in edit order");
    if (data) {
      const processedLines = data?.lines?.map(async (line: any) => {
        const imageUrl = line.productVariant.product.featuredAsset?.preview;

        // check if product variant have more than 1 assets then use else check in product assets if it has more than 1 then use else set empty array
        const variantAssetLength = line.productVariant.assets.length;
        const productAssetLength = line.productVariant.product.assets.length;
        let assets =
          variantAssetLength > 1
            ? line.productVariant.assets
            : productAssetLength > 1
            ? line.productVariant.product.assets
            : [];

        // now check if any of the image matches with imageUrl then remove it from assets array
        assets = assets.filter((asset: any) => asset.preview !== imageUrl);

        let procesingData = line;

        if (!assets.length) {
          procesingData = { ...procesingData, assets: [] };
        } else {
          assets.forEach((asset: any, i: any) => {
            const isValid = isImageURLValid(asset.preview);
            if (!isValid) {
              assets[i].preview = DemoImageURL;
              procesingData = { ...procesingData, assets: assets };
            }
          });
          procesingData = { ...procesingData, assets: assets };
        }

        if (!imageUrl) {
          procesingData = { ...procesingData, imageUrl: DemoImageURL };
        } else {
          const isValid = await isImageURLValid(imageUrl);

          if (!isValid) {
            procesingData = { ...procesingData, imageUrl: DemoImageURL };
          }
          procesingData = { ...procesingData, imageUrl };
        }
        return procesingData;
      });

      // Use Promise.all to await the completion of all asynchronous operations
      const processedLinesData = await Promise.all(processedLines);

      const processedOrder = { ...data, lines: processedLinesData };

      return { order: processedOrder };
    }
    return { order: {} };
  };

  const mergeOrder = () => {
    if (selectedOrder.length <= 0) return;

    let orderData: any = {};
    orderData = selectedOrder[0];

    if (selectedOrder.length === 1) return orderData;
    // merge order

    for (let i = 1; i < selectedOrder.length; i++) {
      // console.log(selectedOrder[i], "selected order ---");
      orderData = {
        ...orderData,
        lines: [...orderData?.lines, ...selectedOrder[i].lines],
        subTotal: orderData?.subTotal + selectedOrder[i].subTotal,
        subTotalWithTax:
          orderData?.subTotalWithTax + selectedOrder[i].subTotalWithTax,
        total: orderData?.total + selectedOrder[i].total,
        totalWithTax: orderData?.totalWithTax + selectedOrder[i].totalWithTax,
      };
    }

    // console.log(orderData, "order data ---");
    return orderData;
  };

  const download = (url: any, name: any) => {
    const link = document.createElement("a");
    link.href = url;
    link.download = name;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const createBulkPdf = async () => {

    const pdfBuffers = await Promise.all(
      selectedOrder.map(async (element: any) => {
        return createPdfBuffer(element);
      })
    );

    const mergedPdfBuffer = await mergePdfBuffers(pdfBuffers);
    const kitname = "MergedCatalog";
    const mergedUrl = URL.createObjectURL(
      new Blob([mergedPdfBuffer], { type: "application/pdf" })
    );
    download(mergedUrl, `${kitname}.pdf`);

    setBulkPdfCreating(false);
    setSelectedOrder([]);

    
  }

  const createPdfBuffer = async (data: any) => {
    const orderData = await editOrderData(data);

    if (Object.keys(orderData).length <= 0) return;
    //  ignore by typescript
    // @ts-ignore
    const blobPdf = await pdf(
      <MyDoc data={orderData} seller={seller} />
    ).toBlob();
    return await blobPdf.arrayBuffer();
    const url = URL.createObjectURL(blobPdf);
    // window.open(url, "_blank")
    // download pdf by name Catalog.pdf
    const kitname = orderData?.order?.customFields?.kitName || "Catalog";

    download(url, `${kitname}.pdf`);

    setBulkPdfCreating(false);
    setSelectedOrder([]);
  };
  const mergePdfBuffers = async (pdfBuffers: ArrayBuffer[]) => {
    const mergedPdfDoc = await PDFDocument.create();

    for (const pdfBuffer of pdfBuffers) {
      const pdfDoc = await PDFDocument.load(pdfBuffer);
      const copiedPages = await mergedPdfDoc.copyPages(
        pdfDoc,
        pdfDoc.getPageIndices()
      );
      copiedPages.forEach((page) => mergedPdfDoc.addPage(page));
    }

    return await mergedPdfDoc.save();
  };

  const createSinglePdf = async (order: any) => {
    const orderData = await editOrderData(order);
    if (Object.keys(orderData).length <= 0) return;
    //  ignore by typescript
    // @ts-ignore
    const blobPdf = await pdf(
      <MyDoc data={orderData} seller={seller} />
    ).toBlob();
    const url = URL.createObjectURL(blobPdf);
    // window.open(url, "_blank")
    // download pdf by name Catalog.pdf
    const kitname = orderData?.order?.customFields?.kitName || "Catalog";
    download(url, `${kitname}.pdf`);
  };

  const renderProductItem = (item: any, index: number) => {
    const id = item?.id;
    const productId = item?.productVariant?.id;
    const image = item?.productVariant?.product?.featuredAsset?.preview;
    const price = item?.linePriceWithTax;
    const name = item?.productVariant?.name;
    const productName = item?.productVariant?.product?.name;
    const quantity = item?.quantity;
    const productDescription = item?.productVariant?.product?.description;

    return (
      <div
        key={index}
        className="relative flex py-8 sm:py-10 xl:py-12 first:pt-0 last:pb-0"
      >
        <div className="relative h-36 w-24 sm:w-32 flex-shrink-0 overflow-hidden rounded-xl bg-slate-100">
          <NcImage
            src={image}
            alt={name}
            containerClassName="h-full w-full object-cover object-center"
            className="h-full w-full object-cover object-center"
          />
        </div>

        <div className="ml-3 sm:ml-6 flex flex-1 flex-col">
          <div>
            <div className="flex justify-between ">
              <div className="flex-[1.5] ">
                <h3 className="text-base font-semibold">{productName}</h3>
                <p className="mt-1 text-sm text-neutral-500 dark:text-neutral-400">
                  {name}
                </p>

                <div className="mt-3 flex justify-between w-full sm:hidden relative">
                  <p>qty: {quantity}</p>
                  <Prices
                    contentClass="py-1 px-2 md:py-1.5 md:px-2.5 text-sm font-medium h-full"
                    price={price}
                  />
                </div>
              </div>

              <div className="hidden sm:block text-center relative">
                {/* <NcInputNumber defaultValue={quantity} id={id} productId={productId} className="relative z-10" /> */}
                QTY:{quantity}
              </div>

              <div className="hidden flex-1 sm:flex justify-end">
                <Prices price={price} className="mt-0.5" />
              </div>
            </div>
          </div>

          <div className="flex mt-auto pt-4 items-end justify-between text-sm">
            {/* {Math.random() > 0.6
              ? renderStatusSoldout()
              : renderStatusInstock()} */}
            {/* { renderStatusInstock()} */}

            {/* <button
              onClick={()=>{
                handleRemoveOrder({lineId:id,productId:productId})
                
              }}
              className="relative z-10 flex items-center mt-3 font-medium text-primary-6000 hover:text-primary-500 text-sm "
            >
              <span>Remove</span>
            </button> */}
          </div>
        </div>
      </div>
    );
  };

  const RenderOrder = ({ item, index }: { item: any, index: any }) => {
    // console.log(item,"order item ---")
    const isExist = selectedOrder.find((order: any) => order.id === item.id);

    const [open, setOpen] = useState(false);
   

    const updateKitname = async (kitName: string, orderId: string) => {
      const { data } = await updateKitNameMutation({
        variables: {
          orderId: orderId,
          kitName: kitName,
        },
      });
      refetch();
      if (data?.updateOrder?.order) {
        setOpen(false);
      }
    };

    const closeModal = () => {
      setOpen(false);
    };

    const openModal = () => {
      setOpen(true);
    };

    return (
      <div
        key={`${index}-${item?.code}`}
        className="border border-slate-200 dark:border-slate-700 rounded-lg overflow-hidden z-0"
      >
        <div className="flex flex-col sm:flex-row sm:justify-between sm:items-center p-4 sm:p-8 bg-slate-50 dark:bg-slate-500/5">
          <div className="flex-1">
            {/* select box */}
            <p className="text-lg font-semibold">#{item?.code}</p>
            <p className="text-lg text-slate-4 00 dark:text-slate-400">
              Total{" "}
              <span className="text-slate-500 dark:text-slate-300">
                {formatCurrencyINR(item?.totalWithTax | 0)}
              </span>
            </p>
            <p className="text-slate-500 dark:text-slate-300 flex gap-2">
              <span>{item?.customFields?.kitName || "Add Kit Name"}</span>
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6 cursor-pointer"
                  onClick={openModal}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
                  />
                </svg>
              </span>
            </p>

            <p className="text-slate-500 dark:text-slate-400 text-sm mt-1.5 sm:mt-2">
              <span>{item?.orderPlacedAt}</span>
              <span className="mx-2">·</span>
              <span className="text-primary-500">
                {item?.state === "PaymentAuthorized" ? "Placed" : item?.state}
              </span>
            </p>
          </div>
          <div className="mt-3 flex  justify-center items-center gap-1 sm:mt-0  ">
            {/* select order button */}

            {/*  */}
            <Input
              type="checkbox"
              sizeClass="w-6 h-6"
              rounded="rounded-none"
              onChange={(e) => {
                if (e.target.checked) {
                  setSelectedOrder([...selectedOrder, item]);
                } else {
                  const newSelectedOrder = selectedOrder.filter(
                    (order: any) => order.id !== item.id
                  );
                  setSelectedOrder(newSelectedOrder);
                }
              }}
              checked={selectedOrder.some((order: any) => order.id === item.id)}
            />

            {/* download order button */}
            <ButtonCircle
              onClick={() => createSinglePdf(item)}
              bg="bg-white   hover:bg-slate-100 dark:bg-slate-900 dark:hover:bg-slate-800 dark:border-slate-700"
              className="ml-2 text-sm text-slate-500 dark:text-slate-400"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6 text-slate-900 dark:text-slate-300"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M12 9.75v6.75m0 0l-3-3m3 3l3-3m-8.25 6a4.5 4.5 0 01-1.41-8.775 5.25 5.25 0 0110.233-2.33 3 3 0 013.758 3.848A3.752 3.752 0 0118 19.5H6.75z"
                />
              </svg>
            </ButtonCircle>

            <Link to={`/account-my-order/${item?.id}`}>
              <ButtonSecondary
                sizeClass="py-2.5 px-4 sm:px-6"
                fontSize="text-sm font-medium"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-5 h-5 "
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
                  />
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                  />
                </svg>
                &nbsp; View Order
              </ButtonSecondary>
            </Link>
          </div>
        </div>
        <div className="border-t border-slate-200 dark:border-slate-700 p-2 sm:p-8 divide-y divide-y-slate-200 dark:divide-slate-700">
          {item?.lines?.map((product: any, index: number) =>
            renderProductItem(product, index)
          )}
        </div>
        {/* modal */}

        <ModalKitUpdate
          orderId={item?.id}
          kitName={item?.customFields?.kitName}
          closeModal={closeModal}
          isOpen={open}
          modalTitle="Update Kit Name"
          updateKitname={updateKitname}
          loading={updateKitNameMutationProps.loading}
        />
      </div>
    );
  };

  // if(!loading && data) console.log(data,"order data ---")
  if (loading || FetchSeller.loading)
    return (
      <div>
        <CommonLayout>
          <div className="space-y-10 sm:space-y-12">
            {/* HEADING */}
            {/* <h2 className="text-2xl sm:text-3xl font-semibold">Order History</h2> */}
            <div className="flex justify-center items-center">
              <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-primary-500"></div>
            </div>
          </div>
        </CommonLayout>
      </div>
    );

  if (error || FetchSeller.error)
    return (
      <div className="flex flex-col items-center justify-center">
        <p className="text-lg font-semibold">Something Went Wrong</p>
        <p className="text-slate-500 dark:text-slate-400 text-sm my-1.5 sm:mt-2">
          <span>{"You haven't placed any orders yet."}</span>
        </p>
        <div className="mt-3 sm:mt-0">
          <Link to="/page-collection">
            <ButtonPrimary
              sizeClass="py-2.5 px-4 sm:px-6"
              fontSize="text-sm font-medium"
            >
              Shop Now
            </ButtonPrimary>
          </Link>
        </div>
      </div>
    );

  return (
    <div>
      <CommonLayout>
        <div className="space-y-10 sm:space-y-12 relative">
          {/* HEADING */}
          {/* card */}

          <div
            className={`${
              selectedOrder.length <= 0 ? "hidden" : "block"
            } sticky top-24 z-30 border fade-in-out border-slate-200 dark:border-slate-700 rounded-lg  p-4 sm:p-8 bg-slate-50 dark:bg-slate-900 `}
          >
            <div className="flex justify-between">
              <h1 className="lg:text-2xl font-semibold">
                Selected Orders{" "}
                {selectedOrder.length > 0 ? `(${selectedOrder.length})` : ""}
              </h1>

              <div className="flex justify-center items-center gap-2">
                {/* clear button */}
                <ButtonSecondary
                  sizeClass="py-2.5 px-4 sm:px-6"
                  fontSize="text-sm font-medium"
                  onClick={() => {
                    setSelectedOrder([]);
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </ButtonSecondary>
                <ButtonSecondary
                  loading={bulkPdfCreating}
                  disabled={bulkPdfCreating}
                  sizeClass="py-2.5 px-4 sm:px-6"
                  fontSize="text-sm font-medium"
                  onClick={() => {
                    setBulkPdfCreating(true);
                    createBulkPdf();
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-6 h-6 text-slate-900 dark:text-slate-300"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M12 9.75v6.75m0 0l-3-3m3 3l3-3m-8.25 6a4.5 4.5 0 01-1.41-8.775 5.25 5.25 0 0110.233-2.33 3 3 0 013.758 3.848A3.752 3.752 0 0118 19.5H6.75z"
                    />
                  </svg>
                </ButtonSecondary>
              </div>
            </div>
          </div>

          {data?.activeCustomer?.orders?.items?.map(
            (item: any, index: number) => (
              <RenderOrder item={item} index={index}  key={index}/>
            )
          )}

          {data?.activeCustomer?.orders?.items?.length === 0 && (
            <div className="flex flex-col items-center justify-center">
              <p className="text-lg font-semibold">No Orders Found</p>
              <p className="text-slate-500 dark:text-slate-400 text-sm my-1.5 sm:mt-2">
                <span>{"You haven't placed any orders yet."}</span>
              </p>
              <div className="mt-3 sm:mt-0">
                <Link to="/page-collection">
                  <ButtonPrimary
                    sizeClass="py-2.5 px-4 sm:px-6"
                    fontSize="text-sm font-medium"
                  >
                    Shop Now
                  </ButtonPrimary>
                </Link>
              </div>
            </div>
          )}
        </div>
      </CommonLayout>
    </div>
  );
};

export default AccountOrder;
