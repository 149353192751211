import React, { FC, useEffect, useState, useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "@fortawesome/fontawesome-free/css/all.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import { faStar as farStar } from "@fortawesome/free-regular-svg-icons";
import testimonial1 from "../../images/SectionOurTestimonials/testimonial1.png";
import testimonial2 from "../../images/SectionOurTestimonials/testimonial2.png";
import testimonial3 from "../../images/SectionOurTestimonials/testimonial3.png";
import testimonial4 from "../../images/SectionOurTestimonials/testimonial4.png";
import "../../styles/SectionOurTestimonials.module.css";

interface Testimonial {
  id: number;
  name: string;
  position: string;
  image: string;
  heading: string;
  content: string;
  rating: number;
}

const SectionOurTestimonials: FC = () => {
  const [testimonials, setTestimonials] = useState<Testimonial[]>([]);
  const sliderRef = useRef<Slider>(null);
  const [currentSlide, setCurrentSlide] = useState<number>(1);
  const [totalSlides, setTotalSlides] = useState<number>(0);

  useEffect(() => {
    const fetchTestimonials = async () => {
      setTimeout(() => {
        const data: Testimonial[] = [
          {
            id: 1,
            name: "Naveen Kumar",
            position: "HR Manager",
            image: testimonial3,
            heading: "Amazing Service",
            content:
              "Our experience with 360customizer is very good. Their commitment for delivery and service is great. Quality of product offering by 360customizer is also very good. Overall I can say 360customizer is good platform to get corporate gifting.",
            rating: 5,
          },
          {
            id: 2,
            name: "Shantanu Dharmadhikari",
            position: "Administration Manager",
            image: testimonial2,
            heading: "Highly recommended!",
            content:
              "For over five years, we've entrusted our corporate gifting and merchandising needs to 360Customizer, and they've consistently exceeded our expectations. Their team is incredibly supportive and always ready to assist with any request. The quality of their products is top-notch, reflecting their commitment to excellence. Plus, their creative ideas have added a special touch to our branding initiatives.",
            rating: 5,
          },
          {
            id: 3,
            name: "Gurpreet Singh Aidhen",
            position: "HR Manager",
            image: testimonial1,
            heading: "Excellent Support",
            content:
              "360Customizer is our go to vendor when it comes to corporate gifting. They have been associated with us for quite some time now and have never disappointed us whether it be timely delivery or with the quality. Overall I will they are one of the best and a professional organisation. We at Weikfield are glad to have them on board.",
            rating: 5,
          },
          {
            id: 4,
            name: "Gauri Panse",
            position: "Facility Administrator",
            image: testimonial4,
            heading: "Enduring Satisfaction",
            content:
              "Working with 360customizer is a great experience from last 5 years. I must say thank the team efforts on providing  unique collection and quality in the corporate gifting products. They are one of our most loyal vendor and having good knowledge about corporate gifting and branding . We value their business excellence. I'm incredibly grateful for getting the amazing service  and satisfaction. I strongly recommended 360customizer for further association !",
            rating: 5,
          },
        ];
        setTestimonials(data);
        setTotalSlides(data.length);
        startAutoSlide();
      }, 4000);
    };

    fetchTestimonials();

    // Clean up the interval on component unmount
    return () => {
      if (autoSlideInterval.current !== null)
        clearInterval(autoSlideInterval.current);
    };
  }, []);

  const autoSlideInterval = useRef<NodeJS.Timeout | null>(null);

  const startAutoSlide = () => {
    autoSlideInterval.current = setInterval(() => {
      sliderRef.current?.slickNext();
    }, 6000);
  };

  const calculateScale = (index: number): number => {
    const distanceFromCurrent = Math.min(
      Math.abs(currentSlide - index),
      Math.abs(totalSlides - currentSlide + index)
    );
    const scale = 1 / (distanceFromCurrent * 0.3 + 1);
    return Math.max(scale, 0.8);
  };

  const StarRating: FC<{ rating: number }> = ({ rating }) => {
    const stars = [];
    for (let i = 0; i < 5; i++) {
      stars.push(
        <span key={i} style={{ color: "gold" }}>
          <FontAwesomeIcon icon={rating >= i + 1 ? faStar : farStar} />
        </span>
      );
    }
    return <div className="flex flex-nowrap">{stars}</div>;
  };

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: Math.min(3, testimonials.length),
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
    customPaging: (i: number) => (
      <span
        style={{
          width: "15px",
          height: "15px",
          background: testimonials[i].id === currentSlide ? "black" : "#C7C7C7",
          borderRadius: "50%",
          display: "inline-block",
          marginRight: "10px",
          cursor: "pointer",
        }}
      ></span>
    ),
    beforeChange: (current: number, next: number) => {
      setCurrentSlide(next + 1);
    },
  };

  return (
    <div className="w-full py-0 md:py-10 pb-0 md:pb-16 my-4">
      <div className="ml-2 lg:ml-16 text-left">
        <h2 className="text-xl lg:text-3xl font-righteous font-medium tracking-wide">
          Our Testimonials
        </h2>
        <p className="text-sm text-black dark:text-neutral-100 font-inter">
          See what people talk about us
        </p>
      </div>
      <div className="w-[100%] mx-auto py-0 md:py-6">
        <Slider ref={sliderRef} {...settings}>
          {testimonials.map((testimonial, index) => (
            <div key={index} className="p-4">
              <div
                className="testimonial-card rounded-lg p-4 mx-auto"
                style={{
                  boxShadow: "0 0 10px rgba(0, 0, 0, 0.3)",
                  transform: `scale(${calculateScale(index)})`,
                  minWidth: "300px",
                  minHeight: "420px",
                  maxWidth: "400px",
                  maxHeight: "500px",
                }}
              >
                <div className="testimonialInfo flex flex-wrap flex-col md:flex-col lg:flex-row items-center justify-between md:justify-center lg:justify-between mb-4 px-2">
                  <div className="flex items-center">
                    <img
                      src={testimonial.image}
                      alt={testimonial.name}
                      className="w-16 h-16 rounded-full m-2"
                    />
                    <div>
                      <p className="text-black font-inter font-medium text-sm whitespace-nowrap md:whitespace-normal">
                        {testimonial.name}
                      </p>
                      <p className="text-black font-inter text-xs whitespace-nowrap md:whitespace-normal">
                        {testimonial.position}
                      </p>
                    </div>
                  </div>

                  <div className="w-20 md:w-10 lg:w-24">
                    <StarRating rating={testimonial.rating} />
                  </div>
                </div>
                <h3 className="text-center text-lg font-inter font-semibold mb-4 ">
                  {testimonial.heading}
                </h3>
                <p className="text-center text-sm font-inter m-4 text-black">
                  {testimonial.content}
                </p>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default SectionOurTestimonials;
