import { gql } from "@apollo/client";


const RemoveAllOrderMutation = gql`
mutation{
    removeAllOrderLines{
      __typename
      ... on ErrorResult{
        errorCode
        message
      }
    }
  }
`


export default RemoveAllOrderMutation;