import React from "react";
import NavigationItem, { NavItemType } from "./NavigationItem";
import { useQuery } from "@apollo/client";
import { GET_HEADER_MENU } from "graphql/queries/queries";
import client from "graphql/apploClient";
import ncNanoId from "utils/ncNanoId";
import TemplatesDropdown from "components/Header/MegaMenu";


export const HEADER_NAV_LINKS: NavItemType[] = [
  {
    id: ncNanoId(),
    href: "/page-collection",
    name: "Products",
  },
  {
    id: ncNanoId(),
    href: "/product-packs",
    name: "Product Packs",
  },
];



function Navigation({data}:{data?:any}) {

  // console.log(data , "data from navigation")


  return (
    <ul className="nc-Navigation flex items-center">
        <TemplatesDropdown />
      {/* {HEADER_NAV_LINKS.map((item) => (
        <NavigationItem key={item.id} menuItem={item} />
      ))} */}

      {
        data?.navigations?.items.map((item:any)=>(
          <NavigationItem key={item?.id} menuItem={{id:item?.id,name:item?.title,href:item?.url, external:true}} />
        ))
      }
    </ul>
  );
}

export default Navigation;
