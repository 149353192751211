import { Popover, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/24/solid";
import { Fragment, useState } from "react";
import { MEGAMENU_TEMPLATES } from "data/navigation";
import CardCategory3 from "components/CardCategories/CardCategory3";
import { NavLink } from "react-router-dom";
import { NavItemType } from "shared/Navigation/NavigationItem";
import { useQuery } from "@apollo/client";
import { GET_FACETS } from "graphql/queries/queries";
import { FacetsData } from "graphql/queries/types";
import ButtonThird from "shared/Button/ButtonThird";
import { Link } from "react-router-dom";

export default function TemplatesDropdown() {
  const {data, loading, error} = useQuery<FacetsData>(GET_FACETS);
  

 
  // from data check if data.facets.items[i].values.length<0 then remove those items from the array

  const categories = data?.facets?.items?.filter((item)=>item?.values?.length>0)





  const renderMegaMenuNavlink = (item:any, close:any) => {
    return (
      <li key={item.id} className={`${item?.isNew ? "menuIsNew" : ""}`}>
        <Link
          rel="noopener noreferrer"
          className="font-normal text-slate-600 hover:text-black dark:text-slate-400 dark:hover:text-white"
          to={{
            pathname:"/page-collection",
            search: `?filters=${item?.id}`,

          }}
          onClick={close}
          
        >
          {item.name}
        </Link>
      </li>
    );
  };

  function Categories({item, close}:{item:any,close:any}){
    const [showAll, setShowAll] = useState(false);
    const numberOfItems = item?.values?.length >5 ? 5 : item?.values?.length;


    return(
      <div>
      <p className="font-medium text-slate-900 dark:text-neutral-200">
        {item.name}
      </p>
      
      <ul className="grid space-y-4 mt-4">
        { !showAll ? item?.values?.slice(0, numberOfItems).map((item:any)=>renderMegaMenuNavlink(item, close)): item?.values?.map((item:any)=>renderMegaMenuNavlink(item,close))}
        {
          item?.values?.length>5 && !showAll && <li>
            <button onClick={()=>setShowAll(true)} className="text-sm text-blue-600 hover:text-black dark:text-blue-400 dark:hover:text-blue-200">
              Show all
            </button>
          </li>
        }

      </ul>
    </div>
    )
  }

  return (
    <div className="TemplatesDropdown  lg:block">
      <Popover className="">
        {({ open, close }) => (
          <>
            <Popover.Button
              className={`
                ${open ? "" : "text-opacity-80"}
                group h-10 sm:h-12 px-3 py-1.5 inline-flex items-center text-sm text-gray-800 dark:text-slate-300 font-medium hover:text-opacity-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75`}
            >
              <span className="">Shop</span>
              <ChevronDownIcon
                className={`${open ? "-rotate-180" : ""}
                  ml-1 h-4 w-4 transition ease-in-out duration-150 `}
                aria-hidden="true"
              />
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute z-20 w-full mt-3.5 inset-x-0 overflow-auto max-h-screen scroll-m-0 scroll">
                <div className="bg-white dark:bg-neutral-900 shadow-lg">
                  <div className="container">
                    <div className="flex text-sm border-t border-slate-200 dark:border-slate-700 py-14">
                      <div className="flex-1 grid grid-cols-5 gap-6 xl:gap-8 pr-6 xl:pr-8">
                        {categories?.map((item, index) => (
                          <Categories item={item} key={index} close={close} />
                        ))}
                      </div>
                      {/* <div className="w-[40%] xl:w-[35%]">
                        <CardCategory3 />
                      </div> */}
                    </div>
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    </div>
  );
}
