import React from "react";

const SectionContent: React.FC = () => {
  return (
    <div className="section-content  py-2 md:py-6">
      <div className="subheading text-2xl mb-2 font-inter font-semibold text-slate-500">
        We Are
      </div>
      <h2 className="text-2xl md:text-4xl font-righteous font-semibold tracking-wider mb-6">
        360Customizer -
        <span className="text-2xl md:text-4xl font-medium inline">
          &nbsp;Make Your Mark !
        </span>
      </h2>
      <p className="mb-4 text-base font-normal font-inter">
        At 360Customizer, we're more than just a brand –
        <b>
          &nbsp;We're a belief, it's a movement towards self-expression and
          empowerment.&nbsp;
        </b>
        We believe every individual and business is uniquely extraordinary. As a
        brand under Namrup Fashion Pvt Ltd, we are driven by the conviction that
        each person and business possesses distinctive characteristics,
        preferences, and identities that deserve to be celebrated. Our aim is to
        provide a platform where individuals and businesses can express
        themselves through customized merchandise, gifts, and accessories
        customized to their tastes, needs, and branding requirements
      </p>
      <p className="mb-4 text-base font-normal font-inter">
        Every individual has their own set of preferences, from colors and
        designs to hobbies and interests to thoughts and emotions. Similarly,
        every business has their own identities, cultures, and standards that
        set them apart. 360Customizer works to empower both individuals and
        businesses to showcase their personalities and brand identities to the
        world through personalized products that align perfectly with their
        uniqueness making them truly special in their own right. Whether it's
        customized t-shirts, hoodies, accessories like caps and wallets, or
        corporate gifting items such as welcome kits, company merchandise,
        uniforms, and client/employee gifts, we offer a wide range of options to
        cater to diverse needs that resonate with their personality and brand.
        We believe that customization is the key to reflect true self-expression
        and brand representation.
      </p>
    </div>
  );
};

export default SectionContent;
