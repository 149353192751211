import React, { FC } from "react";
import { Helmet } from "react-helmet-async";
import SocialsList from "shared/SocialsList/SocialsList";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import SectionKnowMore from "components/SectionKnowMore/SectionKnowMore";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import {
  faPhone,
  faEnvelope,
  faMapMarker,
} from "@fortawesome/free-solid-svg-icons";
import whiteLogo from "../../images/360logo/360WhiteLogo.svg";
import backgroundEllipse from "../../images/contact/contactBackgroundEllipes.png";
import axios from "axios";
import "../../styles/PageContact.css";

interface ContactInfo {
  title: string;
  desc: string;
  icon: IconProp;
}

const info: ContactInfo[] = [
  {
    title: "PHONE",
    desc: "8637758689",
    icon: faPhone,
  },
  {
    title: "EMAIL",
    desc: "sales@360customizer.com",
    icon: faEnvelope,
  },
  {
    title: "ADDRESS",
    desc: "Namrup Fashion Pvt Ltd, 1st Floor, Office no 3, A wing, Pathak Park, Kishor Bhagirath Tapadia Rd, next to Tech Mahindra, behind Anand veg restaurant, Erandwane, Pune, Maharashtra 411004",
    icon: faMapMarker,
  },
];

const subjects: string[] = [
  "Sales",
  "Feedback",
  "General Inquiry",
  "Technical Support",
];

const PageContact: FC = () => {
  const [selectedSubject, setSelectedSubject] =
    React.useState<string>("Inquiry");
  const [formData, setFormData] = React.useState<any>({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    message: "",
    selectedSubject,
  });

  // Form Data Values handler
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // Checkboxes Values Change Function
  const handleChange = (title: string) => {
    setSelectedSubject(title);
  };

  // Open Google Map
  const openGoogleMaps = () => {
    window.open(
      "https://www.google.com/maps/dir//1st+Floor,+Office+no+3,+A+wing,+Pathak+Park,+Kishor+Bhagirath+Tapadia+Rd,+next+to+Tech+Mahindra,+behind+Anand+veg+restaurant,+Bhalekar+Chawl,+Erandwane,+Pune,+Maharashtra+411004/@18.5087967,73.7510131,12z/data=!4m8!4m7!1m0!1m5!1m1!1s0x3bc2bf7f2977650f:0x56dc5e91cbfeec93!2m2!1d73.8334148!2d18.5088145?entry=ttu"
    );
  };

  // Submit Function
  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    try {
      const response = await axios.post(
        "https://360backend.tangledata.com/contactus",
        JSON.stringify(formData),
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response.status === 201) {
        window.location.reload();
      }
    } catch (error) {
      console.error("Error sending data to API:", error);
    }
  };

  return (
    <React.Fragment>
      <div className="container py-14 lg:py-10 space-y-10 lg:space-y-10 flex flex-col">
        <Helmet>
          <title>Contact Us</title>
        </Helmet>
        <div className="ml-2 lg:ml-4 mb-0">
          <h1 className="text-4xl font-righteous font-semibold mb-2 tracking-wide">
            Contact Us
          </h1>
          <h3 className="text-lg font-medium">
            Any question or remarks? Just write us a message!
          </h3>
        </div>
        <div className="flex flex-col md:flex-row w-full h-full ml-0">
          {/* Left Side */}
          {/* <div className="bg-blue-600 text-white w-full md:w-2/5 p-8 rounded-xl lg:rounded-l-xl md:rounded-l-none md:rounded-tl-xl md:rounded-tr-xl"> */}
          <div
            className="bg-blue-600 text-white w-full md:w-2/5 p-8 rounded-xl lg:rounded-l-xl md:rounded-l-none md:rounded-tl-xl md:rounded-tr-xl"
            style={{
              backgroundImage: `url(${backgroundEllipse})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <div className=" flex justify-between mb-8">
              <h2 className="text-3xl font-semibold mb-2">
                Contact Information
              </h2>
              <img src={whiteLogo} className="w-14 h-14" />
            </div>
            <div className="flex flex-col flex-1 my-16 lg:my-28">
              {info.map((item, index) => (
                <div key={index} className="flex mb-6">
                  <FontAwesomeIcon icon={item.icon} className="text-lg mr-4" />
                  {item.title === "ADDRESS" ? (
                    <a
                      onClick={openGoogleMaps}
                      className="text-sm cursor-pointer hover:text-blue-200"
                    >
                      {item.desc}
                    </a>
                  ) : item.title === "EMAIL" ? (
                    <a
                      href={`mailto:${item.desc}`}
                      className="text-sm cursor-pointer hover:text-blue-200"
                    >
                      {item.desc}
                    </a>
                  ) : item.title === "PHONE" ? (
                    <a
                      href={`tel:${item.desc}`}
                      className="text-sm cursor-pointer hover:text-blue-200"
                    >
                      {item.desc}
                    </a>
                  ) : (
                    <p className="text-sm cursor-pointer hover:text-blue-200">
                      {item.desc}
                    </p>
                  )}
                </div>
              ))}
            </div>
            <div className="mt-auto">
              <SocialsList />
            </div>
          </div>

          {/* Right Side */}
          <div className="w-full md:w-3/5 p-8">
            <form
              className="grid grid-cols-1 gap-6"
              action="#"
              method="post"
              onSubmit={handleSubmit}
            >
              <div className="flex flex-col gap-6 md:gap-0 md:flex-row mb-6">
                <div className="flex-1">
                  <label htmlFor="firstName" className="block">
                    First Name
                  </label>
                  <input
                    id="firstName"
                    type="text"
                    name="firstName"
                    value={formData.firstName}
                    onChange={handleInputChange}
                    className="input-style w-full md:w-[80%]"
                  />
                </div>
                <div className="flex-1">
                  <label htmlFor="lastName" className="block">
                    Last Name
                  </label>
                  <input
                    id="lastName"
                    type="text"
                    name="lastName"
                    value={formData.lastName}
                    onChange={handleInputChange}
                    className="input-style w-full md:w-[80%]"
                  />
                </div>
              </div>
              <div className="flex flex-col gap-6 md:gap-0 md:flex-row mb-6">
                <div className="flex-1">
                  <label htmlFor="email" className="block">
                    Email
                  </label>
                  <input
                    id="email"
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleInputChange}
                    className="input-style w-full md:w-[80%]"
                  />
                </div>
                <div className="flex-1">
                  <label htmlFor="phoneNumber" className="block">
                    Phone Number
                  </label>
                  <input
                    id="phoneNumber"
                    type="number"
                    name="phoneNumber"
                    value={formData.phoneNumber}
                    onChange={handleInputChange}
                    className="input-style w-full md:w-[80%]"
                  />
                </div>
              </div>

              <div className="grid grid-cols-2 md:grid-cols-4 gap-4 mt-2">
                {subjects.map((subject, index) => (
                  <div key={index} className="flex items-center">
                    <input
                      type="checkbox"
                      id={`subject${index}`}
                      name="subject"
                      checked={selectedSubject === subject}
                      onChange={() => handleChange(subject)}
                      className="rounded-full border-gray-300 ring-1 ring-opacity-50 h-4 w-4 checked:bg-black"
                    />
                    <label htmlFor={`subject${index}`} className="ml-2 text-sm">
                      {subject}
                    </label>
                  </div>
                ))}
              </div>

              <label htmlFor="message" className="block w-full">
                Message
              </label>
              <input
                id="message"
                type="text"
                name="message"
                className="input-style"
                onChange={handleInputChange}
                required
              />
              <div className="flex justify-end mt-10">
                <ButtonPrimary
                  type="submit"
                  className="text-white bg-black px-6 py-3 w-full max-w-[200px] rounded-md shadow-none"
                >
                  Send Message
                </ButtonPrimary>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div>
        <SectionKnowMore />
      </div>
    </React.Fragment>
  );
};

export default PageContact;
