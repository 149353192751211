import React, { FC, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Link, useNavigate } from "react-router-dom";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { useMutation, useQuery } from "@apollo/client";
import { userLoginMutation } from "graphql/mutations/user-login.mutation";
import { ErrorMessage, Field, Formik, FormikHelpers, Form } from "formik";
import { toast } from "react-hot-toast";
import useCurrentUser from "hooks/useCurrentUser";
import { MyCartQueryResponse } from "graphql/queries/types";
import { Orders, myCartQuery } from "graphql/queries/queries";
import useOrderStore from "hooks/useCart";

export interface PageLoginProps {
  className?: string;
}

const inputClasses = `
      block w-full border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 
      bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900 
      disabled:bg-neutral-200 dark:disabled:bg-neutral-800 rounded-2xl text-sm font-normal h-11 px-4 py-3 `;

interface Values {
  username: string;
  password: string;
}

const PageLogin: FC<PageLoginProps> = ({ className = "" }) => {
  const navigate = useNavigate();
  const cartDataQuery = useQuery(Orders, { fetchPolicy: "no-cache" });

  const orderStore = useOrderStore((state) => {
    return {
      setOrder: state.setOrder,
      order: state.order,
    };
  });

  const getUser = useCurrentUser((state) => {
    return {
      user: state.user,
      getUser: state.getUser,
      isLoggedIn: state.isUserLoggedIn,
    };
  });
  const [login, { data, error, loading }] = useMutation(userLoginMutation);
  const [formError, setFormError] = useState("");
  const formInitValues: Values = {
    username: "",
    password: "",
  };

  const handleSubmit = (
    values: Values,
    { setSubmitting, resetForm }: FormikHelpers<Values>
  ) => {
    setSubmitting(false);
    login({ variables: values });
  };

  useEffect(() => {
    if (!loading && data) {
      if (data?.login?.__typename === "CurrentUser") {
        localStorage.setItem("isLogin", "true");
        getUser.getUser();
        toast.success("Login successful");
        cartDataQuery.refetch().then((res) => {
          if (res.data?.activeOrder?.__typename === "Order") {
            orderStore.setOrder({
              code: res.data?.activeOrder?.code,
              id: res.data?.activeOrder?.id,
              lines: res.data?.activeOrder?.lines,
            });
          }
        });
        navigate("/account-my-order");
      } else {
        setFormError(data?.login?.message);
      }
      // console.log(data,"login data")
    }
  }, [data, loading]);

  if (loading)
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-primary"></div>
      </div>
    );

  if (error) {
    return (
      <div className="flex flex-col justify-center items-center h-screen">
        <h1 className="text-2xl font-semibold text-center my-3">
          Something went wrong!
        </h1>
        <ButtonPrimary
          onClick={() => window.location.reload()}
          className="btn btn-primary mt-5"
        >
          Try again
        </ButtonPrimary>
      </div>
    );
  }

  return (
    <div className={`nc-PageLogin ${className}`} data-nc-id="PageLogin">
      <Helmet>
        <title>Login || 360CUSTOMIZER</title>
      </Helmet>
      <div className="container mb-24 lg:mb-32">
        <h2 className="my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          Login
        </h2>
        <div className="max-w-md mx-auto space-y-6">
          <p className="text-red-500">{formError}</p>
          <Formik initialValues={formInitValues} onSubmit={handleSubmit}>
            {({ errors, touched, isSubmitting }) => (
              <Form className="grid grid-cols-1 gap-6">
                <label className="block">
                  <span className="text-neutral-800 dark:text-neutral-200">
                    Email address
                  </span>
                  <Field
                    id="username"
                    name="username"
                    type="email"
                    placeholder="example@example.com"
                    className={`mt-1 ${inputClasses}`}
                  />
                  <ErrorMessage
                    name="email"
                    component="div"
                    errors={errors}
                    touched={touched}
                    className="text-red-500"
                  />
                </label>
                <label className="block">
                  <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                    Password
                    <Link to="/forgot-pass" className="text-sm text-green-600">
                      Forgot password?
                    </Link>
                  </span>
                  <Field
                    type="password"
                    id="password"
                    name="password"
                    className={`mt-1 ${inputClasses}`}
                  />
                  <ErrorMessage
                    name="password"
                    component="div"
                    errors={errors}
                    touched={touched}
                    className="text-red-500"
                  />
                </label>
                <ButtonPrimary type="submit" disabled={isSubmitting}>
                  Continue
                </ButtonPrimary>
              </Form>
            )}
          </Formik>
          {/* ==== */}
          <span className="block text-center text-neutral-700 dark:text-neutral-300">
            New user? {` `}
            <Link className="text-green-600" to="/signup">
              Create an account
            </Link>
          </span>
        </div>
      </div>
    </div>
  );
};

export default PageLogin;
