




import { gql } from "@apollo/client";





export const setKitNameMutation = gql`
mutation ($kitName:String!){
    setOrderCustomFields(input:{customFields:{kitName:$kitName}}){
        __typename
    }
}
`